import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginApi, logoutApi, resendCodeApi, myInfoApi } from '@apis/auth';
// import customApiRequest from '../../helpers/customApiRequest';
// import checkRes from '../../utils/checkRes';
// import successNotification from '../../utils/successNotification';
import customApiRequest from '@helpers/customApiRequest';

export const userInfoAction = createAsyncThunk(
  'user/userInfoAction',
  async (v, thunkAPI) => {
    const x = customApiRequest({
      apiCall: await myInfoApi(),
      thunkAPI
    });
    return x;
  }
);
export const signinAction = createAsyncThunk(
  'user/signinAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await loginApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// resend signup code action
export const resendSignupCodeAction = createAsyncThunk(
  'user/resendSignupCodeAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await resendCodeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// signout action
export const userSignoutAction = createAsyncThunk(
  'user/userSignoutAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await logoutApi(),
      thunkAPI
    });
  }
);
