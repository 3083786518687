import { Modal } from 'antd';
import { confirmModalTypesValues } from '@mocks/index';
import { CloseCircleIcon, CheckMarkCircleIcon } from '@icons/index';
import './styles.scss';
import { t } from 'i18next';

const ConfirmModal = ({
  modalOpened,
  setModalOpened,
  children,
  handleAccept,
  modalTitle,
  modalType = confirmModalTypesValues?.successModal,
  handleCancel
}) => {
  return (
    <Modal
      destroyOnClose
      className={`shared-custom-modal confirm-modal ${
        modalType === confirmModalTypesValues?.successModal
          ? 'success-modal'
          : ''
      } ${
        modalType === confirmModalTypesValues?.errorModal ? 'error-modal' : ''
      }`}
      width="96%"
      style={{ maxWidth: '542px' }}
      title={modalTitle || ''}
      open={modalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        if (handleCancel && typeof handleCancel === 'function') handleCancel();
        setModalOpened(false);
      }}
      footer={false}
    >
      {children}

      <div className="modal-action-btns-wrapper">
        <button
          className="accept-btn"
          // onClick={() => {
          //   handleAccept();
          // }}
          onClick={handleAccept}
        >
          <CheckMarkCircleIcon color="#fff" />
          {t('yes')}
        </button>
        <button
          onClick={() => {
            setModalOpened(false);
          }}
          className="cancel-btn"
        >
          <CloseCircleIcon color="#404041" />
          {t('no')}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
