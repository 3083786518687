import protectMe from '@helpers/protectMe';
import { useSelector } from 'react-redux';
import sharedAuthRouterLinks from './sharedAuthRouterLinks';
import appRouterLinks from '@routes/appRouterLinks';
import AdminHome from '@pages/admin-home';
import { Navigate } from 'react-router-dom';
import OrdersPage from '@pages/orders-pages/orders-page';
import OrdersStatisticsPage from '@pages/orders-pages/orders-statistics-page';
import DriversPage from '@pages/drivers-pages/drivers-page';
import DriversStatisticsPage from '@pages/drivers-pages/drivers-statistics-page';
import SingleDriverPage from '@pages/single-driver-page';
import CarBrandsPage from '@pages/cars-brands-page';
import SingleCarBrandsPage from '@pages/cars-brands-page/single-car-brands-page';
import NewCarBrandPage from '@pages/cars-brands-page/new-car-brands-page';
import CarModelsPage from '@pages/cars-models-page';
import NewCarModelPage from '@pages/cars-models-page/new-car-models-page';
import CarYearsPage from '@pages/cars-years-page';
import NewCarYearPage from '@pages/cars-years-page/new-car-year-page';
import CountriesPage from '@pages/countries-page';
import NewCountryPage from '@pages/countries-page/new-country-page';
import CountryDetailsPage from '@pages/countries-page/country-details-page';
import CitiesPage from '@pages/cities-page';
import NewCityPage from '@pages/cities-page/new-city-page';
import RegionsPage from '@pages/regions-page';
import NewRegionPage from '@pages/regions-page/new-region-page';
import NationalitiesPage from '@pages/nationalities-page';
import NewNationalityPage from '@pages/nationalities-page/new-nationality-page';
import SingleOrderPage from '@pages/orders-pages/single-order-page';
import NewOrderPage from '@pages/orders-pages/new-order-page';
import DeliveryCalcMethodsPage from '@pages/delivery-calc-methods-page';
import DeliveryCalcRangeMethodPage from '@pages/delivery-calc-methods-page/delivery-calc-range-method-page';
import WalletTransactionsPage from '@pages/wallet-transactoins-page';
import CreateWalletTransactionPage from '@pages/wallet-transactoins-page/create-transaction-page';
import WithdrawalsPage from '@pages/withdrawals-page';
import WalletSettingsPage from '@pages/wallet-settings-page';
import CreateRangeMethodPage from '@pages/delivery-calc-methods-page/delivery-calc-range-method-page/create-range-method';

const SharedAuthRoutes = () => {
  const loggedIn = useSelector((store) => store.user.loggedIn);
  const user = useSelector((store) => store.user.user);

  const renderAdminHome = () => {
    if (loggedIn) {
      return <AdminHome />;
    } else if (loggedIn && user && !user?.activated) {
      return <Navigate to={appRouterLinks?.activateAccountRoute} />;
    } else {
      return <Navigate to={appRouterLinks?.login} />;
      // if (user?.accountType == accountTypes()?.user)
      // return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };

  return [
    protectMe({
      path: appRouterLinks?.adminHome,
      component: renderAdminHome(),
      key: 'admin_home_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: sharedAuthRouterLinks.profile,
      component: <h1>Profile page</h1>,
      key: 'profile_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    // orders routes
    protectMe({
      path: appRouterLinks.orders,
      component: <OrdersPage />,
      key: 'orders_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newOrder,
      component: <NewOrderPage />,
      key: 'new_order_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.ordersStats,
      component: <OrdersStatisticsPage />,
      key: 'orders_stats_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.orderDetails(),
      component: <SingleOrderPage />,
      key: 'single_order_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    // end orders routes
    ///////////////////////////
    // * drivers routes
    protectMe({
      path: appRouterLinks.drivers,
      component: <DriversPage />,
      key: 'drivers_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.driversStats,
      component: <DriversStatisticsPage />,
      key: 'drivers_stats_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.driverDetails(),
      component: <SingleDriverPage />,
      key: 'single_driver_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    // end drivers routes
    ///////////////////////////////////////
    // delivery price calculation methods
    protectMe({
      path: appRouterLinks.deliveryCalcMethods,
      component: <DeliveryCalcMethodsPage />,
      key: 'delivery_calc_methods_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.rangeMethodRoute,
      component: <DeliveryCalcRangeMethodPage />,
      key: 'delivery_calc_range_method_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.createRangeMethodRout,
      component: <CreateRangeMethodPage />,
      key: 'create_delivery_calc_range_method_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.updateRangeMethodRout(),
      component: <CreateRangeMethodPage />,
      key: 'update_delivery_calc_range_method_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    // end delivery price calc methods
    // wallet transactions routes
    protectMe({
      path: appRouterLinks.walletSettingsRoute,
      component: <WalletSettingsPage />,
      key: 'wallet_settings_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.walletTransactionsRoute,
      component: <WalletTransactionsPage />,
      key: 'wallet_transactions_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.createWalletTransaction,
      component: <CreateWalletTransactionPage />,
      key: 'create_wallet_transaction_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    ////////////////////////
    // * Withdrawals Routes
    protectMe({
      path: appRouterLinks.withdrawalsRoute,
      component: <WithdrawalsPage />,
      key: 'withdrawals_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    // * End withdrawals routes
    // //////////////////////
    // cars brands routes
    protectMe({
      path: appRouterLinks.vehiclesBrands,
      component: <CarBrandsPage />,
      key: 'car_brands_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.vehicleBrandDetails(),
      component: <SingleCarBrandsPage />,
      key: 'single_car_brands_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newVehicleBrand,
      component: <NewCarBrandPage />,
      key: 'new_car_brands_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editVehicleBrand(),
      component: <NewCarBrandPage />,
      key: 'edit_car_brands_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    //////////////////////////////////////
    //
    ///////////////////////////////////////
    // cars models routes
    protectMe({
      path: appRouterLinks.vehiclesModels,
      component: <CarModelsPage />,
      key: 'car_models_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newVehicleModel,
      component: <NewCarModelPage />,
      key: 'new_car_models_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editVehicleModel(),
      component: <NewCarModelPage />,
      key: 'edit_car_models_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    //////////////////////////////////////
    //
    // * cars years routes
    protectMe({
      path: appRouterLinks.vehiclesYears,
      component: <CarYearsPage />,
      key: 'car_years_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newVehicleYear,
      component: <NewCarYearPage />,
      key: 'new_car_years_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editVehicleYear(),
      component: <NewCarYearPage />,
      key: 'edit_car_years_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    //////////////////////////////////////
    ///////////////////////////////////////
    // countries routes
    protectMe({
      path: appRouterLinks.countries,
      component: <CountriesPage />,
      key: 'countries_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.countryDetails(),
      component: <CountryDetailsPage />,
      key: 'country_details_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newCountry,
      component: <NewCountryPage />,
      key: 'new_country_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editCountry(),
      component: <NewCountryPage />,
      key: 'edit_country_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    //////////////////////////////////////
    //
    // * cities routes
    protectMe({
      path: appRouterLinks.cities,
      component: <CitiesPage />,
      key: 'cities_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newCity,
      component: <NewCityPage />,
      key: 'new_city_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editCity(),
      component: <NewCityPage />,
      key: 'edit_city_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    //////////////////////////////////////
    // * regions routes
    protectMe({
      path: appRouterLinks.regions,
      component: <RegionsPage />,
      key: 'regions_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newRegion,
      component: <NewRegionPage />,
      key: 'new_region_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editRegion(),
      component: <NewRegionPage />,
      key: 'edit_region_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    //////////////////////////////////////
    // * nationalities routes
    protectMe({
      path: appRouterLinks.nationalities,
      component: <NationalitiesPage />,
      key: 'nationalities_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.newNationality,
      component: <NewNationalityPage />,
      key: 'new_nationality_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    }),
    protectMe({
      path: appRouterLinks.editNationality(),
      component: <NewNationalityPage />,
      key: 'edit_nationality_page_key',
      redirectRoute: appRouterLinks.login,
      loggedIn,
      user,
      authorized: true,
      accountTypesArr: [],
      Children: null
    })
    //////////////////////////////////////
    //
    //////////////////////////////////////
  ];
};

export default SharedAuthRoutes;
