/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignDriverToOrderAction } from '@redux/orders-slice/ordersSliceThunkApi';
import { Button, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import './styles.scss';

const FreeDriversForm = ({
  order_id,
  isSubmittingForm,
  fetchedFreeDrivers
}) => {
  const { i18n } = useTranslation();

  const defaultValues = {
    driver_id: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        driver_id: Yup.string().required('Select driver')
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`assignDriverForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      driver_id: ''
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('order_id', order_id || '');
    formData.append('driver_id', data.driver_id);

    const action = await dispatch(
      assignDriverToOrderAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action.payload.res;
    if (checkSuccessRes(res)) {
      resetFormInputs();
    }
  };

  return (
    <div className="car-brand-form-wrapper">
      <Form
        className="car-brand-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          {/* <div className="form-header bold-font">{t('assignDriver')}</div> */}

          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name="driver_id"
              errorMsg={errors?.driver_id && errors.driver_id.message}
              validateStatus={errors?.driver_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                fetchedFreeDrivers?.length > 0 &&
                fetchedFreeDrivers.map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <p className="custom-label">{sharedFormLabel('driver_id')}</p>
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {watch('driver_id') ? (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={resetFormInputs}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              ) : null}
            </AnimatePresence>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm ? t('saving') : t('assignDriver')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FreeDriversForm;
