import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import useSingleCity from '@customHooks/useSingleCity';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CityForm from './city-form';

const NewCityPage = () => {
  const params = useParams();
  const isLoadingSingleCity = useSelector(
    (store) => store.cities.isLoadingSingleCity
  );
  const isSubmittingCityForm = useSelector(
    (store) => store.cities.isSubmittingCityForm
  );
  const fetchedSingleCity = useSelector(
    (store) => store.cities.fetchedSingleCity
  );

  // get single city
  useSingleCity();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleCity && params?.city_id
            ? `${t('update')} ${fetchedSingleCity?.name}` ||
              `${fetchedSingleCity?.id ? `#${fetchedSingleCity.id}` : ''}` ||
              ' '
            : t('newCity')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: <Link to={appRouterLinks.cities}>{t('cities')}</Link>
          },
          {
            title:
              fetchedSingleCity && params?.city_id
                ? fetchedSingleCity?.name ||
                  `${
                    fetchedSingleCity?.id ? `#${fetchedSingleCity.id}` : ''
                  }` ||
                  ' '
                : t('newCity')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-city-page">
        <SharedPageHeader />

        <CityForm
          fetchedObj={
            params?.city_id && fetchedSingleCity ? fetchedSingleCity : null
          }
          isSubmittingForm={isSubmittingCityForm}
        />
      </div>

      {(isSubmittingCityForm || isLoadingSingleCity) && <LoadingModal />}
    </PageWrapperMotion>
  );
};

export default NewCityPage;
