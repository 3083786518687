/* eslint-disable eqeqeq */
import { TrashIcon, UpdateIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button, Tooltip } from 'antd';

const TableColumns = ({
  setSelectedTableRow,
  setDeleteModalOpened,
  t,
  navigate
}) => {
  const renderUpdateBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('update')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            // to={`${appRouterLinks?.vehicleBrandDetails(record?.id)}`}
            className="action-btn view"
            onClick={(e) => {
              e.stopPropagation();
              navigate(appRouterLinks.updateRangeMethodRout(record.id));
            }}
          >
            <UpdateIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const renderDeleteBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('delete')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
            htmlType="button"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTableRow(record);
              setDeleteModalOpened(true);
            }}
          >
            <TrashIcon color="red" />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('rangeFrom'),
      dataIndex: 'rangeFrom',
      width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.from || '---'}</div>;
      }
    },
    {
      title: t('rangeTo'),
      dataIndex: 'rangeTo',
      width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.to || '---'}</div>;
      }
    },
    {
      title: t('rangePrice'),
      dataIndex: 'rangePrice',
      width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.price ? `${record.price} ${t('currency.sar')}` : '---'}
          </div>
        );
      }
    },
    {
      title: t('update'),
      dataIndex: 'update',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderUpdateBtn(record)}</div>
        );
      }
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderDeleteBtn(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
