import { createSlice } from '@reduxjs/toolkit';
import {
  getCarsYears,
  getSingleCarYear,
  createCarYear,
  updateCarYear,
  deleteCarYear
} from './sliceExtraReducers';

const initialState = {
  // all cars years
  isLoadingCarYears: false,
  fetchCarYearsCount: 0,
  fetchedCarsYears: [],
  selectedCarYear: null,
  isSubmittingCarYearForm: false,
  isSubmittingCarYear: false,
  selectedRowsIds: '',
  carYearsPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // single car Year
  isLoadingSingleCarYear: false,
  fetchSingleCarYearCount: 0,
  fetchedSingleCarYear: null,
  isSubmittingSingleCarYear: false,
  ///////////////////////
  //
  deleteCarYearModalOpened: false,
  deleteMultiCarYearModalOpened: false
};

const carsYearsSlice = createSlice({
  name: 'carsYears',
  initialState,
  reducers: {
    setSelectedCarYearAction: (sliceState, action) => {
      sliceState.selectedCarYear = action.payload;
    },
    setDeleteCarYearModalOpenedAction: (sliceState, action) => {
      sliceState.deleteCarYearModalOpened = action.payload;
    },
    setDeleteMultiCarYearModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiCarYearModalOpened = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    }
  },
  extraReducers: (builder) => {
    getCarsYears({ builder });
    getSingleCarYear({ builder });
    createCarYear({ builder });
    updateCarYear({ builder });
    deleteCarYear({ builder });
  }
});

const carYearsReducer = carsYearsSlice.reducer;

export const {
  setSelectedCarYearAction,
  setDeleteCarYearModalOpenedAction,
  setDeleteMultiCarYearModalOpenedAction,
  setSelectedRowsIdsAction
} = carsYearsSlice.actions;

export default carYearsReducer;
