import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlusIcon, TrashIcon } from '@components/icons';
import AntdTextarea from '@components/shared/antd-form-components/AntdTextarea';
import ProductAddonFields from './ProductAddonFields';
import SharedOrderTextField from './SharedOrderTextField';
import ProductSizesFields from './ProductSizesFields';

const ProductsFormFields = ({ control, setValue, errors, sharedFormLabel }) => {
  const { i18n } = useTranslation();
  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });

  return (
    <div className="order-form-products-fields-wrapper">
      <AnimatePresence>
        <motion.button
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              delay: 0.4,
              duration: 0.4,
              type: 'ease-in-out'
            }
          }}
          className={`add-product-btn ${i18n.dir()}`}
          type="button"
          style={{
            cursor: !errors?.products ? 'pointer' : 'default',
            opacity: !errors?.products ? '1' : '0.5'
          }}
          onClick={() => {
            if (!errors?.products)
              appendProductField({
                name: '',
                quantity: '',
                unitPrice: '',
                note: '',
                addon: [],
                sizes: []
              });
          }}
        >
          <PlusIcon />
          {t('orderForm.addProduct')}
        </motion.button>
      </AnimatePresence>

      {productsFields?.length > 0 &&
        productsFields.map((item, index) => {
          return (
            item && (
              <motion.div
                initial={{
                  opacity: 0,
                  translateX: i18n.dir() === 'ltr' ? 102 : -102
                }}
                animate={{
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.1,
                    duration: 0.3,
                    type: 'ease-in-out'
                  }
                }}
                key={item.id}
                className="products-fields-number-wrapper"
              >
                <div className="fields-wrapper">
                  <div className="name-qty-price-delete">
                    <SharedOrderTextField
                      name={`products.${index}.name`}
                      transName="productName"
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      sharedFormLabel={sharedFormLabel}
                      errorMsg={
                        errors?.products?.length > 0 &&
                        errors.products[index] &&
                        errors.products[index].name &&
                        errors.products[index].name.message
                      }
                      validateStatus={
                        errors?.products?.length > 0 &&
                        errors.products[index] &&
                        errors.products[index].name
                          ? 'error'
                          : ''
                      }
                    />
                    <div className="qty-price">
                      <SharedOrderTextField
                        name={`products.${index}.quantity`}
                        transName="productQty"
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        sharedFormLabel={sharedFormLabel}
                        errorMsg={
                          errors?.products?.length > 0 &&
                          errors.products[index] &&
                          errors.products[index].quantity &&
                          errors.products[index].quantity.message
                        }
                        validateStatus={
                          errors?.products?.length > 0 &&
                          errors.products[index] &&
                          errors.products[index].quantity
                            ? 'error'
                            : ''
                        }
                      />
                      <SharedOrderTextField
                        name={`products.${index}.unitPrice`}
                        transName="productPrice"
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        sharedFormLabel={sharedFormLabel}
                        prefix={t('currency.sar')}
                        errorMsg={
                          errors?.products?.length > 0 &&
                          errors.products[index] &&
                          errors.products[index].unitPrice &&
                          errors.products[index].unitPrice.message
                        }
                        validateStatus={
                          errors?.products?.length > 0 &&
                          errors.products[index] &&
                          errors.products[index].unitPrice
                            ? 'error'
                            : ''
                        }
                      />
                      <button
                        className="delete-btn"
                        onClick={() => {
                          removeProductField(index);
                        }}
                      >
                        <TrashIcon color="red" />
                      </button>
                    </div>
                  </div>
                  <div
                    className={`custom-text-area-wrapper product-note ${i18n.dir()}`}
                  >
                    <AntdTextarea
                      rows={4}
                      className="custom-text-area product-note-area"
                      name={`products.${index}.note`}
                      errorMsg={errors[`products.${index}.note`]?.message}
                      validateStatus={
                        errors[`products.${index}.note`] ? 'error' : ''
                      }
                      control={control}
                    />
                    <p className="custom-label">
                      {sharedFormLabel('productNote')}
                    </p>
                  </div>

                  <div className="addons-sizes-wrapper">
                    {/* Addons */}
                    <ProductAddonFields
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      sharedFormLabel={sharedFormLabel}
                      productIndex={index}
                    />

                    <span className="sep"></span>

                    {/* Sizes */}
                    <ProductSizesFields
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      sharedFormLabel={sharedFormLabel}
                      productIndex={index}
                    />
                  </div>
                </div>

                <div className={`number-wrap ${i18n.dir()}`}>
                  <span>#{index + 1}</span>
                </div>
              </motion.div>
            )
          );
        })}
    </div>
  );
};

export default ProductsFormFields;
