/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import AntdTable from '@components/shared/antd-table';
import { useTranslation } from 'react-i18next';
import TableColumns from './TableColumns';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  setConfirmModalAction,
  setSelectedOrderAction,
  setFetchedOrdersAction,
  setSelectedRowsIdsAction,
  setDeleteMultiOrderModalOpenedAction,
  setDeleteOrderModalOpenedAction
} from '@redux/orders-slice/ordersSlice';
import { confirmModalTypesValues } from '@mocks/index';
import {
  allOrdersAction,
  changeOrderStatusAction,
  deleteOrderAction
} from '@redux/orders-slice/ordersSliceThunkApi';
import ConfirmModal from '@components/shared/confirm-modal';
import appRouterLinks from '@routes/appRouterLinks';
import { useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { db } from '@fb';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { t } from 'i18next';
import LoadingModal from '@components/shared/loading-modal';
////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
const OrdersTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingOrders = useSelector((store) => store.orders.isLoadingOrders);
  const fetchOrdersCount = useSelector(
    (store) => store.orders.fetchOrdersCount
  );
  const fetchedOrders = useSelector((store) => store.orders.fetchedOrders);
  const ordersPagination = useSelector(
    (store) => store.orders.ordersPagination
  );

  const selectedOrder = useSelector((store) => store.orders.selectedOrder);
  const confirmModalType = useSelector(
    (store) => store.orders.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.orders.confirmModal.opened
  );
  const deleteOrderModalOpened = useSelector(
    (store) => store.orders.deleteOrderModalOpened
  );
  const deleteMultiOrderModalOpened = useSelector(
    (store) => store.orders.deleteMultiOrderModalOpened
  );
  const selectedRowsIds = useSelector((store) => store.orders.selectedRowsIds);
  const isSubmittingOrder = useSelector(
    (store) => store.orders.isSubmittingOrder
  );

  const user = useSelector((store) => store.user.user);

  const [firebaseData, setFirebaseData] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => {
      return {
        ...obj,
        key: obj?.id
      };
    });
  };

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      if (isMounted) {
        const action = await dispatch(
          allOrdersAction({
            filterObj: {
              ...values
              // active: activeTypes.active,
            }
          })
        );

        const res = action?.payload?.res;
        if (checkSuccessRes(res)) {
          const ordersRef = ref(db, 'Orders');
          onValue(ordersRef, (snapshot) => {
            const data = snapshot.val();
            setFirebaseData(data);
          });
        }
      }
    };

    getData();

    return () => {
      isMounted = false;
    };
  }, [fetchOrdersCount, search, i18n.language]);

  // useEffect(() => {
  //   const starCountRef = ref(db, 'Orders');
  //   onValue(starCountRef, (snapshot) => {
  //     const data = snapshot.val();
  //     setFirebaseData(data);
  //   });
  // }, []);

  const getNewOrders = (staticOrders, realTimeOrders) => {
    let newOrdersArr = staticOrders?.length > 0 ? [...staticOrders] : [];
    if (
      staticOrders?.length > 0 &&
      realTimeOrders &&
      typeof realTimeOrders === 'object' &&
      Object.keys(realTimeOrders)?.length > 0
    ) {
      for (let key in realTimeOrders) {
        const foundIndexInStaticOrdersArr = newOrdersArr.findIndex(
          // (order) => order?.orderId == key
          (order) => order?.id == key
        );
        // * if not found in static orders arr
        // * then push real time obj to static orders arr

        if (foundIndexInStaticOrdersArr === -1) {
          // newOrdersArr.push(realTimeOrders[key]);
          const item = realTimeOrders[key];
          newOrdersArr.push(item);
        }
        // * if found in static orders arr
        // * then replace the found order obj with the real time object
        else {
          newOrdersArr[foundIndexInStaticOrdersArr] = {
            ...newOrdersArr[foundIndexInStaticOrdersArr],
            ...realTimeOrders[key]
          };
        }
      }
    } else if (
      (!newOrdersArr || newOrdersArr?.length === 0) &&
      realTimeOrders &&
      typeof realTimeOrders === 'object' &&
      Object.keys(realTimeOrders)?.length > 0
    ) {
      for (let key in realTimeOrders) {
        newOrdersArr.push(realTimeOrders[key]);
      }
    }

    return newOrdersArr;
  };

  useEffect(() => {
    dispatch(setFetchedOrdersAction(getNewOrders(fetchedOrders, firebaseData)));

    return () => dispatch(setFetchedOrdersAction([]));
  }, [firebaseData]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedOrders?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedOrders);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchOrdersCount, JSON.stringify(fetchedOrders)]);

  let isMounted = true;
  const handleToggle = () => {
    if (isMounted && selectedOrder?.id) {
      const formData = new FormData();
      formData.append('order_id', selectedOrder.id);
      dispatch(
        changeOrderStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const handleDelete = () => {
    if (isMounted && selectedOrder?.id) {
      const formData = new FormData();
      formData.append('order_id', selectedOrder.id);
      dispatch(
        deleteOrderAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('order_id', selectedRowsIds);
      dispatch(
        deleteOrderAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={1500}
        showHeader={true}
        className={`orders-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedOrders}
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedOrderAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        //
        isLoadingTableData={isLoadingOrders}
        user={user}
        handleRowClick={(record) => {
          navigate(appRouterLinks.orderDetails(record?.id));
        }}
        hasPagination={true}
        tablePagination={ordersPagination}
        baseTablePageUrl={`${appRouterLinks.orders}?searchText=${
          values?.searchText || ''
        }&status=${values?.status || ''}`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteOrderModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedOrderAction(null));
          }
          dispatch(setDeleteOrderModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedOrder?.id || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiOrderModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiOrderModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedOrderAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleToggle}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من تفعيل ${selectedOrder?.name || ''}`}</h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من ايقاف تفعيل ${selectedOrder?.name || ''}`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {isSubmittingOrder && <LoadingModal />}
    </>
  );
};

export default OrdersTable;
