import { createSlice } from '@reduxjs/toolkit';
import {
  getCarsModels,
  getSingleCarModel,
  createCarModel,
  updateCarModel,
  deleteCarModel,
  deleteSingleCarModel
} from './sliceExtraReducers';

const initialState = {
  // all cars Models
  isLoadingCarModels: false,
  fetchCarModelsCount: 0,
  fetchedCarsModels: [],
  selectedCarModel: null,
  selectedRowsIds: '',
  isSubmittingCarModel: false,
  isSubmittingCarModelStatus: false,
  isSubmittingCarModelForm: false,
  carModelsPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // single car Model
  isLoadingSingleCarModel: false,
  fetchSingleCarModelCount: 0,
  fetchedSingleCarModel: null,
  isSubmittingSingleCarModel: false,
  ///////////////////////
  //
  previewCarModelImgModalOpened: false,
  //
  deleteCarModelModalOpened: false,
  deleteMultiCarModelModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const carsModelsSlice = createSlice({
  name: 'carsModels',
  initialState,
  reducers: {
    setSelectedCarModelAction: (sliceState, action) => {
      sliceState.selectedCarModel = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteCarModelModalOpenedAction: (sliceState, action) => {
      sliceState.deleteCarModelModalOpened = action.payload;
    },
    setDeleteMultiCarModelModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiCarModelModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setPreviewModelImgModalOpened: (sliceState, action) => {
      sliceState.previewCarModelImgModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    getCarsModels({ builder });
    getSingleCarModel({ builder });
    createCarModel({ builder });
    updateCarModel({ builder });
    deleteCarModel({ builder });
    deleteSingleCarModel({ builder });
  }
});

const carModelsReducer = carsModelsSlice.reducer;

export const {
  setSelectedCarModelAction,
  setDeleteCarModelModalOpenedAction,
  setConfirmModalAction,
  setPreviewModelImgModalOpened,
  setDeleteMultiCarModelModalOpenedAction,
  setSelectedRowsIdsAction
} = carsModelsSlice.actions;

export default carModelsReducer;
