import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import AntdTextarea from '../../../../components/shared/antd-form-components/AntdTextarea';
import CustomAntdDatePicker from '../../../../components/shared/antd-form-components/CustomAntdDatePicker';
import CustomRadioGroup from '../../../../components/shared/antd-form-components/CustomRadioGroup';
import { isSpecialArr, paymentTypesArr } from '../../../../mocks';
import SharedOrderTextField from './SharedOrderTextField';

const MainOrderFormFields = ({
  control,
  setValue,
  errors,
  sharedFormLabel,
  sharedFormPlaceholder,
  register,
  watch
}) => {
  const { i18n, t } = useTranslation();

  return (
    <div className="main-form-fields">
      <div className="order-id-radios-wrapper">
        <SharedOrderTextField
          name="orderId"
          transName="orderId"
          control={control}
          setValue={setValue}
          errors={errors}
          errName="orderId"
          sharedFormLabel={sharedFormLabel}
        />

        <div className="two-radios-groups">
          <CustomRadioGroup
            name="paymentType"
            arr={paymentTypesArr(t)}
            register={register}
            watch={watch}
            setValue={setValue}
            label={t('paymentType')}
          />
          <CustomRadioGroup
            name="isSpecial"
            arr={isSpecialArr(t)}
            register={register}
            watch={watch}
            setValue={setValue}
            label={t('isSpecial')}
          />
        </div>
      </div>

      <div className="prices-wrapper">
        <SharedOrderTextField
          name="productPrice"
          transName="orderNetPrice"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          prefix={t('currency.sar')}
          disabled={
            watch('products')?.length > 0 && watch('products')[0]?.unitPrice
              ? true
              : false
          }
          sm
        />

        <SharedOrderTextField
          name="deliveryPrice"
          transName="deliveryPrice"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          prefix={t('currency.sar')}
          sm
        />

        <SharedOrderTextField
          name="productTax"
          transName="productTax"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          prefix="%"
          sm
        />
        <SharedOrderTextField
          name="deliveryTax"
          transName="deliveryTax"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          prefix="%"
          sm
        />
      </div>

      <div className="dates-wrapper">
        <label className="custom-date-input order-date-label">
          <span className="label-span">{sharedFormLabel('createdTime')}</span>
          <CustomAntdDatePicker
            showTime={true}
            disablePast
            control={control}
            name="createdTime"
            placeholder={sharedFormPlaceholder('createdTime')}
            className="order-discount-picker"
            formClassName="create-product-form"
            errorMsg={errors?.createdTime && errors.createdTime.message}
            format={
              i18n.language === 'ar' ? 'DD-MM-YYYY h:m a' : 'YYYY-MM-DD h:m a'
            }
          />
        </label>
        <label className="custom-date-input order-date-label">
          <span className="label-span">
            {sharedFormLabel('pickupTimeRestaurant')}
          </span>
          <CustomAntdDatePicker
            showTime={true}
            disablePast
            control={control}
            name="pickupTimeRestaurant"
            placeholder={sharedFormPlaceholder('pickupTimeRestaurant')}
            className="order-discount-picker"
            formClassName="create-product-form"
            errorMsg={
              errors?.pickupTimeRestaurant &&
              errors.pickupTimeRestaurant.message
            }
            format={
              i18n.language === 'ar' ? 'DD-MM-YYYY h:m a' : 'YYYY-MM-DD h:m a'
            }
          />
        </label>
        <label className="custom-date-input order-date-label">
          <span className="label-span">
            {sharedFormLabel('acceptedTimeRestaurant')}
          </span>
          <CustomAntdDatePicker
            showTime={true}
            disablePast
            control={control}
            name="acceptedTimeRestaurant"
            placeholder={sharedFormPlaceholder('acceptedTimeRestaurant')}
            className="order-discount-picker"
            formClassName="create-product-form"
            errorMsg={
              errors?.acceptedTimeRestaurant &&
              errors.acceptedTimeRestaurant.message
            }
            format={
              i18n.language === 'ar' ? 'DD-MM-YYYY h:m a' : 'YYYY-MM-DD h:m a'
            }
          />
        </label>
      </div>

      <div
        className={`custom-text-area-wrapper order-note-wrapper ${i18n.dir()}`}
      >
        <AntdTextarea
          rows={4}
          className="custom-text-area order-note"
          name="note"
          errorMsg={errors?.note && errors?.note?.message}
          validateStatus={errors?.note ? 'error' : ''}
          control={control}
        />
        <p className="custom-label">{sharedFormLabel('orderNote')}</p>
      </div>
    </div>
  );
};

export default MainOrderFormFields;
