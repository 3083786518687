import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allCitiesAction,
  singleCityAction,
  changeCityStatusAction,
  changeSingleCityStatusAction,
  deleteCityAction,
  deleteSingleCityAction,
  createCityAction,
  updateCityAction
} from './citiesSliceThunkApis';

export function getCities({ builder }) {
  const { pending, fulfilled, rejected } = allCitiesAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingCities = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingCities = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedCities = data?.data;
        if (data?.pagination) sliceState.citiesPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingCities = false;
    });
}

export function getSingleCity({ builder }) {
  const { pending, fulfilled, rejected } = singleCityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleCity = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleCity = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleCity = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleCity = false;
    });
}

export function createCity({ builder }) {
  const { pending, fulfilled, rejected } = createCityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCityForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCityForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchCitiesCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCityForm = false;
    });
}

export function updateCity({ builder }) {
  const { pending, fulfilled, rejected } = updateCityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCityForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCityForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleCityCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCityForm = false;
    });
}
export function changeCityStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeCityStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCityStatus = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCityStatus = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchCitiesCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCityStatus = false;
    });
}

export function changeSingleCityStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleCityStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCity = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCity = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleCityCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCity = false;
    });
}

export function deleteCity({ builder }) {
  const { pending, fulfilled, rejected } = deleteCityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCity = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCity = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCityModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.deleteMultiCityModalOpened = false;
        sliceState.fetchCitiesCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCity = false;
    });
}

export function deleteSingleCity({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleCityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCity = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCity = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCityModalOpened = false;
        // sliceState.fetchSingleCityCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCity = false;
    });
}
