import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import useSingleCarBrand from '@customHooks/useSingleCarBrand';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Link, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import CarBrandForm from './car-brand-form';
import LoadingModal from '@components/shared/loading-modal';

const NewCarBrandPage = () => {
  const params = useParams();
  const isLoadingSingleCarBrand = useSelector(
    (store) => store.carBrands.isLoadingSingleCarBrand
  );
  const isSubmittingCarBrandForm = useSelector(
    (store) => store.carBrands.isSubmittingCarBrandForm
  );
  const fetchedSingleCarBrand = useSelector(
    (store) => store.carBrands.fetchedSingleCarBrand
  );

  useSingleCarBrand();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleCarBrand && params?.brand_id
            ? `${t('update')} ${fetchedSingleCarBrand?.name}` ||
              `${
                fetchedSingleCarBrand?.id ? `#${fetchedSingleCarBrand.id}` : ''
              }` ||
              ' '
            : t('newCarBrand')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.vehiclesBrands}>
                {t('vehicleBrands')}
              </Link>
            )
          },
          {
            title:
              fetchedSingleCarBrand && params?.brand_id
                ? fetchedSingleCarBrand?.name ||
                  `${
                    fetchedSingleCarBrand?.id
                      ? `#${fetchedSingleCarBrand.id}`
                      : ''
                  }` ||
                  ' '
                : t('newCarBrand')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-car-brands-page">
        <SharedPageHeader />

        <CarBrandForm
          fetchedObj={params?.brand_id ? fetchedSingleCarBrand : null}
          isSubmittingForm={isSubmittingCarBrandForm}
        />
      </div>

      {(isSubmittingCarBrandForm || isLoadingSingleCarBrand) && (
        <LoadingModal />
      )}
    </PageWrapperMotion>
  );
};

export default NewCarBrandPage;
