import CustomTag from '@components/shared/custom-tag';
import { carTypesArr } from '@mocks/index';
import { t } from 'i18next';
import './styles.scss';

const DriverCarInfoCard = ({ obj }) => {
  return (
    <div className="driver-car-info-card">
      <div className="card-header">
        {t('carInfo')}

        <CustomTag status={obj?.carType} arr={carTypesArr(t)} />
      </div>
      <div className="card-info">
        <div className="info-row brand">
          <span>{t('carMark')} : </span>
          <span>{obj?.brand?.name || '---'}</span>
        </div>
        <div className="info-row model">
          <span>{t('carModel')} : </span>
          <span>{obj?.carModel?.name || '---'}</span>
        </div>
        <div className="info-row year">
          <span>{t('carYear')} : </span>
          <span>{obj?.year?.name || '---'}</span>
        </div>
        <div className="info-row plate">
          <span>{t('plateNumbers')} : </span>
          <span>{obj?.plateNumbers || '---'}</span>
        </div>
        <div className="info-row plate">
          <span>{t('plateLetters')} : </span>
          <span>{obj?.plateLetters || '---'}</span>
        </div>
        <div className="info-row serial-number">
          <span>{t('carSerialNumber')} : </span>
          <span>{obj?.serialNumber || '---'}</span>
        </div>
      </div>
    </div>
  );
};

export default DriverCarInfoCard;
