/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createCarYearAction,
  updateCarYearAction
} from '@redux/cars-years-slice/carsYearsSliceThunkApis';
import { Button, Form } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import getFormSchema from './carYearSchema';
import './styles.scss';

const CarYearForm = ({ fetchedObj, isSubmittingForm }) => {
  const { i18n } = useTranslation();

  const params = useParams();

  const defaultValues = {
    year: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        fetchedObj,
        t
      })
    ),
    mode: 'all',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values
  useEffect(() => {
    if (fetchedObj && params?.year_id) {
      setValue('year', fetchedObj?.year || defaultValues.year);
    }
  }, [fetchedObj, params?.year_id]);

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`carYearForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      year: fetchedObj?.year || ''
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('year', data.year);

    try {
      if (!fetchedObj && !params?.year_id) {
        const action = await dispatch(
          createCarYearAction({
            dataToBeSubmitted: formData
          })
        );
        const res = action.payload.res;
        if (checkSuccessRes(res)) {
          resetFormInputs();
        }
      } else {
        formData.append('year_id', fetchedObj.id);
        await dispatch(
          updateCarYearAction({
            dataToBeSubmitted: formData
          })
        );
      }
    } catch (e) {
      // console.log('e : ', e);
      throw e;
    }
  };

  return (
    <div className="car-year-form-wrapper">
      <Form
        className="car-year-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">
            {fetchedObj ? t('updateYear') : t('newYear')}
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="year"
              type="text"
              errorMsg={errors?.year?.message}
              validateStatus={errors?.year ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('year', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('year')}</p>
          </div>

          <div className="submit-cancel-btns">
            {watch('year') && (
              <Button
                icon={<CloseIcon width={18} height={18} />}
                className="cancel-btn"
                onClick={resetFormInputs}
              >
                {t('cancel')}
              </Button>
            )}

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm
                ? t('saving')
                : fetchedObj
                ? t('carYearForm.editYear')
                : t('carYearForm.addYear')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CarYearForm;
