/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { allDriversAction } from '@redux/drivers-slice/driversSliceThunkApi';
import debounce from 'lodash.debounce';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';

const DriversSelectOptions = ({ label, control, setValue, errors }) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const fetchedDrivers = useSelector((store) => store.drivers.fetchedDrivers);
  const fetchedDriversCount = useSelector(
    (store) => store.drivers.fetchedDriversCount
  );
  const driversPagination = useSelector(
    (store) => store.drivers.driversPagination
  );
  const isLoadingDrivers = useSelector(
    (store) => store.drivers.isLoadingDrivers
  );

  const [selectOptionArr, setSelectOptionArr] = useState([]);
  useEffect(() => {
    if (fetchedDrivers?.length === 0 || !fetchedDrivers) {
      setSelectOptionArr((prev) => {
        return [...prev];
      });
    } else if (
      fetchedDrivers?.length > 0 &&
      driversPagination?.current_page > 1
    ) {
      setSelectOptionArr((prev) => {
        return [...prev, ...fetchedDrivers];
      });
    } else setSelectOptionArr([...fetchedDrivers]);
  }, [fetchedDrivers]);

  // get all drivers for form select option
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allDriversAction({
          filterObj: {
            // ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchedDriversCount, search, i18n.language]);

  const handleClickMore = () => {
    if (driversPagination.current_page < driversPagination.total_pages)
      dispatch(
        allDriversAction({
          filterObj: {
            page: driversPagination.current_page + 1
          }
        })
      );
  };

  const [searchObj, setSearchObj] = useState({
    name: '',
    page: 1
  });
  const [query, setQuery] = useState('');

  const changeHandler = (t) => {
    setQuery(t);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);

  useEffect(() => {
    let isMounted = true;
    if (query && query.trim() && isMounted) {
      setSearchObj({
        name: query,
        page: 1
      });
    }

    return () => (isMounted = false);
  }, [query]);

  useEffect(() => {
    if (searchObj?.name) {
      dispatch(
        allDriversAction({
          filterObj: {
            searchText: searchObj?.name || '',
            page: searchObj?.page || 1
          }
        })
      );
    }
  }, [searchObj?.name, searchObj?.page]);

  return (
    <div className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}>
      <AntdSelectOption
        withPagination={
          driversPagination?.current_page < driversPagination?.total_pages
        }
        handleClickMore={handleClickMore}
        loadingMore={isLoadingDrivers}
        className="custom-select-field"
        name="user_id"
        errorMsg={errors?.user_id && errors.user_id.message}
        validateStatus={errors?.user_id ? 'error' : ''}
        control={control}
        setValue={setValue}
        options={
          selectOptionArr?.length > 0
            ? selectOptionArr.map((item) => ({
                title: item?.name || '---',
                value: item?.id ? String(item.id) : ''
              }))
            : []
        }
        showSearch={true}
        // onSearch={onSearch}
        onSearch={(t) => {
          debouncedChangeHandler(t);
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <p className="custom-label">{label}</p>
    </div>
  );
};

export default DriversSelectOptions;
