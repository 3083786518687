/* eslint-disable react-hooks/exhaustive-deps */
import CustomMap from '@components/custom-map';
import { Descriptions } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import './styles.scss';

const CustomerInfo = ({ obj, firebaseObj }) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    lat: '',
    lng: ''
  });

  useEffect(() => {
    if (obj?.lat && obj?.lng) {
      setSelectedLocation({
        lat: parseFloat(obj.lat),
        lng: parseFloat(obj?.lng)
      });
    }
  }, [obj?.lat, obj?.lgn]);

  if (!obj) {
    return (
      <Descriptions column={1} title={t('customerInfo')} bordered>
        <h2>{t('noCustomer')}</h2>
      </Descriptions>
    );
  } else if (obj) {
    return (
      <div className="order-customer-info">
        <div className="desc-map-wrapper">
          <Descriptions
            size="small"
            column={1}
            title={t('customerInfo')}
            bordered
          >
            <Descriptions.Item label={t('name')}>
              {firebaseObj?.clientName || obj?.name || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('phone')}>
              {obj?.phone || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('email')}>
              {obj?.email || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('gender')}>
              {obj?.gender || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('address')}>
              {obj?.address || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('city')}>
              {obj?.city || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('region')}>
              {obj?.region || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('district')}>
              {obj?.district || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('street')}>
              {obj?.street || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('houseNumber')}>
              {obj?.houseNumber || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('floorNumber')}>
              {obj?.floorNumber || '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('note')}>
              {obj?.note || '---'}
            </Descriptions.Item>
          </Descriptions>
          <div className="map-wrapper">
            <CustomMap
              width="100%"
              height="500px"
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              isDraggable={false}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default CustomerInfo;
