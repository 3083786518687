import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allCarModelsAction,
  singleCarModelAction,
  deleteCarModelAction,
  deleteSingleCarModelAction,
  createCarModelAction,
  updateCarModelAction
} from './carsModelsSliceThunkApis';

export function getCarsModels({ builder }) {
  const { pending, fulfilled, rejected } = allCarModelsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingCarModels = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingCarModels = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedCarsModels = data?.data;
        if (data?.pagination) sliceState.carModelsPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingCarModels = false;
    });
}
export function getSingleCarModel({ builder }) {
  const { pending, fulfilled, rejected } = singleCarModelAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleCarModel = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleCarModel = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleCarModel = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleCarModel = false;
    });
}
export function createCarModel({ builder }) {
  const { pending, fulfilled, rejected } = createCarModelAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarModelForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarModelForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchCarModelsCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarModelForm = false;
    });
}
export function updateCarModel({ builder }) {
  const { pending, fulfilled, rejected } = updateCarModelAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarModelForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarModelForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleCarModelCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarModelForm = false;
    });
}

export function deleteCarModel({ builder }) {
  const { pending, fulfilled, rejected } = deleteCarModelAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarModel = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarModel = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCarModelModalOpened = false;
        sliceState.deleteMultiCarModelModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.fetchCarModelsCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarModel = false;
    });
}
export function deleteSingleCarModel({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleCarModelAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCarModel = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCarModel = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCarModelModalOpened = false;
        // sliceState.fetchSingleCarModelCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCarModel = false;
    });
}
