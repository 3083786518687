/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeOrderAction } from '@redux/orders-slice/ordersSliceThunkApi';
import { Badge, Button, Collapse, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  calcProductsTotalPrice,
  calcOrderTotalPrice,
  calcSingleProductPrice
} from '@helpers/calcOrderPrices';
import ClientFormFields from './ClientFormFields';
import formDefaultValues from './formDefaultValues';
import MainOrderFormFields from './MainOrderFormFields';
import getFormSchema from './newOrderSchema';
import ProductsFormFields from './ProductsFormFields';
import StoreFormFields from './StoreFormFields';
import DecimalFormat from 'decimal-format';
import './styles.scss';
import moment from 'moment';
import appRouterLinks from '@routes/appRouterLinks';
import { useValidateImageURL } from 'use-validate-image-url';
import sleep from '@helpers/sleep';

const NewOrderForm = ({ fetchedObj, isSubmittingForm }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedClientAddress, setSelectedClientAddress] = useState('');
  const [selectedClientLocation, setSelectedClientLocation] = useState({
    lat: '',
    lng: ''
  });
  const [selectedStoreAddress, setSelectedStoreAddress] = useState('');
  const [selectedStoreLocation, setSelectedStoreLocation] = useState({
    lat: '',
    lng: ''
  });

  const defaultValues = formDefaultValues;
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    reset,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        fetchedObj,
        t
      })
    ),
    mode: 'all',
    defaultValues: {
      ...defaultValues
    }
  });

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`orderForm.${key}.label`);
  const sharedFormPlaceholder = (key) => t(`orderForm.${key}.placeholder`);

  const resetFormInputs = () => {
    reset({ ...defaultValues });
  };

  const df = new DecimalFormat('#,##0.00#');
  const dispatch = useDispatch();
  const [orderTotal, setOrderTotal] = useState(0);
  const [clientRate, setClientRate] = useState(0);
  const [storeRate, setStoreRate] = useState(0);

  useEffect(() => {
    moment.locale('en');
  }, [i18n.language]);

  const onSubmit = async (data) => {
    const createTimeValue = data.createdTime
      ? moment(data.createdTime.toDate()).format('YYYY-MM-DD HH:mm:ss')
      : '';
    const acceptTimeValue = data.acceptedTimeRestaurant
      ? moment(data.acceptedTimeRestaurant.toDate()).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      : '';
    const pickupTimeValue = data.pickupTimeRestaurant
      ? moment(data.pickupTimeRestaurant.toDate()).format('YYYY-MM-DD HH:mm:ss')
      : '';

    const objToSubmit = {
      orderId: data.orderId || '',
      createdTime: createTimeValue,
      acceptedTimeRestaurant: acceptTimeValue,
      pickupTimeRestaurant: pickupTimeValue,
      productPrice: data.productPrice || 0,
      taxProductPrice:
        data?.productTax && data?.productPrice
          ? (parseFloat(data.productPrice) * parseFloat(data.productTax)) / 100
          : 0,
      deliveryPrice: data?.deliveryPrice || 0,
      deliveryTaxPrice:
        data?.deliveryPrice && data?.deliveryTax
          ? (parseFloat(data.deliveryPrice) * parseFloat(data.deliveryTax)) /
            100
          : 0,
      note: data?.note || '',
      paymentType: data?.paymentType || '',
      isSpecial: data?.isSpecial || '',
      clientData:
        data?.clientData && typeof data.clientData === 'object'
          ? {
              ...data.clientData,
              rate: clientRate || '',
              lat: selectedClientLocation?.lat || '',
              lng: selectedClientLocation?.lng || ''
            }
          : null,
      storeData:
        data?.storeData && typeof data.storeData === 'object'
          ? {
              ...data.storeData,
              rate: storeRate || '',
              lat: selectedStoreLocation?.lat || '',
              lng: selectedStoreLocation?.lng || ''
            }
          : null,
      products:
        data?.products?.length > 0
          ? data.products.map((product) => ({
              ...product,
              totalPrice:
                calcSingleProductPrice({
                  productObj: product
                }) || 0
            }))
          : []
    };

    const action = await dispatch(
      makeOrderAction({
        dataToBeSubmitted: JSON.stringify(objToSubmit)
      })
    );
    const res = action.payload.res;
    if (checkSuccessRes(res)) {
      // resetFormInputs()
      if (res?.data?.data?.id)
        navigate(appRouterLinks?.orderDetails(res?.data?.data?.id));
    }
  };

  useEffect(() => {
    if (watch('products')?.length > 0 && watch('products')[0]?.unitPrice) {
      setValue(
        'productPrice',
        calcProductsTotalPrice({
          productsArr: watch('products')
        })
      );
    }
  }, [JSON.stringify(watch())]);

  useEffect(() => {
    const total = calcOrderTotalPrice({
      // productsTotalPrice:  calcProductsTotalPrice({
      //   productsArr: watch('products')
      // }),
      productsTotalPrice: watch('productPrice') || 0,
      deliveryPrice: watch('deliveryPrice'),
      productTax: watch('productTax'),
      deliveryTax: watch('deliveryTax')
    });
    setOrderTotal(total);
  }, [watch()]);

  return (
    <div className="new-order-form-wrapper">
      <Badge.Ribbon
        className="total-ribbon"
        text={
          <div className="ribbon-text">
            <span className="total-span">
              {df.format(parseFloat(orderTotal) > 0 ? orderTotal : 0)}
            </span>
            <span className="curr-span">{t('currency.sar')}</span>
          </div>
        }
      >
        <Form
          className="new-order-form custom-shared-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <div className="form-body with-bg">
            <div className="form-header bold-font">{t('newOrder')}</div>

            <MainOrderFormFields
              control={control}
              setValue={setValue}
              errors={errors}
              sharedFormLabel={sharedFormLabel}
              sharedFormPlaceholder={sharedFormPlaceholder}
              register={register}
              watch={watch}
            />

            <Collapse
              size="large"
              defaultActiveKey={['1']}
              className="client-fields-coll"
            >
              <Collapse.Panel header={t('clientFieldsLabel')} key="1">
                {/* Client fields */}
                <ClientFormFields
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  sharedFormLabel={sharedFormLabel}
                  watch={watch}
                  register={register}
                  setR={setClientRate}
                  selectedAddress={selectedClientAddress}
                  setSelectedAddress={setSelectedClientAddress}
                  selectedLocation={selectedClientLocation}
                  setSelectedLocation={setSelectedClientLocation}
                />
                {/* end client fields */}
              </Collapse.Panel>
              <Collapse.Panel header={t('storeFieldsLabel')} key="2">
                {/* Store fields */}
                <StoreFormFields
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  sharedFormLabel={sharedFormLabel}
                  watch={watch}
                  register={register}
                  setR={setStoreRate}
                  selectedAddress={selectedStoreAddress}
                  setSelectedAddress={setSelectedStoreAddress}
                  selectedLocation={selectedStoreLocation}
                  setSelectedLocation={setSelectedStoreLocation}
                />
                {/* end store fields */}
              </Collapse.Panel>

              <Collapse.Panel header={t('productsFieldsLabel')} key="3">
                <ProductsFormFields
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  sharedFormLabel={sharedFormLabel}
                  watch={watch}
                  register={register}
                />
              </Collapse.Panel>
            </Collapse>

            <div className="submit-cancel-btns">
              <AnimatePresence>
                {watch('orderId') && (
                  <motion.div
                    initial={{
                      opacity: 0,
                      translateX: i18n.dir() === 'ltr' ? -92 : 92
                    }}
                    animate={{
                      opacity: 1,
                      translateX: 0,
                      transition: {
                        duration: 0.8,
                        type: 'spring'
                      }
                    }}
                    exit={{
                      opacity: 0,
                      translateX: i18n.dir() === 'ltr' ? -92 : 92,
                      transition: {
                        duration: 0.8,
                        type: 'spring'
                      }
                    }}
                  >
                    <Button
                      icon={<CloseIcon width={18} height={18} />}
                      className="cancel-btn"
                      onClick={resetFormInputs}
                    >
                      {t('cancel')}
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>

              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                icon={
                  isSubmittingForm ? (
                    <LoadingOutlined />
                  ) : (
                    <CheckMarkIcon width={18} height={18} />
                  )
                }
                loading={isSubmittingForm}
              >
                {isSubmittingForm
                  ? t('saving')
                  : fetchedObj
                  ? t('orderForm.editOrder')
                  : t('orderForm.addOrder')}
              </Button>
            </div>
          </div>
        </Form>
      </Badge.Ribbon>
    </div>
  );
};

export default NewOrderForm;
