import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import 'react-resizable/css/styles.css';
import 'antd/dist/reset.css';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReactReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import './scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 32,
      color: '#d0ae6c'
    }}
    spin
  />
);

root.render(
  // <React.StrictMode>
  <Suspense
    fallback={
      <div
        className="custom-container"
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    }
  >
    <Router>
      <ReactReduxProvider store={store}>
        <App />
      </ReactReduxProvider>
    </Router>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
