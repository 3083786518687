import './styles.scss';

const StatisticsCard = ({ title, icon, value }) => {
  return (
    <div className="statistics-card">
      {icon && <div className="icon-wrap">{icon}</div>}

      <div className="card-body">
        {title && <div className="card-title">{title}</div>}
        {value && <div className="card-value">{value}</div>}
      </div>
    </div>
  );
};

export default StatisticsCard;
