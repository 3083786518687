import imgsSrcs from '@helpers/imgsSrcs';

const CustomEmpty = ({ children }) => {
  return (
    <div
      style={{
        height: 300,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <img
        style={{
          width: '100%',
          maxWidth: '300px'
        }}
        src={imgsSrcs?.emptyResultsImg}
        alt="empty"
      />
      {children}
    </div>
  );
};

export default CustomEmpty;
