// import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const sharedSchema = (t) => {
  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `carModelForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  return {
    name_en: Yup.string().required(
      sharedFormErr({
        key: 'name_en'
      })
    ),
    name_ar: Yup.string().required(
      sharedFormErr({
        key: 'name_ar'
      })
    ),
    brand_id: Yup.string().required(
      sharedFormErr({
        key: 'brand_id'
      })
    )
  };
};

const getFormSchema = ({ fetchedObj, t }) => {
  if (fetchedObj) {
    return Yup.object().shape({
      ...sharedSchema(t)
    });
  }
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

export default getFormSchema;
