import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allWithdrawalApi,
  acceptOrRejectWithdrawalApi
} from '@apis/delivery-methods-apis';
import customApiRequest from '@helpers/customApiRequest';

const customReq = ({ actionName, actionApi }) => {
  return createAsyncThunk(
    `/delivery/${actionName}`,
    async ({ filterObj, dataToBeSubmitted }, thunkAPI) => {
      if (filterObj)
        return customApiRequest({
          apiCall: await actionApi(filterObj),
          thunkAPI
        });
      else if (dataToBeSubmitted) {
        return customApiRequest({
          apiCall: await actionApi(dataToBeSubmitted),
          thunkAPI
        });
      }
      return customApiRequest({
        apiCall: await actionApi(),
        thunkAPI
      });
    }
  );
};

//////////////
// * Start withdrawal actions
export const getAllWithdrawalAction = createAsyncThunk(
  'delivery/getAllWithdrawalAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allWithdrawalApi(filterObj),
      thunkAPI
    });
  }
);
export const acceptOrRejectWithdrawalAction = createAsyncThunk(
  'delivery/acceptOrRejectWithdrawalAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await acceptOrRejectWithdrawalApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// * End withdrawal actions
