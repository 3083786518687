/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import {
  CheckMarkIcon,
  CloseIcon,
  UploadCloudIcon
} from '@components/icons/index';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';
import FileInput from '@components/shared/file-input/FileInput';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import { activeTypesArr } from '@mocks/index';
import {
  createCarBrandAction,
  updateCarBrandAction
} from '@redux/cars-brands-slice/carsBrandsSliceThunkApis';
import { Button, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import getFormSchema from './carBrandSchema';
import './styles.scss';

const CarBrandForm = ({ fetchedObj, isSubmittingForm }) => {
  const { i18n } = useTranslation();
  const params = useParams();
  const [files, setFiles] = useState(null);

  const defaultValues = {
    name_en: '',
    name_ar: '',
    status: '',
    logo: null
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        fetchedObj,
        t
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  useEffect(() => {
    if (!params?.brand_id) {
      setFiles(null);
    }
  }, [params?.brand_id]);

  // handle default values
  useEffect(() => {
    if (fetchedObj && params?.brand_id) {
      setValue('name_en', fetchedObj?.name_en || defaultValues.name_en);
      setValue('name_ar', fetchedObj?.name_ar || defaultValues.name_ar);
      setValue('status', String(fetchedObj?.status) || defaultValues.status);
    }
  }, [fetchedObj, params?.brand_id]);

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`carBrandForm.${key}.label`);

  const resetFormInputs = () => {
    setFiles(null);
    reset({
      name_en: fetchedObj?.name_en || '',
      name_ar: fetchedObj?.name_ar || '',
      status: fetchedObj?.status ? String(fetchedObj.status) : ''
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name_en', data.name_en);
    formData.append('name_ar', data.name_ar);
    // formData.append('status', data.status);
    if (data?.logo?.length > 0) {
      formData.append('logo', data.logo[0]);
    }

    if (!fetchedObj) {
      const action = await dispatch(
        createCarBrandAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action.payload.res;
      if (checkSuccessRes(res)) {
        resetFormInputs();
      }
    } else {
      formData.append('brand_id', fetchedObj.id);
      await dispatch(
        updateCarBrandAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <div className="car-brand-form-wrapper">
      <Form
        className="car-brand-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">
            {fetchedObj ? t('updateCarBrand') : t('newCarBrand')}
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name_en"
              type="text"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('name_en')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name_ar"
              type="text"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('name_ar')}</p>
          </div>

          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name="status"
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                activeTypesArr(t)?.length > 0 &&
                activeTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <p className="custom-label">{sharedFormLabel('status')}</p>
          </div>

          <div
            className="attatches-wrap"
            style={{
              marginBottom: 22
            }}
          >
            <FileInput
              files={files}
              setFiles={setFiles}
              name="logo"
              label=""
              max={5}
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              // accept="image/*"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.jiff']
              }}
              multiple={false}
              setValue={setValue}
              watch={watch}
              register={register}
              unregister={unregister}
              // setUrls={setUrls}
              dropzoneText={sharedFormLabel('logo')}
              className="product-images-dropzone"
              canDelete={false}
              icon={<UploadCloudIcon color="#00000073" />}
              // disabled={
              //   fetchedSingleProductDetails?.productImages?.length >= 5 &&
              //   watch('productImages')?.length >= 1
              //     ? true
              //     : false
              // }
              dropzoneUrls={
                fetchedObj?.logo && params?.brand_id
                  ? [{ url: fetchedObj.logo }]
                  : []
              }
              showError={errors?.logo}
              errorMsg={errors?.logo?.message}
            />
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {(watch('name_ar') ||
                watch('name_en') ||
                watch('status') ||
                watch('logo')) && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={resetFormInputs}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm
                ? t('saving')
                : fetchedObj
                ? t('carBrandForm.editBrand')
                : t('carBrandForm.addBrand')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CarBrandForm;
