/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import CustomMap from '@components/custom-map';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';
import AntdTextarea from '@components/shared/antd-form-components/AntdTextarea';
import CustomRadioGroup from '@components/shared/antd-form-components/CustomRadioGroup';
import { clientRolesArr, genderTypesArr } from '@mocks/index';
import { Rate } from 'antd';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import SharedOrderTextField from './SharedOrderTextField';
import './styles.scss';

const ClientFormFields = ({
  control,
  setValue,
  errors,
  sharedFormLabel,
  watch,
  register,
  setR,
  selectedAddress,
  setSelectedAddress,
  selectedLocation,
  setSelectedLocation
}) => {
  const { i18n } = useTranslation();

  return (
    <div className="client-data-fields-wrapper">
      {/* <p className="fields-label">{t('clientFieldsLabel')}</p> */}
      <div className="fields-wrapper">
        {/* Client data */}
        <SharedOrderTextField
          name="clientData.name"
          transName="clientName"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
          errorMsg={
            errors.clientData &&
            errors.clientData.name &&
            errors.clientData.name.message
          }
          validateStatus={
            errors.clientData && errors.clientData.name ? 'error' : ''
          }
        />
        <SharedOrderTextField
          name="clientData.phone"
          transName="clientPhone"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.address"
          transName="clientAddress"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.city"
          transName="clientCity"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.region"
          transName="clientRegion"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.district"
          transName="clientDistrict"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.street"
          transName="clientStreet"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.houseNumber"
          transName="clientHouseNumber"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="clientData.floorNumber"
          transName="clientFloorNumber"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />

        <div
          className={`custom-select-field-wrapper without-icon ${i18n.dir()} sm`}
        >
          <AntdSelectOption
            className="custom-select-field"
            name="clientData.role"
            errorMsg={
              errors['clientData.role'] && errors['clientData.role'].message
            }
            validateStatus={errors['clientData.role'] ? 'error' : ''}
            control={control}
            setValue={setValue}
            options={
              clientRolesArr(t)?.length > 0 &&
              clientRolesArr(t).map((obj) => ({
                title: obj?.name,
                value: obj?.value
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
          <p className="custom-label">{sharedFormLabel('clientRole')}</p>
        </div>

        <CustomRadioGroup
          name="clientData.gender"
          arr={genderTypesArr(t)}
          register={register}
          watch={watch}
          setValue={setValue}
        />

        <div className="client-rate-wrapper">
          {/* <p className="rate-label">{sharedFormLabel('clientRate')}</p> */}
          <Rate
            className="rate-client"
            allowClear={false}
            allowHalf={false}
            onChange={(v) => {
              setR(v);
            }}
          />
        </div>

        <div className={`custom-text-area-wrapper client-note ${i18n.dir()}`}>
          <AntdTextarea
            rows={6}
            className="custom-text-area"
            name="clientData.note"
            errorMsg={errors['clientData.note']?.message}
            validateStatus={errors['clientData.note'] ? 'error' : ''}
            control={control}
          />
          <p className="custom-label">{sharedFormLabel('clientNote')}</p>
        </div>

        <div className="map-wrap">
          {/* <img src={mapImg} alt="map" /> */}
          <CustomMap
            width="100%"
            height="322px"
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isDraggable={true}
            hasAutoComplete={true}
          />
        </div>

        {/* end client data */}
      </div>
    </div>
  );
};

export default ClientFormFields;
