import { createSlice } from '@reduxjs/toolkit';
import {
  getCities,
  getSingleCity,
  createCity,
  updateCity,
  changeCityStatus,
  changeSingleCityStatus,
  deleteCity,
  deleteSingleCity
} from './sliceExtraReducers';

const initialState = {
  // * all cities
  isLoadingCities: false,
  fetchCitiesCount: 0,
  fetchedCities: [],
  selectedCity: null,
  selectedRowsIds: '',
  isSubmittingCity: false,
  isSubmittingCityStatus: false,
  isSubmittingCityForm: false,
  citiesPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // * single city
  isLoadingSingleCity: false,
  fetchSingleCityCount: 0,
  fetchedSingleCity: null,
  isSubmittingSingleCity: false,
  ///////////////////////
  ///////////////////////
  deleteCityModalOpened: false,
  deleteMultiCityModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setSelectedCityAction: (sliceState, action) => {
      sliceState.selectedCity = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteCityModalOpenedAction: (sliceState, action) => {
      sliceState.deleteCityModalOpened = action.payload;
    },
    setDeleteMultiCityModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiCityModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: (builder) => {
    getCities({ builder });
    getSingleCity({ builder });
    createCity({ builder });
    updateCity({ builder });
    changeCityStatus({ builder });
    changeSingleCityStatus({ builder });
    deleteCity({ builder });
    deleteSingleCity({ builder });
  }
});

const citiesReducer = citiesSlice.reducer;

export const {
  setSelectedCityAction,
  setSelectedRowsIdsAction,
  setDeleteCityModalOpenedAction,
  setDeleteMultiCityModalOpenedAction,
  setConfirmModalAction
} = citiesSlice.actions;

export default citiesReducer;
