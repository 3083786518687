import { configureStore } from '@reduxjs/toolkit';
import carBrandsReducer from './cars-brands-slice/carsBrandsSlice';
import carModelsReducer from './cars-models-slice/carsModelsSlice';
import carYearsReducer from './cars-years-slice/carsYearsSlice';
import citiesReducer from './cities-slice/citiesSlice';
import countriesReducer from './countries-slice/countriesSlice';
import deliveryMethodsReducer from './delivery-methods-slice';
import driversReducer from './drivers-slice/driversSlice';
import generalAppReducer from './general-app-slice/generalAppSlice';
import nationalitiesReducer from './nationalities-slice/nationalitiesSlice';
import ordersReducer from './orders-slice/ordersSlice';
import regionsReducer from './regions-slice/regionsSlice';
import userReducer from './user-slice/userSlice';
import walletTransactionsReducer from './wallet-transactions-slice';
import withdrawalReducer from './withdrawal-slice';

export default configureStore({
  reducer: {
    user: userReducer,
    generalApp: generalAppReducer,
    orders: ordersReducer,
    drivers: driversReducer,
    carBrands: carBrandsReducer,
    carModels: carModelsReducer,
    carYears: carYearsReducer,
    countries: countriesReducer,
    cities: citiesReducer,
    regions: regionsReducer,
    nationalities: nationalitiesReducer,
    deliverMethods: deliveryMethodsReducer,
    withdrawals: withdrawalReducer,
    walletTransactions: walletTransactionsReducer
  }
});
