import './styles.scss';
import CustomImage from '@components/shared/custom-image';
import CustomTag from '@components/shared/custom-tag';
import { t } from 'i18next';
import { driverStatusArr } from '@mocks/index';
import { setPreviewDriverImgModalOpened } from '@redux/drivers-slice/driversSlice';
import { useDispatch, useSelector } from 'react-redux';
import ImageModal from '@components/shared/image-modal';

const DriverInfoCard = ({ obj }) => {
  const dispatch = useDispatch();
  const previewDriverImgModalOpened = useSelector(
    (store) => store.drivers.previewDriverImgModalOpened
  );

  return (
    <div className="driver-info-card">
      <div className="img-name-status-wrap">
        <button
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setPreviewDriverImgModalOpened(true));
          }}
          className="img-preview-btn"
        >
          <CustomImage src={obj?.image} className="driver-img" />
        </button>
        <div className="name-status-date">
          <p className="name">{obj?.name || 'Driver Name'}</p>
          {obj?.email && <p className="email">{obj?.email}</p>}
          <p className="date">{obj?.date || 'Joined 3 months ago'}</p>

          <div className="status-wrap">
            <CustomTag status={obj?.status} arr={driverStatusArr(t)} />
          </div>
        </div>
      </div>

      <div className="location-wrap">
        <div className="row-wrap">
          <div className="row-label">{t('country')}</div>
          <div className="row-value">{obj?.country?.name || '---'}</div>
        </div>
        <div className="row-wrap">
          <div className="row-label">{t('city')}</div>
          <div className="row-value">{obj?.city?.name || '---'}</div>
        </div>
        <div className="row-wrap">
          <div className="row-label">{t('region')}</div>
          <div className="row-value">{obj?.region?.name || '---'}</div>
        </div>
      </div>

      {
        <ImageModal
          title={t('driverImg')}
          src={obj?.image}
          open={previewDriverImgModalOpened}
          onCancel={() => dispatch(setPreviewDriverImgModalOpened(false))}
        />
      }
    </div>
  );
};

export default DriverInfoCard;
