import axios from 'axios';
import apisUrls from './apisUrls';

export const allCountriesApi = async (filterObj) => {
  return axios.get(apisUrls.countries(filterObj));
};

export const singleCountryApi = async (filterObj) => {
  return axios.get(apisUrls.singleCountry(filterObj));
};

export const createCountryApi = async (values) => {
  return axios.post(apisUrls.createCountry, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateCountryApi = async (values) => {
  return axios.post(apisUrls.updateCountry, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteCountryApi = async (values) => {
  return axios.post(apisUrls.deleteCountry, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const changeCountryStatusApi = async (values) => {
  return axios.post(apisUrls.changeCountryStatus, values);
};
