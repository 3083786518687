import AppBreadcrumb from '@components/shared/app-breadcrumb';
// import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@icons/index';
import './styles.scss';

const PageHeader = ({ withBack = true, title, icon, breadcrumbItems }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="page-header">
      <div className="custom-container">
        <div
          style={{
            display: 'flex',
            gap: 8
          }}
        >
          {withBack && (
            <Button
              onClick={() => {
                navigate(-1);
              }}
              shape="circle"
              type="text"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {i18n.dir() === 'ltr' ? (
                <ArrowLeftIcon width={18} height={18} />
              ) : (
                <ArrowRightIcon width={18} height={18} />
              )}
            </Button>
          )}
          {title ? (
            <h4 className="page-title">
              {/* {icon} */}
              {title}
            </h4>
          ) : null}
        </div>

        <AppBreadcrumb items={breadcrumbItems} />
      </div>
    </div>
  );
};

export default PageHeader;
