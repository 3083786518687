import MessageListItem from '@components/shared/message-list-item';
import { Link } from 'react-router-dom';
import './styles.scss';

const HomeMessagesList = () => {
  return (
    <div className="home-messages-list">
      <div className="list-header">
        <p className="list-title">Messages</p>
        <Link to="/">Show all</Link>
      </div>
      <MessageListItem />
      <MessageListItem />
      <MessageListItem />
      <MessageListItem />
      <MessageListItem />
    </div>
  );
};

export default HomeMessagesList;
