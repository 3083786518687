import customApiRequest from '@helpers/customApiRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allRegionsApi,
  changeRegionStatusApi,
  createRegionApi,
  deleteRegionApi,
  singleRegionApi,
  updateRegionApi
} from '@apis/regionsApis';

export const allRegionsAction = createAsyncThunk(
  'regions/allRegionsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allRegionsApi(filterObj),
      thunkAPI
    });
  }
);

export const singleRegionAction = createAsyncThunk(
  'regions/singleRegionAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleRegionApi(filterObj),
      thunkAPI
    });
  }
);

export const createRegionAction = createAsyncThunk(
  'regions/createRegionAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createRegionApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const updateRegionAction = createAsyncThunk(
  'regions/updateRegionAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateRegionApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeRegionStatusAction = createAsyncThunk(
  'regions/changeRegionStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeRegionStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const changeSingleRegionStatusAction = createAsyncThunk(
  'regions/changeSingleRegionStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeRegionStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteRegionAction = createAsyncThunk(
  'regions/deleteRegionAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteRegionApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteSingleRegionAction = createAsyncThunk(
  'regions/deleteSingleRegionAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteRegionApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
