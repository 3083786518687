import axios from 'axios';
import apisUrls from './apisUrls';

export const allCarModelsApi = async (filterObj) => {
  return axios.get(apisUrls.carModels(filterObj));
};

export const singleCarModelApi = async (filterObj) => {
  return axios.get(apisUrls.singleCarModel(filterObj));
};

export const createCarModelApi = async (values) => {
  return axios.post(apisUrls.createCarModel, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateCarModelApi = async (values) => {
  return axios.post(apisUrls.updateCarModel, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteCarModelApi = async (values) => {
  return axios.post(apisUrls.deleteCarModel, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
