/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setDeleteCarYearModalOpenedAction,
  setDeleteMultiCarYearModalOpenedAction,
  setSelectedCarYearAction,
  setSelectedRowsIdsAction
} from '@redux/cars-years-slice/carsYearsSlice';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '@components/shared/loading-modal';
import {
  allCarYearsAction,
  deleteCarYearAction
} from '@redux/cars-years-slice/carsYearsSliceThunkApis';
import './styles.scss';
import TableColumns from './TableColumns';

const CarYearsTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingCarYears = useSelector(
    (store) => store.carYears.isLoadingCarYears
  );
  const isSubmittingCarYear = useSelector(
    (store) => store.carYears.isSubmittingCarYear
  );
  const deleteCarYearModalOpened = useSelector(
    (store) => store.carYears.deleteCarYearModalOpened
  );
  const deleteMultiCarYearModalOpened = useSelector(
    (store) => store.carYears.deleteMultiCarYearModalOpened
  );
  const selectedRowsIds = useSelector(
    (store) => store.carYears.selectedRowsIds
  );
  const fetchCarYearsCount = useSelector(
    (store) => store.carYears.fetchCarYearsCount
  );
  const fetchedCarsYears = useSelector(
    (store) => store.carYears.fetchedCarsYears
  );
  const carYearsPagination = useSelector(
    (store) => store.carYears.carYearsPagination
  );
  const selectedCarYear = useSelector(
    (store) => store.carYears.selectedCarYear
  );

  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allCarYearsAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCarYearsCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedCarsYears?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedCarsYears);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCarYearsCount, fetchedCarsYears, fetchedCarsYears?.length]);

  let isMounted = true;
  const handleDelete = () => {
    if (isMounted && selectedCarYear?.id) {
      const formData = new FormData();
      formData.append('year_id', selectedCarYear.id);
      dispatch(
        deleteCarYearAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('year_id', selectedRowsIds);
      dispatch(
        deleteCarYearAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        width={900}
        // scroll={900}
        scroll={500}
        showHeader={true}
        className={`car-years-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedCarsYears}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteCarYearModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedCarYearAction(v))}
        //
        isLoadingTableData={isLoadingCarYears}
        user={user}
        // handleRowClick={(record, x) => {
        //   navigate(appRouterLinks.editVehicleYear(record?.id));
        // }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={carYearsPagination}
        baseTablePageUrl={`${appRouterLinks.vehiclesYears}?searchText=${
          values?.searchText || ''
        }`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteCarYearModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedCarYearAction(null));
          }
          dispatch(setDeleteCarYearModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedCarYear?.name || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiCarYearModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiCarYearModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {isSubmittingCarYear && <LoadingModal />}
    </>
  );
};

export default CarYearsTable;
