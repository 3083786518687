/* eslint-disable eqeqeq */
import './styles.scss';
import CustomImage from '@components/shared/custom-image';
import CustomTag from '@components/shared/custom-tag';
import { t } from 'i18next';
import {
  activeTypes,
  activeTypesArr,
  confirmModalTypesValues
} from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteCountryModalOpenedAction,
  setPreviewCountryLogoModalOpened
} from '@redux/countries-slice/countriesSlice';
import { useDispatch, useSelector } from 'react-redux';
import ImageModal from '@components/shared/image-modal';
import StatusSelectOption from '@components/shared/status-select-option';
import DeleteBtn from '@components/shared/delete-btn';
import UpdateBtn from '@components/shared/update-btn';
import { useNavigate } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';

const CountryDetailsCard = ({ obj, setSelectedStatus, selectedStatus }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const previewCountryImgModalOpened = useSelector(
    (store) => store.countries.previewCountryImgModalOpened
  );

  const handleChangeSelectOption = (value) => {
    const foundStatus = activeTypesArr(t).find((obj) => obj?.value == value);
    setSelectedStatus(foundStatus);
    dispatch(
      setConfirmModalAction({
        opened: true,
        type:
          foundStatus?.value === activeTypes?.active
            ? confirmModalTypesValues?.successModal
            : confirmModalTypesValues?.errorModal
      })
    );
  };

  return (
    <div className="country-info-card">
      <div className="img-actions-wrapper">
        <div className="img-name-status-wrap">
          <button
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setPreviewCountryLogoModalOpened(true));
            }}
            className="img-preview-btn"
          >
            <CustomImage src={obj?.logo} className="driver-img" />
          </button>
          <div className="name-status-date">
            <p className="name">{obj?.name || 'Driver Name'}</p>
            {obj?.email && <p className="email">{obj?.email}</p>}

            <div className="status-wrap">
              <CustomTag status={obj?.status} arr={activeTypesArr(t)} />
            </div>
          </div>
        </div>

        <div className="actions-wrapper">
          <StatusSelectOption
            statusArr={activeTypesArr(t)}
            selectedStatus={selectedStatus}
            handleChangeSelectOption={handleChangeSelectOption}
            fetchedDefaultStatus={obj?.status}
          />

          <DeleteBtn
            btnText={t('delete')}
            handleClickDeleteBtn={() => {
              dispatch(setDeleteCountryModalOpenedAction(true));
            }}
          />
          <UpdateBtn
            btnText={t('update')}
            handleClickUpdateBtn={() => {
              navigate(appRouterLinks.editCountry(obj?.id));
            }}
          />
        </div>
      </div>

      <div className="location-wrap">
        <div className="col-wrap">
          <div className="col-label">ID</div>
          <div className="col-value">#{obj?.id || '---'}</div>
        </div>
        <div className="col-wrap">
          <div className="col-label">{t('name_en')}</div>
          <div className="col-value">{obj?.name_en || '---'}</div>
        </div>
        <div className="col-wrap">
          <div className="col-label">{t('name_ar')}</div>
          <div className="col-value">{obj?.name_ar || '---'}</div>
        </div>
        <div className="col-wrap">
          <div className="col-label">{t('phoneKey')}</div>
          <div className="col-value">{obj?.phoneKey || '---'}</div>
        </div>
        <div className="col-wrap">
          <div className="col-label">{t('phoneNumbers')}</div>
          <div className="col-value">{obj?.phoneNumbers || '---'}</div>
        </div>
      </div>

      <ImageModal
        title={t('driverImg')}
        src={obj?.logo}
        open={previewCountryImgModalOpened}
        onCancel={() => dispatch(setPreviewCountryLogoModalOpened(false))}
      />
    </div>
  );
};

export default CountryDetailsCard;
