/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import ConfirmModal from '@components/shared/confirm-modal';
import CustomEmpty from '@components/shared/custom-empty';
import CustomImage from '@components/shared/custom-image';
import ImageModal from '@components/shared/image-modal';
import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import StatisticsCard from '@components/shared/statistics-card';
import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  ArrowUpIcon,
  CheckMarkIcon,
  CloseIcon,
  LineChartSolid,
  VehicleIcon
} from '@icons/index';
import {
  confirmModalTypesValues,
  driverStatusArr,
  driverStatusValues
} from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteDriverModalOpenedAction,
  setPreviewCarBackImgModalOpened,
  setPreviewCarFrontImgModalOpened
} from '@redux/drivers-slice/driversSlice';
import {
  changeSingleDriverStatusAction,
  deleteSingleDriverAction,
  singleDriverAction
} from '@redux/drivers-slice/driversSliceThunkApi';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteBtn from '../../components/shared/delete-btn';
import StatusSelectOption from '../../components/shared/status-select-option';
import DriverCarInfoCard from './driver-car-info-card';
import DriverInfoCard from './driver-info-card';
import DriverMoreInfoCard from './driver-more-info-card';
import './styles.scss';

const SingleDriverPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();

  const isLoadingSingleDriver = useSelector(
    (store) => store.drivers.isLoadingSingleDriver
  );
  const fetchedSingleDriver = useSelector(
    (store) => store.drivers.fetchedSingleDriver
  );
  const fetchSingleDriverCount = useSelector(
    (store) => store.drivers.fetchSingleDriverCount
  );
  const isSubmittingSingleDriver = useSelector(
    (store) => store.drivers.isSubmittingSingleDriver
  );

  const deleteDriverModalOpened = useSelector(
    (store) => store.drivers.deleteDriverModalOpened
  );
  const confirmModalOpened = useSelector(
    (store) => store.drivers.confirmModal.opened
  );
  const confirmModalType = useSelector(
    (store) => store.drivers.confirmModal.type
  );

  const previewCarFrontImgModalOpened = useSelector(
    (store) => store.drivers.previewCarFrontImgModalOpened
  );

  const previewCarBackImgModalOpened = useSelector(
    (store) => store.drivers.previewCarBackImgModalOpened
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        singleDriverAction({
          filterObj: {
            driver_id: params?.driver_id,
            ...values
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSingleDriverCount, search, params?.driver_id, i18n.language]);

  let isMounted = true;
  const handleDelete = async () => {
    if (isMounted && fetchedSingleDriver?.id) {
      const formData = new FormData();
      formData.append('driver_id', fetchedSingleDriver.id);

      const action = await dispatch(
        deleteSingleDriverAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action.payload.res;
      if (checkSuccessRes(res)) {
        navigate(appRouterLinks?.drivers);
      }
    }
  };

  const [selectedStatus, setSelectedStatus] = useState('');
  const handleChangeSelectOption = (value) => {
    const foundStatus = driverStatusArr(t).find((obj) => obj?.value == value);
    setSelectedStatus(foundStatus);

    dispatch(
      setConfirmModalAction({
        opened: true,
        type:
          foundStatus?.value !== driverStatusValues?.rejected
            ? confirmModalTypesValues?.successModal
            : confirmModalTypesValues?.errorModal
      })
    );
  };

  const handleChangeStatus = () => {
    if (isMounted && fetchedSingleDriver?.id) {
      const formData = new FormData();
      formData.append('driver_id', fetchedSingleDriver.id);
      formData.append('status', selectedStatus?.value);

      dispatch(
        changeSingleDriverStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleDriver?.name ||
          `${fetchedSingleDriver?.id ? `#${fetchedSingleDriver.id}` : ''}` ||
          ' '
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: <Link to={appRouterLinks.drivers}>{t('drivers')}</Link>
          },
          {
            title: t('details')
          }
        ]}
      />
    );
  };

  if (isLoadingSingleDriver) {
    return (
      <>
        <SharedPageHeader />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300
          }}
        >
          <LoadingOutlined />
        </div>
      </>
    );
  } else if (!fetchedSingleDriver) {
    return (
      <>
        <SharedPageHeader />
        <CustomEmpty>
          <h2
            style={{
              margin: '18px auto',
              textAlign: 'center'
            }}
          >
            {t('empty')}
          </h2>
        </CustomEmpty>
      </>
    );
  } else if (fetchedSingleDriver) {
    return (
      <>
        <PageWrapperMotion>
          <div className="single-driver-page">
            <SharedPageHeader />

            <div className="page-content">
              <div className="info-stats-wrapper">
                <div className="info-more-info-cards">
                  <DriverInfoCard obj={fetchedSingleDriver} />
                  <DriverMoreInfoCard obj={fetchedSingleDriver} />
                </div>

                <div className="actions-stats-wrapper">
                  <div className="actions-wrapper">
                    <StatusSelectOption
                      statusArr={driverStatusArr(t)}
                      fetchedDefaultStatus={fetchedSingleDriver?.status}
                      selectedStatus={selectedStatus}
                      handleChangeSelectOption={handleChangeSelectOption}
                    />

                    <DeleteBtn
                      btnText={t('deleteDriver')}
                      handleClickDeleteBtn={() => {
                        dispatch(setDeleteDriverModalOpenedAction(true));
                      }}
                    />
                  </div>
                  <div className="stats-wrapper">
                    <StatisticsCard
                      title="All orders"
                      value={
                        <div className="value-wrapper">
                          <ArrowUpIcon color="green" />
                          <p>100+</p>
                        </div>
                      }
                      icon={<LineChartSolid />}
                    />
                    <StatisticsCard
                      title="Accepted orders"
                      value={
                        <div className="value-wrapper">
                          <ArrowUpIcon color="green" />
                          <p>12+</p>
                        </div>
                      }
                      icon={<CheckMarkIcon />}
                    />
                    <StatisticsCard
                      title="Rejected orders"
                      value={
                        <div className="value-wrapper">
                          <p>3</p> <span>Orders</span>
                        </div>
                      }
                      icon={<CloseIcon />}
                    />
                  </div>
                  <DriverCarInfoCard obj={fetchedSingleDriver?.carData} />
                </div>
              </div>

              <div className="car-front-back-img">
                <div className="img-btn-wrap">
                  <div className="img-label">{t('carFrontImg')}</div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setPreviewCarFrontImgModalOpened(true));
                    }}
                    className="img-preview-btn"
                  >
                    <CustomImage
                      className="car-img front-img"
                      src={fetchedSingleDriver?.carData?.carImageFront}
                    />
                  </button>
                </div>

                <div className="img-btn-wrap">
                  <div className="img-label">{t('carBackImg')}</div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setPreviewCarBackImgModalOpened(true));
                    }}
                    className="img-preview-btn"
                  >
                    <CustomImage
                      className="car-img back-img"
                      src={fetchedSingleDriver?.carData?.carImageBack}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ImageModal
            title={t('carFrontImg')}
            src={fetchedSingleDriver?.carData?.carImageFront}
            open={previewCarFrontImgModalOpened}
            onCancel={() => dispatch(setPreviewCarFrontImgModalOpened(false))}
          />

          <ImageModal
            title={t('carBackImg')}
            src={fetchedSingleDriver?.carData?.carImageBack}
            open={previewCarBackImgModalOpened}
            onCancel={() => dispatch(setPreviewCarBackImgModalOpened(false))}
          />

          <ConfirmModal
            modalOpened={deleteDriverModalOpened}
            setModalOpened={(v) =>
              dispatch(setDeleteDriverModalOpenedAction(v))
            }
            handleAccept={handleDelete}
            modalTitle=""
            modalType={confirmModalTypesValues?.errorModal}
          >
            <h3>{t('sureDelete')}</h3>
          </ConfirmModal>
          {/*  */}
          {/*  */}
          <ConfirmModal
            modalOpened={confirmModalOpened}
            setModalOpened={(v) =>
              dispatch(
                setConfirmModalAction({
                  opened: v,
                  type: ''
                })
              )
            }
            handleAccept={handleChangeStatus}
            modalTitle=""
            modalType={confirmModalType}
            handleCancel={() => {
              const foundStatus = driverStatusArr(t).find(
                (obj) => obj?.value == fetchedSingleDriver?.status
              );
              setSelectedStatus(foundStatus);
            }}
          >
            <h3>
              {t('sureChangeDriverStatus')} {selectedStatus?.name}
            </h3>
          </ConfirmModal>

          {isSubmittingSingleDriver && <LoadingModal />}
        </PageWrapperMotion>
      </>
    );
  }
  return null;
};

export default SingleDriverPage;
