/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import getFormSchema from './carModelSchema';
import './styles.scss';

import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  createCarModelAction,
  updateCarModelAction
} from '@redux/cars-models-slice/carsModelsSliceThunkApis';
import { AnimatePresence, motion } from 'framer-motion';
import FormBrandSelectOption from './FormBrandSelectOption';

const CarModelForm = ({ fetchedObj, isSubmittingForm }) => {
  const { i18n } = useTranslation();

  const params = useParams();

  const defaultValues = {
    name_en: '',
    name_ar: '',
    brand_id: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        fetchedObj,
        t
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  const fetchedCarsBrands = useSelector(
    (store) => store.carBrands.fetchedCarsBrands
  );

  // handle default values
  useEffect(() => {
    if (fetchedObj && params?.model_id && fetchedCarsBrands?.length > 0) {
      setValue('name_en', fetchedObj?.name_en || defaultValues.name_en);
      setValue('name_ar', fetchedObj?.name_ar || defaultValues.name_ar);
      if (fetchedObj?.brand?.id)
        setValue('brand_id', String(fetchedObj.brand.id));
      else setValue('brand_id', '');
    }
  }, [fetchedObj, params?.model_id, fetchedCarsBrands]);

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`carModelForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      name_en: fetchedObj?.name_en || '',
      name_ar: fetchedObj?.name_ar || '',
      brand_id: fetchedObj?.brand_id ? String(fetchedObj.brand_id) : ''
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name_en', data.name_en);
    formData.append('name_ar', data.name_ar);
    formData.append('brand_id', data.brand_id);

    if (!fetchedObj && !params?.model_id) {
      const action = await dispatch(
        createCarModelAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action.payload.res;
      if (checkSuccessRes(res)) {
        resetFormInputs();
      }
    } else {
      formData.append('model_id', fetchedObj.id);
      await dispatch(
        updateCarModelAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <div className="car-model-form-wrapper">
      <Form
        className="car-model-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">
            {fetchedObj ? t('updateCarModel') : t('newCarModel')}
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name_en"
              type="text"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('name_en')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name_ar"
              type="text"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('name_ar')}</p>
          </div>

          <FormBrandSelectOption
            label={sharedFormLabel('brand_id')}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {(watch('name_ar') || watch('name_en') || watch('brand_id')) && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={resetFormInputs}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm
                ? t('saving')
                : fetchedObj
                ? t('carModelForm.editModel')
                : t('carModelForm.addModel')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CarModelForm;
