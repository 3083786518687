import AntdTable from '@components/shared/antd-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableColumns from './TableColumns';
import './styles.scss';
import { t } from 'i18next';

const ProductsInfo = ({ products }) => {
  const { i18n } = useTranslation();

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (products?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(products);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [products, products?.length]);

  return (
    <div className="order-products-table">
      {/* <h4
        style={{
          marginBottom: 12
        }}
      >
        {t('products')}
      </h4> */}
      <AntdTable
        bordered
        // scroll={900}
        scroll={1200}
        showHeader={true}
        className={`order-products-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={products}
        hasPagination={false}
      />
    </div>
  );
};

export default ProductsInfo;
