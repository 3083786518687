import axios from 'axios';
import apisUrls from './apisUrls';

export const allCarBrandsApi = async (filterObj) => {
  return axios.get(apisUrls.carBrands(filterObj));
};

export const singleCarBrandApi = async (filterObj) => {
  return axios.get(apisUrls.singleCarBrand(filterObj));
};

export const createCarBrandApi = async (values) => {
  return axios.post(apisUrls.createCarBrand, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateCarBrandApi = async (values) => {
  return axios.post(apisUrls.updateCarBrand, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteCarBrandApi = async (values) => {
  return axios.post(apisUrls.deleteCarBrand, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const changeCarBrandStatusApi = async (values) => {
  return axios.post(apisUrls.changeCarBrandStatus, values);
};
