import { Navigate, Route } from 'react-router-dom';
import sharedRouterLinks from '@routes/shared-routes/sharedRouterLinks';
import appRouterLinks from '@routes/appRouterLinks';

const protectMe = ({
  path,
  component,
  key,
  redirectRoute,
  loggedIn,
  user,
  authorized,
  accountTypesArr,
  withLayout = true,
  Children // nested routes
}) => {
  const renderElement = () => {
    // if (loggedIn && user?.activated) {
    if (loggedIn) {
      // return <DefaultAppLayout>{component}</DefaultAppLayout>;
      if (authorized) {
        if (withLayout) {
          // return <DefaultAppLayout>{component}</DefaultAppLayout>;
          return component;
        }
        return component;
      } else if (
        accountTypesArr?.length > 0 &&
        accountTypesArr.includes(Number(user?.accountType))
      ) {
        if (withLayout) {
          // return <DefaultAppLayout>{component}</DefaultAppLayout>;
          return component;
        }
        return component;
      } else {
        return <Navigate to={sharedRouterLinks?.notFound} />;
      }
    }

    return (
      <Navigate to={redirectRoute ? redirectRoute : appRouterLinks.login} />
    );
  };

  return (
    <Route
      exact
      path={path}
      key={key}
      element={renderElement()}
      // element={
      //   loggedIn && user?.activated ? (
      //     accountTypesArr?.length > 0 &&
      //     accountTypesArr.includes(user?.accountType) ? (
      //       <DefaultAppLayout>{component}</DefaultAppLayout>
      //     ) : (
      //       <Navigate to={routerLinks?.notFound} />
      //     )
      //   ) : (
      //     <Navigate
      //       to={redirectRoute ? redirectRoute : routerLinks?.signinPage}
      //     />
      //   )
      // }
    >
      {Children}
    </Route>
    // <Route
    //   exact
    //   path={path}
    //   key={key}
    //   element={
    //     loggedIn && user?.activated ? (
    //       <DefaultAppLayout>{component}</DefaultAppLayout>
    //     ) : (
    //       <Navigate
    //         to={redirectRoute ? redirectRoute : routerLinks?.signinPage}
    //       />
    //     )
    //   }
    // />
  );
};

export default protectMe;
