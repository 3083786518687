import { Modal } from 'antd';
import { deliveryMethodsTypes } from '../../../mocks';
import FixedPriceForm from './fixed-price-form';
import MinKmForm from './min-km-form';
import './styles.scss';

const FormModal = ({
  modalOpened,
  setModalOpened,
  selectedType,
  setSelectedType
}) => {
  const renderModalForm = () => {
    if (selectedType?.type === deliveryMethodsTypes?.fixed)
      return <FixedPriceForm setModalOpened={setModalOpened} />;
    else if (selectedType?.type === deliveryMethodsTypes?.min)
      return <MinKmForm setModalOpened={setModalOpened} />;
    return null;
  };

  return (
    <Modal
      wrapClassName="calc-delivery-modal-wrap"
      className="shared-custom-modal calc-delivery-price-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={selectedType?.title}
      open={modalOpened}
      onOk={() => {
        setSelectedType(null);
        setModalOpened(false);
      }}
      onCancel={() => {
        setSelectedType(null);
        setModalOpened(false);
      }}
      footer={false}
    >
      <div className="custom-modal-content">{renderModalForm()}</div>
    </Modal>
  );
};

export default FormModal;
