import { createSlice } from '@reduxjs/toolkit';
import {
  getAllWalletTransactions,
  createWalletTransaction,
  getDriversBankAccounts
} from './sliceExtraReducers';

const initialState = {
  isLoadingWalletTransaction: false,
  fetchedWalletTransactionCount: 0,
  selectedWalletTransaction: null,
  fetchedWalletTransactions: [],
  isSubmittingWalletTransaction: false,
  isSubmittingTransactionForm: false,
  walletTransactionsPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////
  // drivers bank accounts
  isLoadingDriverBankAccounts: false,
  fetchedDriversBankAccounts: []
};

const walletTransactionsSlice = createSlice({
  name: 'walletTransactions',
  initialState,
  reducers: {
    setSelectedWalletTransactionAction: (sliceState, action) => {
      sliceState.selectedWalletTransaction = action.payload;
    }
  },
  extraReducers: (builder) => {
    getAllWalletTransactions({ builder });
    createWalletTransaction({ builder });
    getDriversBankAccounts({ builder });
  }
});

const walletTransactionsReducer = walletTransactionsSlice.reducer;

export const { setSelectedWalletTransactionAction } =
  walletTransactionsSlice.actions;

export default walletTransactionsReducer;
