import { UpdateIcon } from '@icons/index';
import './styles.scss';

const UpdateBtn = ({ btnText, handleClickUpdateBtn, props }) => {
  return (
    <div className="update-btn-wrapper" {...props}>
      <button onClick={handleClickUpdateBtn} className="update-btn">
        <UpdateIcon />
        {btnText}
      </button>
    </div>
  );
};

export default UpdateBtn;
