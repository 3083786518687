import { createSlice } from '@reduxjs/toolkit';
import {
  getNationalities,
  getSingleNationality,
  createNationality,
  updateNationality,
  changeNationalityStatus,
  changeSingleNationalityStatus,
  deleteNationality,
  deleteSingleNationality
} from './sliceExtraReducers';

const initialState = {
  // * all nationalities nationalities
  isLoadingNationalities: false,
  fetchNationalitiesCount: 0,
  fetchedNationalities: [],
  selectedNationality: null,
  selectedRowsIds: '',
  isSubmittingNationality: false,
  isSubmittingNationalityStatus: false,
  isSubmittingNationalityForm: false,
  nationalitiesPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // * single nationality nationality
  isLoadingSingleNationality: false,
  fetchSingleNationalityCount: 0,
  fetchedSingleNationality: null,
  isSubmittingSingleNationality: false,
  ///////////////////////
  //
  previewNationalityImgModalOpened: false,
  //
  deleteNationalityModalOpened: false,
  deleteMultiNationalityModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const nationalitiesSlice = createSlice({
  name: 'nationalities',
  initialState,
  reducers: {
    setSelectedNationalityAction: (sliceState, action) => {
      sliceState.selectedNationality = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteNationalityModalOpenedAction: (sliceState, action) => {
      sliceState.deleteNationalityModalOpened = action.payload;
    },
    setDeleteMultiNationalityModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiNationalityModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setPreviewNationalityImgModalOpened: (sliceState, action) => {
      sliceState.previewNationalityImgModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    getNationalities({ builder });
    getSingleNationality({ builder });
    createNationality({ builder });
    updateNationality({ builder });
    changeNationalityStatus({ builder });
    changeSingleNationalityStatus({ builder });
    deleteNationality({ builder });
    deleteSingleNationality({ builder });
  }
});

const nationalitiesReducer = nationalitiesSlice.reducer;

export const {
  setSelectedNationalityAction,
  setSelectedRowsIdsAction,
  setDeleteNationalityModalOpenedAction,
  setDeleteMultiNationalityModalOpenedAction,
  setConfirmModalAction,
  setPreviewNationalityImgModalOpened
} = nationalitiesSlice.actions;

export default nationalitiesReducer;
