import AntdCheckbox from '@antd/AntdCheckbox';
import AntdTextField from '@antd/AntdTextField';
import LoadingModal from '@components/shared/loading-modal';
import ForgetPasswordContext from '@context/forget-password-context/ForgetPasswordContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { EyeOffIcon, EyeOnIcon } from '@icons';
import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '@redux/user-slice/userSlice';
import { signinAction } from '@redux/user-slice/userSliceThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import ForgetPasswordModal from './ForgetPasswordModal';
import './LoginForm.scss';
import { accountTypes } from '../../mocks';
// import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';

const LoginForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const generalLabelStr = (key) => t(`loginForm.${key}.label`);
  const [passwrodVisible, setPasswordVisible] = React.useState(false);
  // const fcmDeviceToken = useFCMDeviceToken();
  const { forgetPasswordModalOpened } = useContext(ForgetPasswordContext);
  const schema = Yup.object().shape({
    // email: Yup.string()
    //   .required(t('loginForm.email.errors.required'))
    //   .email(t('loginForm.email.errors.type_error')),
    username: Yup.string().required('Enter username'),
    password: Yup.string().required(t('loginForm.password.errors.required'))
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      username: 'Admin',
      password: '123456789',
      remember: true
    }
  });
  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      const action = await dispatch(
        signinAction({
          dataToBeSubmitted: data
        })
      );
      const res = action?.payload?.res;
      if (res?.data?.data && res?.data?.status === 1) {
        if (data.remember) {
          dispatch(
            setCurrentUserAction({
              ...res?.data?.data,
              accountType:
                data?.accountType ||
                res.data.data.userType ||
                accountTypes()?.user,
              companyType: res.data.data?.companyType,
              activated: true,
              remember: data?.remember || false
            })
          );
          navigate(appRouterLinks?.adminHome);
        } else {
          dispatch(
            setUserToStateAction({
              ...res.data.data,
              accountType: data?.accountType || res.data.data.userType,
              companyType: res.data.data?.companyType,
              activated: true,
              remember: data?.remember || false
            })
          );
          navigate(appRouterLinks?.adminHome);

          // if (
          //   data?.accountType == accountTypes()?.user ||
          //   res?.data?.data?.type == accountTypes()?.user
          // ) {
          //   navigate(appRouterLinks?.homePage);
          // } else {
          //   navigate(merchentappRouterLinks?.merchentDashboard);
          // }
        }
      }
    } catch (error) {}
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signin-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('loginForm.title')}</p>
          <p className="sub-title">{t('loginForm.sub_title')}</p>
        </div>

        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="username"
              type="text"
              errorMsg={errors?.username?.message}
              validateStatus={errors?.username ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{generalLabelStr('username')}</p>
          </div>

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="password"
                type={passwrodVisible ? 'text' : 'password'}
                errorMsg={errors?.password?.message}
                validateStatus={errors?.password ? 'error' : ''}
                control={control}
                setValue={setValue}
              />
              <p className="custom-label">{generalLabelStr('password')}</p>
            </div>

            {watch('password') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>
          <RouterLink
            className="forget-p"
            // onClick={() => setForgetPasswordModalOpened(true)}
            to={appRouterLinks?.forgetPasswordRoute}
          >
            {t('loginForm.forget_pass')}
          </RouterLink>
          <AntdCheckbox
            name="remember"
            control={control}
            label={t('loginForm.rememberMe')}
            errorMsg={errors?.remember?.message}
            validateStatus={errors?.remember ? 'error' : ''}
          />
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {isSubmitting
              ? t('loginForm.submit_btn.loading')
              : t('loginForm.submit_btn.label')}
          </Button>
        </div>
      </Form>

      {forgetPasswordModalOpened && <ForgetPasswordModal />}
      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default LoginForm;
