import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allCarsBrandsAction,
  singleCarsBrandsAction,
  changeCarsBrandsStatusAction,
  changeSingleCarBrandStatusAction,
  deleteCarBrandsAction,
  deleteSingleCarBrandAction,
  createCarBrandAction,
  updateCarBrandAction
} from './carsBrandsSliceThunkApis';

export function getCarsBrands({ builder }) {
  const { pending, fulfilled, rejected } = allCarsBrandsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingCarBrands = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingCarBrands = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedCarsBrands = data?.data;
        if (data?.pagination) sliceState.carBrandsPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingCarBrands = false;
    });
}
export function getSingleCarBrand({ builder }) {
  const { pending, fulfilled, rejected } = singleCarsBrandsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleCarBrand = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleCarBrand = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleCarBrand = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleCarBrand = false;
    });
}
export function createCarBrand({ builder }) {
  const { pending, fulfilled, rejected } = createCarBrandAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarBrandForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarBrandForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchCarBrandsCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarBrandForm = false;
    });
}
export function updateCarBrand({ builder }) {
  const { pending, fulfilled, rejected } = updateCarBrandAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarBrandForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarBrandForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleCarBrandCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarBrandForm = false;
    });
}
export function changeCarBrandStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeCarsBrandsStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarBrandStatus = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarBrandStatus = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchCarBrandsCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarBrandStatus = false;
    });
}
export function changeSingleCarBrandStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleCarBrandStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCarBrand = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCarBrand = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleCarBrandCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCarBrand = false;
    });
}
export function deleteCarBrand({ builder }) {
  const { pending, fulfilled, rejected } = deleteCarBrandsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarBrand = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarBrand = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCarBrandModalOpened = false;
        sliceState.deleteMultiBrandModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.fetchCarBrandsCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarBrand = false;
    });
}
export function deleteSingleCarBrand({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleCarBrandAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCarBrand = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCarBrand = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCarBrandModalOpened = false;
        // sliceState.fetchSingleCarBrandCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCarBrand = false;
    });
}
