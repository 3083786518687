/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { allCarsBrandsAction } from '@redux/cars-brands-slice/carsBrandsSliceThunkApis';
import debounce from 'lodash.debounce';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';

const FormBrandSelectOption = ({ label, control, setValue, errors }) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const fetchedCarsBrands = useSelector(
    (store) => store.carBrands.fetchedCarsBrands
  );
  const fetchCarBrandsCount = useSelector(
    (store) => store.carBrands.fetchCarBrandsCount
  );
  const carBrandsPagination = useSelector(
    (store) => store.carBrands.carBrandsPagination
  );
  const isLoadingCarBrands = useSelector(
    (store) => store.carBrands.isLoadingCarBrands
  );

  const [selectOptionArr, setSelectOptionArr] = useState([]);
  useEffect(() => {
    if (fetchedCarsBrands?.length === 0 || !fetchedCarsBrands) {
      setSelectOptionArr((prev) => {
        return [...prev];
      });
    } else if (
      fetchedCarsBrands?.length > 0 &&
      carBrandsPagination?.current_page > 1
    ) {
      setSelectOptionArr((prev) => {
        return [...prev, ...fetchedCarsBrands];
      });
    } else setSelectOptionArr([...fetchedCarsBrands]);
  }, [fetchedCarsBrands]);

  // get all car brands for form select option
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allCarsBrandsAction({
          filterObj: {
            // ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCarBrandsCount, search, i18n.language]);

  const handleClickMore = () => {
    if (carBrandsPagination.current_page < carBrandsPagination.total_pages)
      dispatch(
        allCarsBrandsAction({
          filterObj: {
            page: carBrandsPagination.current_page + 1
          }
        })
      );
  };

  const [searchObj, setSearchObj] = useState({
    name: '',
    page: 1
  });
  const [query, setQuery] = useState('');

  const changeHandler = (t) => {
    setQuery(t);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);

  useEffect(() => {
    let isMounted = true;
    if (query && query.trim() && isMounted) {
      setSearchObj({
        name: query,
        page: 1
      });
    }

    return () => (isMounted = false);
  }, [query]);

  useEffect(() => {
    if (searchObj?.name) {
      dispatch(
        allCarsBrandsAction({
          filterObj: {
            searchText: searchObj?.name || '',
            page: searchObj?.page || 1
          }
        })
      );
    }
  }, [searchObj?.name, searchObj?.page]);

  return (
    <div className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}>
      <AntdSelectOption
        withPagination={
          carBrandsPagination?.current_page < carBrandsPagination?.total_pages
        }
        handleClickMore={handleClickMore}
        loadingMore={isLoadingCarBrands}
        className="custom-select-field"
        name="brand_id"
        errorMsg={errors?.brand_id && errors.brand_id.message}
        validateStatus={errors?.brand_id ? 'error' : ''}
        control={control}
        setValue={setValue}
        options={
          selectOptionArr?.length > 0
            ? selectOptionArr.map((item) => ({
                title: item?.name || '---',
                value: item?.id ? String(item.id) : ''
              }))
            : []
        }
        showSearch={true}
        // onSearch={onSearch}
        onSearch={(t) => {
          debouncedChangeHandler(t);
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <p className="custom-label">{label}</p>
    </div>
  );
};

export default FormBrandSelectOption;
