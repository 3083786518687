/* eslint-disable react-hooks/exhaustive-deps */
import DeleteAllBtn from '@components/delete-all-btn';
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import {
  setDeleteMultiRegionModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/regions-slice/regionsSlice';
import appRouterLinks from '@routes/appRouterLinks';
import { Button } from 'antd';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RegionsTable from './regions-table';
import TableFilter from './regions-table/table-filter';

const RegionsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedRowsIds = useSelector((store) => store.regions.selectedRowsIds);

  return (
    <div className="regions-page">
      <PageHeader
        title={t('regions')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('regions')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.newRegion);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addRegion')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiRegionModalOpenedAction(true))
            }
          />
          <TableFilter />
        </div>
      </div>
      <RegionsTable />
    </div>
  );
};

export default RegionsPage;
