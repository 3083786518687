// import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { checkIfNumberWithNoDecimalPoint } from '@helpers/checkValidNumber';

const sharedSchema = (t) => {
  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `countryForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  return {
    name_en: Yup.string().required(
      sharedFormErr({
        key: 'name_en'
      })
    ),
    name_ar: Yup.string().required(
      sharedFormErr({
        key: 'name_ar'
      })
    ),
    phoneKey: Yup.string()
      .required(
        sharedFormErr({
          key: 'phoneKey'
        })
      )
      // * check if only numbers starting from index 1
      .test(
        'phoneKey',
        sharedFormErr({
          key: 'phoneKey',
          typeObj: {
            type: 'wrongFormat'
          }
        }),
        (v, context) => {
          let result = true;
          if (
            v &&
            v.length > 1 &&
            !checkIfNumberWithNoDecimalPoint(v.substring(1))
          )
            result = false;

          return result;
        }
      )
      // check if the first char is + or number
      .test(
        'phoneKey',
        sharedFormErr({
          key: 'phoneKey',
          typeObj: {
            type: 'wrongFormat'
          }
        }),
        (v, context) => {
          let result = true;
          if (v && v[0] !== '+' && !checkIfNumberWithNoDecimalPoint(v[0])) {
            result = false;
          }

          return result;
        }
      ),
    //////////////////////////
    phoneNumbers: Yup.string()
      .required(
        sharedFormErr({
          key: 'phoneNumbers'
        })
      )
      .test(
        'phoneNumbers',
        sharedFormErr({
          key: 'phoneNumbers',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || !checkIfNumberWithNoDecimalPoint(v)) result = false;

          return result;
        }
      ),
    status: Yup.string().required(
      sharedFormErr({
        key: 'status'
      })
    )
  };
};

const getFormSchema = ({ fetchedObj, t }) => {
  if (fetchedObj) {
    return Yup.object().shape({
      ...sharedSchema(t)
    });
  }
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

export default getFormSchema;
