import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allDriversAction,
  singleDriverAction,
  changeDriverStatusAction,
  changeSingleDriverStatusAction,
  deleteDriverAction,
  deleteSingleDriverAction
} from './driversSliceThunkApi';

export function getDrivers({ builder }) {
  const { pending, fulfilled, rejected } = allDriversAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingDrivers = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingDrivers = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedDrivers = data?.data;
        if (data?.pagination) sliceState.driversPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingDrivers = false;
    });
}
export function getSingleDriver({ builder }) {
  const { pending, fulfilled, rejected } = singleDriverAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleDriver = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleDriver = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleDriver = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleDriver = false;
    });
}
export function changeDriverStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeDriverStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingDriver = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingDriver = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchDriverCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingDriver = false;
    });
}
export function changeSingleDriverStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleDriverStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleDriver = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleDriver = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleDriverCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleDriver = false;
    });
}
export function deleteDriver({ builder }) {
  const { pending, fulfilled, rejected } = deleteDriverAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingDriver = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingDriver = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteDriverModalOpened = false;
        sliceState.deleteMultiDriverModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.fetchDriverCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingDriver = false;
    });
}
export function deleteSingleDriver({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleDriverAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleDriver = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleDriver = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteDriverModalOpened = false;
        // sliceState.fetchSingleDriverCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleDriver = false;
    });
}
