import {
  DashboardIcon,
  PlusCircled,
  ShoppingCartIcon,
  StatisticsIcon,
  DriverIcon,
  VehicleIcon,
  CarIcon,
  PagesIcon,
  CalendarIcon,
  CountryIcon,
  CityIcon,
  AreaIcon,
  MoneyIcon,
  PlusIcon,
  WalletIcon,
  FileTransfer,
  SettingsIcon
} from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';

const appMenuLinks = ({ i18n, t, user, appMenuCollapsed }) => {
  return [
    {
      key: 'dashboard_key',
      label: appMenuCollapsed ? '' : t('dashboard'),
      icon: <DashboardIcon />,
      type: '',
      url: appRouterLinks.adminHome,
      children: null
    },
    {
      key: 'parent_1',
      label: appMenuCollapsed ? '' : t('orders'),
      icon: appMenuCollapsed ? <ShoppingCartIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.ordersStats,
          label: t('ordersStats'),
          icon: <StatisticsIcon />,
          type: '',
          url: appRouterLinks.ordersStats,
          children: null
        },
        {
          key: appRouterLinks.orders,
          label: t('orders'),
          icon: <ShoppingCartIcon />,
          type: '',
          url: appRouterLinks.orders,
          children: null
        },
        {
          key: appRouterLinks.newOrder,
          label: t('newOrder'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newOrder,
          children: null
        }
      ]
    },
    {
      type: 'divider'
    },
    {
      key: 'parent_2',
      label: appMenuCollapsed ? '' : t('drivers'),
      icon: appMenuCollapsed ? <DriverIcon /> : null,
      type: '',
      url: '',
      children: [
        // {
        //   key: appRouterLinks.driversStats,
        //   label: t('driversStats'),
        //   icon: <StatisticsIcon />,
        //   type: '',
        //   url: appRouterLinks.driversStats,
        //   children: null
        // },
        {
          key: appRouterLinks.drivers,
          label: t('drivers'),
          icon: <VehicleIcon />,
          type: '',
          url: appRouterLinks.drivers,
          children: null
        },
        {
          key: appRouterLinks.deliveryCalcMethods,
          label: t('deliveryCalcMethods'),
          icon: <MoneyIcon />,
          type: '',
          url: appRouterLinks.deliveryCalcMethods,
          children: null
        },
        {
          type: 'divider'
        },
        {
          key: appRouterLinks.withdrawalsRoute,
          label: t('withdrawals'),
          icon: <FileTransfer />,
          type: '',
          url: appRouterLinks.withdrawalsRoute,
          children: null
        },
        {
          type: 'divider'
        },
        {
          key: appRouterLinks.walletSettingsRoute,
          label: t('walletSettings'),
          icon: <SettingsIcon />,
          type: '',
          url: appRouterLinks.walletSettingsRoute,
          children: null
        },
        {
          key: appRouterLinks.walletTransactionsRoute,
          label: t('walletTransactions'),
          icon: <WalletIcon />,
          type: '',
          url: appRouterLinks.walletTransactionsRoute,
          children: null
        },
        {
          key: appRouterLinks.createWalletTransaction,
          label: t('createWalletTransaction'),
          icon: <PlusIcon />,
          type: '',
          url: appRouterLinks.createWalletTransaction,
          children: null
        }
        // {
        //   key: 'add_driver',
        //   label: t('addDriver'),
        //   icon: <PlusCircled />,
        //   type: '',
        //   url: appRouterLinks.newDriver,
        //   children: null
        // }
      ]
    },
    {
      type: 'divider'
    },
    {
      key: 'parent_3',
      label: appMenuCollapsed ? '' : t('vehicleBrands'),
      icon: appMenuCollapsed ? <VehicleIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.vehiclesBrands,
          label: t('vehicleBrands'),
          icon: <CarIcon />,
          type: '',
          url: appRouterLinks.vehiclesBrands
        },
        {
          key: appRouterLinks.newVehicleBrand,
          label: t('addBrand'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newVehicleBrand,
          children: null
        }
      ]
    },
    {
      key: 'parent_4',
      label: appMenuCollapsed ? '' : t('vehicleModels'),
      icon: appMenuCollapsed ? <VehicleIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.vehiclesModels,
          label: t('vehicleModels'),
          icon: <CarIcon />,
          type: '',
          url: appRouterLinks.vehiclesModels
        },
        {
          key: appRouterLinks.newVehicleModel,
          label: t('addModel'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newVehicleModel,
          children: null
        }
      ]
    },
    {
      key: 'parent_5',
      label: appMenuCollapsed ? '' : t('vehicleYears'),
      icon: appMenuCollapsed ? <CalendarIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.vehiclesYears,
          label: t('vehicleYears'),
          icon: <CalendarIcon />,
          type: '',
          url: appRouterLinks.vehiclesYears
        },
        {
          key: appRouterLinks.newVehicleYear,
          label: t('addYear'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newVehicleYear,
          children: null
        }
      ]
    },
    {
      type: 'divider'
    },
    {
      key: 'parent_6',
      label: appMenuCollapsed ? '' : t('countries'),
      icon: appMenuCollapsed ? <CountryIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.countries,
          label: t('countries'),
          icon: <CountryIcon />,
          type: '',
          url: appRouterLinks.countries
        },
        {
          key: appRouterLinks.newCountry,
          label: t('addCountry'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newCountry,
          children: null
        }
      ]
    },
    {
      key: 'parent_8',
      label: appMenuCollapsed ? '' : t('regions'),
      icon: appMenuCollapsed ? <AreaIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.regions,
          label: t('regions'),
          icon: <AreaIcon />,
          type: '',
          url: appRouterLinks.regions
        },
        {
          key: appRouterLinks.newRegion,
          label: t('addRegion'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newRegion,
          children: null
        }
      ]
    },
    {
      key: 'parent_7',
      label: appMenuCollapsed ? '' : t('cities'),
      icon: appMenuCollapsed ? <CityIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.cities,
          label: t('cities'),
          icon: <CityIcon />,
          type: '',
          url: appRouterLinks.cities
        },
        {
          key: appRouterLinks.newCity,
          label: t('addCity'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newCity,
          children: null
        }
      ]
    },
    {
      key: 'parent_9',
      label: appMenuCollapsed ? '' : t('nationalities'),
      icon: appMenuCollapsed ? <AreaIcon /> : null,
      type: '',
      url: '',
      children: [
        {
          key: appRouterLinks.nationalities,
          label: t('nationalities'),
          icon: <AreaIcon />,
          type: '',
          url: appRouterLinks.nationalities
        },
        {
          key: appRouterLinks.newNationality,
          label: t('addNationality'),
          icon: <PlusCircled />,
          type: '',
          url: appRouterLinks.newNationality,
          children: null
        }
      ]
    }
  ];
};

export default appMenuLinks;
