import React from 'react';
import { useNavigate } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';
import { useDispatch, useSelector } from 'react-redux';
import { userSignoutAction } from '../redux/user-slice/userSliceThunkApis';
import { removeCurrentUserAction } from '../redux/user-slice/userSlice';

const useSignout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const signMeOut = () => {
    dispatch(userSignoutAction()).then((obj) => {
      dispatch(removeCurrentUserAction());
      navigate(appRouterLinks?.login);
    });
  };

  return { signMeOut, isSubmitting };
};

export default useSignout;
