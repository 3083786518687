/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { singleCarsBrandsAction } from '@redux/cars-brands-slice/carsBrandsSliceThunkApis';
import { useTranslation } from 'react-i18next';

const useSingleCarBrand = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const fetchSingleCarBrandCount = useSelector(
    (store) => store.carBrands.fetchSingleCarBrandCount
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && params?.brand_id) {
      dispatch(
        singleCarsBrandsAction({
          filterObj: {
            brand_id: params?.brand_id || '',
            ...values
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSingleCarBrandCount, search, i18n.language]);
};

export default useSingleCarBrand;
