import axios from 'axios';
import apisUrls from '../apisUrls';

export const loginApi = (values) => {
  return axios.post(apisUrls.loginUrl, values);
};

export const logoutApi = (values) => {
  return axios.post(apisUrls.signOutUrl, values);
};

export const myInfoApi = () => {
  return axios.get(apisUrls.myInfoUrl);
};

export const updateProfileApi = (values) => {
  return axios.post(apisUrls.editProfileUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// FORGET PASSWORD
export const forgetPasswordEnterEmailApi = (values) => {
  return axios.post(apisUrls.forgetPasswordUrl, values);
};

export const forgetPasswordEnterCodeApi = (token, values) => {
  return axios.post(apisUrls.checkPasswordCodeUrl, values, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const forgetPasswordResendCodeApi = (token, values) => {
  return axios.post(apisUrls.resendCode, values, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const forgetPasswordResetPasswordApi = (token, values) => {
  return axios.post(apisUrls.resetPasswordUrl, values, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

//
export const resendCodeApi = (values) => {
  return axios.post(apisUrls.resendCode, values);
};

// CHANGE PASSWORD
export const changePasswordApi = (values) => {
  return axios.post(apisUrls.changePasswordUrl, values);
};
