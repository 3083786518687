import { createSlice } from '@reduxjs/toolkit';
import {
  getCarsBrands,
  getSingleCarBrand,
  createCarBrand,
  updateCarBrand,
  changeCarBrandStatus,
  changeSingleCarBrandStatus,
  deleteCarBrand,
  deleteSingleCarBrand
} from './sliceExtraReducers';

const initialState = {
  // all cars brands
  isLoadingCarBrands: false,
  fetchCarBrandsCount: 0,
  fetchedCarsBrands: [],
  selectedCarBrand: null,
  isSubmittingCarBrand: false,
  isSubmittingCarBrandStatus: false,
  isSubmittingCarBrandForm: false,
  selectedRowsIds: '',
  carBrandsPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // single car brand
  isLoadingSingleCarBrand: false,
  fetchSingleCarBrandCount: 0,
  fetchedSingleCarBrand: null,
  isSubmittingSingleCarBrand: false,
  ///////////////////////
  //
  previewCarBrandImgModalOpened: false,
  //
  deleteCarBrandModalOpened: false,
  deleteMultiBrandModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const carsBrandsSlice = createSlice({
  name: 'carsBrands',
  initialState,
  reducers: {
    setSelectedCarBrandAction: (sliceState, action) => {
      sliceState.selectedCarBrand = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteCarBrandModalOpenedAction: (sliceState, action) => {
      sliceState.deleteCarBrandModalOpened = action.payload;
    },
    setDeleteMultiCarBrandModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiBrandModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setPreviewBrandImgModalOpened: (sliceState, action) => {
      sliceState.previewCarBrandImgModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    getCarsBrands({ builder });
    getSingleCarBrand({ builder });
    createCarBrand({ builder });
    updateCarBrand({ builder });
    changeCarBrandStatus({ builder });
    changeSingleCarBrandStatus({ builder });
    deleteCarBrand({ builder });
    deleteSingleCarBrand({ builder });
  }
});

const carBrandsReducer = carsBrandsSlice.reducer;

export const {
  setSelectedCarBrandAction,
  setSelectedRowsIdsAction,
  setDeleteCarBrandModalOpenedAction,
  setDeleteMultiCarBrandModalOpenedAction,
  setConfirmModalAction,
  setPreviewBrandImgModalOpened
} = carsBrandsSlice.actions;

export default carBrandsReducer;
