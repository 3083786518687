import axios from 'axios';
import apisUrls from './apisUrls';

export const allNationalitiesApi = async (filterObj) => {
  return axios.get(apisUrls.nationalities(filterObj));
};

export const singleNationalityApi = async (filterObj) => {
  return axios.get(apisUrls.singleNationality(filterObj));
};

export const createNationalityApi = async (values) => {
  return axios.post(apisUrls.createNationality, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateNationalityApi = async (values) => {
  return axios.post(apisUrls.updateNationality, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteNationalityApi = async (values) => {
  return axios.post(apisUrls.deleteNationality, values);
};

export const changeNationalityStatusApi = async (values) => {
  return axios.post(apisUrls.changeNationalityStatus, values);
};
