import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Logo from "@components/shared/logo/Logo";
import ForgetPasswordContext from "@context/forget-password-context/ForgetPasswordContext";
// import { Link as RouterLink } from 'react-router-dom';
import successCheckImg from "@assets/imgs/success-check.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import appRouterLinks from "@routes/appRouterLinks";
import {
  setCurrentUserAction,
  setUserToStateAction,
} from "@redux/user-slice/userSlice";

const ForgetPasswordChangedSuccessfullyPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    setUser,
    user,
    // successLock,
  } = useContext(ForgetPasswordContext);

  const navigate = useNavigate();
  const LockComp = () => {
    return (
      <div className="lock-img-wrap">
        <img src={successCheckImg} alt="success" />
        <p>{t("forgetPasswordPage.successLock")}</p>
        {/* <RouterLink className="signin-link" to={routerLinks?.signinPage}>
          {t('forgetPasswordPage.login')}
        </RouterLink> */}

        <button
          className="signin-link"
          onClick={() => {
            // check if the user is activated or not
            if (user?.status === 0) {
              dispatch(
                setUserToStateAction({
                  ...user,
                  accountType: user?.userType,
                  activated: false,
                  remember: false,
                })
              );
              // remove foreget password user from localstorage
              setUser(null);
              navigate(appRouterLinks?.activateAccountRoute);
            } else if (user?.status === 1) {
              dispatch(
                setCurrentUserAction({
                  ...user,
                  accountType: user?.userType,
                  activated: true,
                  remember: true,
                })
              );
              // remove foreget password user from localstorage
              setUser(null);
              navigate(appRouterLinks?.home);
            }
          }}
        >
          {t("forgetPasswordPage.login")}
        </button>
      </div>
    );
  };

  return (
    <div className="forget-password-page">
      <div className="custom-container">
        <div className="page-content-wrap">
          <Logo className="app-logo" />
          <div className="page-form-wrap">
            <LockComp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordChangedSuccessfullyPage;
