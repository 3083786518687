import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import useSingleCarModel from '@customHooks/useSingleCarModel';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CarModelForm from './car-model-form';

const NewCarModelPage = () => {
  const params = useParams();
  const isLoadingSingleCarModel = useSelector(
    (store) => store.carModels.isLoadingSingleCarModel
  );
  const isSubmittingCarModelForm = useSelector(
    (store) => store.carModels.isSubmittingCarModelForm
  );
  const fetchedSingleCarModel = useSelector(
    (store) => store.carModels.fetchedSingleCarModel
  );

  // get single car model
  useSingleCarModel();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleCarModel && params?.model_id
            ? `${t('update')} ${fetchedSingleCarModel?.name}` ||
              `${
                fetchedSingleCarModel?.id ? `#${fetchedSingleCarModel.id}` : ''
              }` ||
              ' '
            : t('newCarModel')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.vehiclesModels}>
                {t('vehicleModels')}
              </Link>
            )
          },
          {
            title:
              fetchedSingleCarModel && params?.model_id
                ? fetchedSingleCarModel?.name ||
                  `${
                    fetchedSingleCarModel?.id
                      ? `#${fetchedSingleCarModel.id}`
                      : ''
                  }` ||
                  ' '
                : t('newCarModel')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-car-model-page">
        <SharedPageHeader />

        <CarModelForm
          fetchedObj={params?.model_id ? fetchedSingleCarModel : null}
          isSubmittingForm={isSubmittingCarModelForm}
        />
      </div>

      {(isSubmittingCarModelForm || isLoadingSingleCarModel) && (
        <LoadingModal />
      )}
    </PageWrapperMotion>
  );
};

export default NewCarModelPage;
