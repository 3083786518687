/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { FileTransfer } from '@icons/index';
import { t } from 'i18next';
import WithdrawalsTable from './withdrawals-table';
import TableFilter from './withdrawals-table/table-filter';

const WithdrawalsPage = () => {
  return (
    <div className="withdrawals-page">
      <PageHeader
        title={t('withdrawals')}
        icon={<FileTransfer />}
        breadcrumbItems={[
          {
            title: t('withdrawals')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <div className="table-filter-delete-all-btn">
          <TableFilter />
        </div>
      </div>
      <WithdrawalsTable />
    </div>
  );
};

export default WithdrawalsPage;
