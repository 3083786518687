import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appMenuCollapsed: false,
  appInFullScreen: false
};

const generalAppSlice = createSlice({
  name: 'generalAppSlice',
  initialState,
  reducers: {
    setAppMenuCollapsed: (sliceState, action) => {
      sliceState.appMenuCollapsed = action.payload;
    },
    setAppInFullScreen: (sliceState, action) => {
      if (action.payload) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
      sliceState.appInFullScreen = action.payload;
    }
  }
});

const generalAppReducer = generalAppSlice.reducer;

export const { setAppMenuCollapsed, setAppInFullScreen } =
  generalAppSlice.actions;

export default generalAppReducer;
