import DeleteBtn from '@components/shared/delete-btn';
import StatusSelectOption from '@components/shared/status-select-option';
import { orderStatusArr, orderStatusValues } from '@mocks/index';
import {
  setAssignDriverModalOpenedAction,
  setDeleteOrderModalOpenedAction
} from '@redux/orders-slice/ordersSlice';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PlusIcon } from '@components/icons';
import './styles.scss';

const OrderActions = ({
  status,
  selectedStatus,
  handleChangeSelectOption,
  orderId
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="order-actions-wrapper">
      <div className="order-id">
        <span>{t('orderId')} : </span>
        <span>
          {'#'} {orderId || '---'}
        </span>
      </div>

      <div className="actions">
        <StatusSelectOption
          statusArr={orderStatusArr(t)}
          fetchedDefaultStatus={status}
          selectedStatus={selectedStatus}
          handleChangeSelectOption={handleChangeSelectOption}
          width={i18n.language === 'en' ? 170 : 152}
        />

        {status === orderStatusValues?.new ? (
          <button
            onClick={() => {
              dispatch(setAssignDriverModalOpenedAction(true));
            }}
            className="assign-driver-btn"
            type="button"
          >
            <PlusIcon color="#4da24d" />
            {t('assignDriver')}
          </button>
        ) : null}

        <DeleteBtn
          btnText={t('deleteOrder')}
          handleClickDeleteBtn={() => {
            dispatch(setDeleteOrderModalOpenedAction(true));
          }}
        />
      </div>
    </div>
  );
};

export default OrderActions;
