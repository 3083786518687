import axios from 'axios';
import apisUrls from './apisUrls';

export const allRegionsApi = async (filterObj) => {
  return axios.get(apisUrls.regions(filterObj));
};

export const singleRegionApi = async (filterObj) => {
  return axios.get(apisUrls.singleRegion(filterObj));
};

export const createRegionApi = async (values) => {
  return axios.post(apisUrls.createRegion, values);
};

export const updateRegionApi = async (values) => {
  return axios.post(apisUrls.updateRegion, values);
};

export const deleteRegionApi = async (values) => {
  return axios.post(apisUrls.deleteRegion, values);
};

export const changeRegionStatusApi = async (values) => {
  return axios.post(apisUrls.changeRegionStatus, values);
};
