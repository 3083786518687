const sharedAuthRouterLinks = {
  profile: '/profile',
  // ORDERS
  orders: `/orders`,
  ordersStats: `/orders/statistics`,
  newOrder: '/orders/new',
  editOrder: (order_id) =>
    order_id ? `/orders/edit/${order_id}` : '/orders/edit/:order_id',
  orderDetails: (order_id) =>
    order_id ? `/orders/details/${order_id}` : '/orders/details/:order_id',
  // DRIVERS
  drivers: `/drivers`,
  driversStats: `/drivers/statistics`,
  newDriver: '/drivers/new',
  editDriver: (driver_id) =>
    driver_id ? `/drivers/edit/${driver_id}` : '/drivers/edit/:driver_id',
  driverDetails: (driver_id) =>
    driver_id ? `/drivers/details/${driver_id}` : '/drivers/details/:driver_id',
  deliveryCalcMethods: '/delivery-calculation-methods',
  // range method links
  rangeMethodRoute: '/delivery-calculation-methods/range-method',
  createRangeMethodRout: '/delivery-calculation-methods/range-method/create',
  updateRangeMethodRout: (price_id) =>
    price_id
      ? `/delivery-calculation-methods/range-method/update/${price_id}`
      : '/delivery-calculation-methods/range-method/update/:price_id',
  // withdrawal
  withdrawalsRoute: '/withdrawals',
  // wallet transactions
  walletSettingsRoute: '/wallet-settings',
  walletTransactionsRoute: '/wallet-transactions',
  createWalletTransaction: '/wallet-transactions/create',
  ////////////////////////
  // COUNTRIES
  countries: `/countries`,
  newCountry: '/countries/new',
  editCountry: (country_id) =>
    country_id
      ? `/countries/edit/${country_id}`
      : '/countries/edit/:country_id',
  countryDetails: (country_id) =>
    country_id
      ? `/countries/details/${country_id}`
      : '/countries/details/:country_id',
  // CITIES
  cities: `/cities`,
  newCity: '/cities/new',
  editCity: (city_id) =>
    city_id ? `/cities/edit/${city_id}` : '/cities/edit/:city_id',
  cityDetails: (city_id) =>
    city_id ? `/cities/details/${city_id}` : '/cities/details/:city_id',
  // REGIONS
  regions: `/regions`,
  newRegion: '/regions/new',
  editRegion: (region_id) =>
    region_id ? `/regions/edit/${region_id}` : '/regions/edit/:region_id',
  regionDetails: (region_id) =>
    region_id ? `/regions/details/${region_id}` : '/regions/details/:region_id',
  // NATIONALITIES
  nationalities: `/nationalities`,
  newNationality: '/nationalities/new',
  editNationality: (nationality_id) =>
    nationality_id
      ? `/nationalities/edit/${nationality_id}`
      : '/nationalities/edit/:nationality_id',
  nationalityDetails: (nationality_id) =>
    nationality_id
      ? `/nationalities/details/${nationality_id}`
      : '/nationalities/details/:nationality_id',
  // VEHICLES Brands
  vehiclesBrands: `/vehicles-brands`,
  newVehicleBrand: '/vehicles-brands/new',
  editVehicleBrand: (brand_id) =>
    brand_id
      ? `/vehicles-brands/edit/${brand_id}`
      : '/vehicles-brands/edit/:brand_id',
  vehicleBrandDetails: (brand_id) =>
    brand_id
      ? `/vehicles-brands/details/${brand_id}`
      : '/vehicles-brands/details/:brand_id',
  // * VEHICLES Models
  vehiclesModels: `/vehicles-models`,
  newVehicleModel: '/vehicles-models/new',
  editVehicleModel: (model_id) =>
    model_id
      ? `/vehicles-models/edit/${model_id}`
      : '/vehicles-models/edit/:model_id',
  vehicleModelDetails: (model_id) =>
    model_id
      ? `/vehicles-models/details/${model_id}`
      : '/vehicles-models/details/:model_id',
  // * VEHICLES YEARS
  vehiclesYears: `/vehicles-years`,
  newVehicleYear: '/vehicles-years/new',
  editVehicleYear: (year_id) =>
    year_id
      ? `/vehicles-years/edit/${year_id}`
      : '/vehicles-years/edit/:year_id',
  vehicleYearDetails: (year_id) =>
    year_id
      ? `/vehicles-years/details/${year_id}`
      : '/vehicles-years/details/:year_id'
};

export default sharedAuthRouterLinks;
