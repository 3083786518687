import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allCountriesAction,
  singleCountryAction,
  changeCountryStatusAction,
  changeSingleCountryStatusAction,
  deleteCountryAction,
  deleteSingleCountryAction,
  createCountryAction,
  updateCountryAction
} from './countriesSliceThunkApis';

export function getCountries({ builder }) {
  const { pending, fulfilled, rejected } = allCountriesAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingCountries = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingCountries = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedCountries = data?.data;
        if (data?.pagination) sliceState.countriesPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingCountries = false;
    });
}
export function getSingleCountry({ builder }) {
  const { pending, fulfilled, rejected } = singleCountryAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleCountry = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleCountry = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleCountry = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleCountry = false;
    });
}

export function createCountry({ builder }) {
  const { pending, fulfilled, rejected } = createCountryAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCountryForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCountryForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchCountriesCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCountryForm = false;
    });
}

export function updateCountry({ builder }) {
  const { pending, fulfilled, rejected } = updateCountryAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCountryForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCountryForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleCountryCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCountryForm = false;
    });
}
export function changeCountryStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeCountryStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCountryStatus = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCountryStatus = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchCountriesCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCountryStatus = false;
    });
}
export function changeSingleCountryStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleCountryStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCountry = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCountry = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleCountryCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCountry = false;
    });
}
export function deleteCountry({ builder }) {
  const { pending, fulfilled, rejected } = deleteCountryAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCountry = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCountry = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCountryModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.deleteMultiCountryModalOpened = false;
        sliceState.fetchCountriesCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCountry = false;
    });
}
export function deleteSingleCountry({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleCountryAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleCountry = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleCountry = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCountryModalOpened = false;
        // sliceState.fetchSingleCountryCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleCountry = false;
    });
}
