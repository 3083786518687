const calcSingleObjPrice = ({ unitPrice, qty }) => {
  return parseFloat(unitPrice) * parseFloat(qty);
};

const sumPricesArr = ({ pricesArr }) => {
  return pricesArr.reduce(
    (acc = 0, curr = 0) => parseFloat(acc) + parseFloat(curr),
    0
  );
};

//////////////////

export const calcSingleProductPrice = ({ productObj }) => {
  //* calc product net price
  const netPrice = calcSingleObjPrice({
    unitPrice: productObj?.unitPrice || 0,
    qty: productObj?.quantity || 1
  });

  // * calc addons price
  const netAddonsPrice =
    productObj?.addon?.length > 0
      ? sumPricesArr({
          pricesArr: productObj?.addon.map((addon) =>
            calcSingleObjPrice({ unitPrice: addon?.price || 0, qty: 1 })
          )
        })
      : 0;

  // * calc sizes price
  const netSizesPrice =
    productObj?.sizes?.length > 0
      ? sumPricesArr({
          pricesArr: productObj?.sizes.map((size) =>
            calcSingleObjPrice({ unitPrice: size?.price || 0, qty: 1 })
          )
        })
      : 0;

  ///////////
  return (
    parseFloat(netPrice) +
    parseFloat(netAddonsPrice) +
    parseFloat(netSizesPrice)
  );
};

export const calcProductsTotalPrice = ({ productsArr }) => {
  // * calc net price
  const netProductsPrice =
    productsArr?.length > 0
      ? sumPricesArr({
          pricesArr: productsArr.map((p) =>
            calcSingleProductPrice({ productObj: p })
          )
        })
      : 0;

  return netProductsPrice;
};

export const calcOrderTotalPrice = ({
  productsTotalPrice,
  productTax,
  deliveryPrice,
  deliveryTax
}) => {
  const pTax = productTax || 0;
  const dTax = deliveryTax || 0;
  const dPrice = deliveryPrice || 0;
  //* get products tax price
  const productsTaxPrice =
    (parseFloat(productsTotalPrice) * parseFloat(pTax)) / 100;
  //* get delivery tax price
  const deliveryTaxPrice = (parseFloat(dPrice) * parseFloat(dTax)) / 100;
  ///////

  const total =
    parseFloat(productsTotalPrice) +
    productsTaxPrice +
    parseFloat(dPrice) +
    deliveryTaxPrice;
  return total.toFixed(2);
};
