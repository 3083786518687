/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions } from 'antd';
import { t } from 'i18next';
import CustomMap from '@components/custom-map';
import { useEffect, useState } from 'react';
import CustomImage from '@components/shared/custom-image';
import './styles.scss';

const StoreInfo = ({ obj, firebaseObj }) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    lat: '',
    lng: ''
  });

  useEffect(() => {
    if (obj?.lat && obj?.lng) {
      setSelectedLocation({
        lat: parseFloat(obj.lat),
        lng: parseFloat(obj?.lng)
      });
    }
  }, [obj?.lat, obj?.lgn]);

  return (
    <div className="order-store-info-wrapper">
      <div className="desc-map-wrapper">
        <Descriptions size="small" column={1} title={t('storeInfo')} bordered>
          <Descriptions.Item label={t('name')}>
            <div className="img-name-wrapper">
              {obj?.logo && (
                <CustomImage src={obj.logo} className="store-logo" />
              )}
              <span className="name-span">
                {firebaseObj?.storeName || obj?.name || '---'}
              </span>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={t('category')}>
            {obj?.category || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('phone')}>
            {obj?.phone || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('city')}>
            {obj?.city || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('region')}>
            {obj?.region || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('district')}>
            {obj?.district || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('note')}>
            {obj?.note || '---'}
          </Descriptions.Item>
        </Descriptions>
        <div className="map-wrapper">
          <CustomMap
            width="100%"
            height="315px"
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isDraggable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreInfo;
