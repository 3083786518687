import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allCountriesApi,
  singleCountryApi,
  createCountryApi,
  updateCountryApi,
  deleteCountryApi,
  changeCountryStatusApi
} from '@apis/countriesApis';
import customApiRequest from '@helpers/customApiRequest';

export const allCountriesAction = createAsyncThunk(
  'countries/allCountriesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allCountriesApi(filterObj),
      thunkAPI
    });
  }
);

export const singleCountryAction = createAsyncThunk(
  'countries/singleCountryAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleCountryApi(filterObj),
      thunkAPI
    });
  }
);

export const createCountryAction = createAsyncThunk(
  'countries/createCountryAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCountryApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const updateCountryAction = createAsyncThunk(
  'countries/updateCountryAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateCountryApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeCountryStatusAction = createAsyncThunk(
  'countries/changeCountryStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeCountryStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const changeSingleCountryStatusAction = createAsyncThunk(
  'countries/changeSingleCountryStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeCountryStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteCountryAction = createAsyncThunk(
  'countries/deleteCountryAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCountryApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteSingleCountryAction = createAsyncThunk(
  'countries/deleteSingleCountryAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCountryApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
