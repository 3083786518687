import { t } from 'i18next';
import './styles.scss';

const DriverMoreInfoCard = ({ obj }) => {
  return (
    <div className="info-row driver-more-info-card">
      <div className="info-row email">
        <span>{t('email')} : </span>
        <span>{obj?.email || '---'}</span>
      </div>
      <div className="info-row phone">
        <span>{t('phone')} : </span>
        <span>{obj?.phone || '---'}</span>
      </div>
      <div className="info-row nationality">
        <span>{t('nationality')} : </span>
        <span>{obj?.nationality?.name || '---'}</span>
      </div>
      <div className="info-row idNumber">
        <span>{t('idNumber')} : </span>
        <span>{obj?.idNumber || '---'}</span>
      </div>
      <div className="info-row birthDate">
        <span>{t('birthDate')} : </span>
        <span>{obj?.birthDate || '---'}</span>
      </div>
    </div>
  );
};

export default DriverMoreInfoCard;
