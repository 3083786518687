import React from 'react';
import { useController } from 'react-hook-form';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CustomAntdDatePicker = ({
  control,
  name,
  label,
  placeholder,
  validateStatus,
  defaultValue,
  errorMsg,
  className,
  formClassName,
  disablePast,
  disableFuture,
  format,
  picker,
  showTime = false
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });
  const { i18n } = useTranslation();
  const handleDisabledDate = (curr) => {
    if (disablePast) {
      return curr && moment(curr) < moment().add(-1, 'days');
      // return curr && curr <= moment();
      // return curr && moment(curr).isBefore();
    } else if (disableFuture) {
      return curr && moment(curr) > moment().add(-1, 'days');
    }
    return null;
  };
  return (
    <div className="custom-picker-wrap">
      <DatePicker
        disabledDate={handleDisabledDate}
        {...field}
        className={className}
        format={
          format || (i18n.language === 'ar' ? 'DD-MM-YYYY' : 'YYYY-MM-DD')
        }
        // format={format || 'YYYY-MM-DD'}
        // picker={picker || null}
        placeholder={placeholder}
        size="large"
        defaultValue={defaultValue}
        bordered={false}
        getPopupContainer={() => document.querySelector(`.${formClassName}`)}
        // value={defaultValue ? defaultValue : field.value}
        showTime={showTime}
      />

      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default CustomAntdDatePicker;
