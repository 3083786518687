const GeneralLayout = ({ children }) => {
  return (
    <div className="app-general-layout">
      {/* <h1>GENERAL</h1> */}
      {children}
    </div>
  );
};

export default GeneralLayout;
