/* eslint-disable eqeqeq */
import CustomImage from '@components/shared/custom-image';
import CustomTag from '@components/shared/custom-tag';
import { activeTypes, activeTypesArr } from '@mocks/index';
import { Button, Switch, Tooltip } from 'antd';
import { confirmModalTypesValues } from '@mocks/index';
import { TrashIcon, UpdateIcon } from '@icons/index';
import appRouterLinks from '../../../routes/appRouterLinks';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setPreviewCountryLogoModalOpened,
  setIsLoading,
  setConfirmModal,
  setDeleteModalOpened,
  token,
  t,
  dispatch,
  navigate
}) => {
  const handleToggleAgent = (record) => {
    if (record) {
      setSelectedTableRow(record);
      setConfirmModal({
        opened: true,
        type:
          record?.status == activeTypes?.active
            ? confirmModalTypesValues.errorModal
            : confirmModalTypesValues.successModal
      });
    }
  };

  const renderChangeStatusSwitch = (record) => {
    const found = activeTypesArr(t).find((obj) => obj?.value === record.status);
    if (found)
      return (
        <div className="action-btns-wrap">
          <Switch
            checked={record?.status === activeTypes?.active}
            onChange={(v, e) => {
              e.stopPropagation();
              e.preventDefault();
              handleToggleAgent(record);
            }}
            className={`affiliate-toggle ${
              record?.status === activeTypes?.notActive ? 'not-active' : ''
            }`}
          />
        </div>
      );
    return '---';
  };

  const renderDetailsBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('details')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              // width="24"
              height="24"
              fill="#196c10"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
          </Button>
        </Tooltip>
      </div>
    );
  };
  const renderUpdateBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('update')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
            onClick={(e) => {
              e.stopPropagation();
              navigate(appRouterLinks.editCountry(record.id));
            }}
          >
            <UpdateIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const renderDeleteBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('delete')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
            htmlType="button"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTableRow(record);
              setDeleteModalOpened(true);
            }}
          >
            <TrashIcon color="red" />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('photo'),
      dataIndex: 'photo',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell asset-img-row">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTableRow(record);
                setPreviewCountryLogoModalOpened(true);
              }}
              className="img-preview-btn"
            >
              <CustomImage src={record?.logo} className="asset-img" />
            </button>
          </div>
        );
      }
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: 132,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.name || '---'}</div>;
      }
    },
    {
      title: t('phoneKey'),
      dataIndex: 'phoneKey',
      width: 132,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.phoneKey || '---'}</div>
        );
      }
    },
    {
      title: t('status'),
      dataIndex: 'status',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.status ? (
              <CustomTag status={record?.status} arr={activeTypesArr(t)} />
            ) : (
              '---'
            )}
          </div>
        );
      }
    },

    {
      title: t('changeStatus'),
      dataIndex: 'changeStatus',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {renderChangeStatusSwitch(record)}
          </div>
        );
      }
    },
    {
      title: t('details'),
      dataIndex: 'details',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderDetailsBtn(record)}</div>
        );
      }
    },
    {
      title: t('update'),
      dataIndex: 'update',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderUpdateBtn(record)}</div>
        );
      }
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderDeleteBtn(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
