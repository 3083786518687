import AntdLineChart from '@components/shared/antd-charts/line-chart';
import AntdAreaChart from '@components/shared/antd-charts/area-chart';
import AntdColumnChart from '@components/shared/antd-charts/column-chart';
import AntdDonutChart from '@components/shared/antd-charts/donut-chart';
import AntdPieChart from '@components/shared/antd-charts/pie-chart';
import PageHeader from '@components/shared/page-header';
import { t } from 'i18next';
import { DashboardIcon } from '@icons/index';
import HomeOrdersStatistics from './home-orders-statistics';
import './styles.scss';
import appRouterLinks from '@routes/appRouterLinks';
import { Link } from 'react-router-dom';
import HomeMessagesList from './home-messages-list';
import OrdersTable from '../orders-pages/orders-page/orders-table';

const AdminHome = () => {
  return (
    <div className="admin-home-page">
      <PageHeader
        title={t('dashboard')}
        icon={<DashboardIcon />}
        breadcrumbItems={[
          {
            title: t('dashboard')
          }
        ]}
      />

      <HomeOrdersStatistics />

      <div className="two-cols-row">
        <div className="chart-card">
          <div className="chart-header">
            <p className="chart-title">Orders Overview</p>
            <Link to={appRouterLinks.orders}>Show all</Link>
          </div>
          <div className="chart-body">
            <AntdLineChart />
          </div>
        </div>
        <div className="chart-card">
          <div className="chart-header">
            <p className="chart-title">Sales Overview</p>
            <Link to={appRouterLinks.orders}>Show all</Link>
          </div>
          <div className="chart-body">
            <AntdAreaChart />
          </div>
        </div>
      </div>

      <div className="orders-table-home-wrap">
        <div className="table-header">
          <p className="table-title">Recent Orders</p>
          <Link to={appRouterLinks.orders}>Show all</Link>
        </div>
        <OrdersTable />
      </div>

      <div
        className="two-cols-row"
        style={{
          marginTop: 22
        }}
      >
        <div className="two-rows-col">
          <div className="chart-card">
            <div className="chart-header">
              <p className="chart-title">Restaurants Overview</p>
              <Link to={appRouterLinks.orders}>Show all</Link>
            </div>
            <div className="chart-body">
              <AntdColumnChart />
            </div>
          </div>

          <HomeMessagesList />
        </div>

        <div className="two-rows-col">
          <div className="chart-card">
            <div className="chart-header">
              <p className="chart-title">Customers Overview</p>
              <Link to={appRouterLinks.orders}>Show all</Link>
            </div>
            <div className="chart-body">
              <AntdPieChart />
            </div>
          </div>

          <div className="chart-card">
            <div className="chart-header">
              <p className="chart-title">Drivers Overview</p>
              <Link to={appRouterLinks.orders}>Show all</Link>
            </div>
            <div className="chart-body">
              <AntdDonutChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
