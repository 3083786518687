/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import CountriesTable from './countries-table';
import TableFilter from './countries-table/table-filter';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';
import {
  setDeleteMultiCountryModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/countries-slice/countriesSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteAllBtn from '@components/delete-all-btn';

const CountriesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedRowsIds = useSelector(
    (store) => store.countries.selectedRowsIds
  );
  return (
    <div className="countries-page">
      <PageHeader
        title={t('countries')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('countries')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.newCountry);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addCountry')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiCountryModalOpenedAction(true))
            }
          />
          <TableFilter />
        </div>
      </div>
      <CountriesTable />
    </div>
  );
};

export default CountriesPage;
