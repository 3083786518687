/* eslint-disable eqeqeq */
import { RadioButtonFilled, RadioButtonEmpty } from '@components/icons';

const CustomRadioGroup = ({ name, arr, watch, register, setValue, label }) => {
  return (
    <div className="custom-radios-wrap">
      {label ? <div className="radios-label">{label}</div> : null}
      <div className="labels-wrap">
        {arr.map((obj) => (
          <label
            key={obj?.id}
            className={`radio-input-label ${
              watch(name) == obj.value ? 'selected' : ''
            }`}
          >
            <input
              type="radio"
              value={String(obj.value)}
              {...register(name)}
              onClick={(e) => {
                if (String(watch(name)) === String(obj?.value))
                  setValue(name, '');
                else setValue(name, String(obj?.value));
              }}
            />

            <div className="svg-radio-icon-wrap">
              {watch(name) == String(obj?.value) ? (
                <RadioButtonFilled color="#d0ae6c" />
              ) : (
                <RadioButtonEmpty />
              )}
            </div>
            <span className={`label-span`}>{obj?.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CustomRadioGroup;
