import { Line } from '@ant-design/plots';
import data from './data';

const AntdLineChart = () => {
  const config = {
    data,
    xField: 'year',
    yField: 'value',
    seriesField: 'category',
    xAxis: {
      type: 'time'
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
      }
    }
  };

  return (
    <div
      style={{
        direction: 'ltr'
      }}
      className="antd-line-chart"
    >
      <Line {...config} />
    </div>
  );
};

export default AntdLineChart;
