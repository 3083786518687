import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allDeliveryMethodsAction,
  updateDeliveryMethodsAction,
  activateDeliveryMethodsAction,
  getPriceRangeMethodAction,
  getSinglePriceRangeMethodAction,
  createPriceRangeMethodAction,
  updatePriceRangeMethodAction,
  deletePriceRangeMethodAction
} from './sliceActionsThunkApis';

export function activateDeliveryMethod({ builder }) {
  const { pending, fulfilled, rejected } = activateDeliveryMethodsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isUpdatingDeliveryData = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isUpdatingDeliveryData = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchDeliveryDataCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isUpdatingDeliveryData = false;
    });
}

// * Start method 1, 3
export function getAllDeliveryMethods({ builder }) {
  const { pending, fulfilled, rejected } = allDeliveryMethodsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingDeliveryData = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingDeliveryData = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedDeliveryData = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingDeliveryData = false;
    });
}
export function updateDeliveryMethods({ builder }) {
  const { pending, fulfilled, rejected } = updateDeliveryMethodsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isUpdatingDeliveryData = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isUpdatingDeliveryData = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchDeliveryDataCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isUpdatingDeliveryData = false;
    });
}
// * End method 1, 3
////////////
// * Start Price range method (method 2)
export function getPriceRangeMethod({ builder }) {
  const { pending, fulfilled, rejected } = getPriceRangeMethodAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingRangeMethod = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingRangeMethod = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedRangeMethods = data?.data;
        if (data?.pagination) sliceState.driversPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingRangeMethod = false;
    });
}

export function getSinglePriceRangeMethod({ builder }) {
  const { pending, fulfilled, rejected } = getSinglePriceRangeMethodAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleRangeMethod = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleRangeMethod = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleRangeMethod = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleRangeMethod = false;
    });
}

export function createPriceRangeMethod({ builder }) {
  const { pending, fulfilled, rejected } = createPriceRangeMethodAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRangeForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRangeForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.confirmModal = {
      //     opened: false,
      //     type: ''
      //   };
      //   sliceState.fetchedRangeMethodCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRangeForm = false;
    });
}
export function updatePriceRangeMethod({ builder }) {
  const { pending, fulfilled, rejected } = updatePriceRangeMethodAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRangeForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRangeForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchedRangeMethodCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRangeForm = false;
    });
}

export function deletePriceRangeMethod({ builder }) {
  const { pending, fulfilled, rejected } = deletePriceRangeMethodAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRangeMethod = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRangeMethod = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.selectedRangeMethod = null;
        sliceState.deleteRangeMethodModalOpened = false;
        sliceState.fetchedRangeMethodCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRangeMethod = false;
    });
}
// * End Price range method
