import { Modal } from 'antd';
import CustomImage from '@components/shared/custom-image';
import './styles.scss';

const ImageModal = ({ title, open, onCancel, src, children }) => {
  return (
    <Modal
      className="image-modal"
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <CustomImage src={src} className="modal-img" />
      {children}
    </Modal>
  );
};

export default ImageModal;
