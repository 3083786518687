import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allOrdersApi,
  singleOrderApi,
  changeOrderStatusApi,
  deleteOrderApi,
  makeOrderApi,
  orderFreeDriversApi,
  assignDriverToOrderApi
} from '@apis/ordersApis';
import customApiRequest from '@helpers/customApiRequest';

export const allOrdersAction = createAsyncThunk(
  'orders/allOrdersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allOrdersApi(filterObj),
      thunkAPI
    });
  }
);

export const singleOrderAction = createAsyncThunk(
  'orders/singleOrderAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleOrderApi(filterObj),
      thunkAPI
    });
  }
);

export const getOrderFreeDriversAction = createAsyncThunk(
  'orders/getOrderFreeDriversAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await orderFreeDriversApi(filterObj),
      thunkAPI
    });
  }
);

export const assignDriverToOrderAction = createAsyncThunk(
  'orders/assignDriverToOrderAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await assignDriverToOrderApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeOrderStatusAction = createAsyncThunk(
  'orders/changeOrderStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeOrderStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const makeOrderAction = createAsyncThunk(
  'orders/makeOrderAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await makeOrderApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeSingleOrderStatusAction = createAsyncThunk(
  'orders/changeSingleOrderStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeOrderStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteOrderAction = createAsyncThunk(
  'orders/deleteOrderAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteOrderApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deleteSingleOrderAction = createAsyncThunk(
  'orders/deleteSingleOrderAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteOrderApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
