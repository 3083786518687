import { useLocation, Routes as Switch } from 'react-router-dom';
import AuthenticationRoutes from '@routes/authentication-routes/AuthenticationRoutes';
import { Route } from 'react-router-dom';
import SharedAuthRoutes from './protected-routes/shared-auth-routes/SharedAuthRoutes';

const AppRoutes = () => {
  const location = useLocation();
  return (
    <Switch location={location} key={location?.pathname}>
      {/* {GeneralRoutes()} */}
      {AuthenticationRoutes()}
      {SharedAuthRoutes()}

      <Route path="*" element={<h1>Not found</h1>} />
    </Switch>
  );
};

export default AppRoutes;
