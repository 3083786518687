/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrderFreeDriversAction } from '@redux/orders-slice/ordersSliceThunkApi';

const useFreeDrivers = ({ order_id, fetchCount }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();

  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && params?.order_id) {
      dispatch(
        getOrderFreeDriversAction({
          filterObj: {
            order_id: order_id || '',
            ...values
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);
};

export default useFreeDrivers;
