import { checkIfNumberWithDecimalPoint } from '@helpers/checkValidNumber';
import * as Yup from 'yup';

const formSchema = ({ sharedFormErr }) =>
  Yup.object().shape({
    priceForStart: Yup.string()
      .required(
        sharedFormErr({
          key: 'priceForStart'
        })
      )
      .test(
        'priceForStart',
        sharedFormErr({
          key: 'priceForStart',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v) => {
          let result = true;
          if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
          return result;
        }
      ),
    numberOfStartKm: Yup.string()
      .required(
        sharedFormErr({
          key: 'numberOfStartKm'
        })
      )
      .test(
        'numberOfStartKm',
        sharedFormErr({
          key: 'numberOfStartKm',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v) => {
          let result = true;
          if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
          return result;
        }
      ),
    priceOfPlusKm: Yup.string()
      .required(
        sharedFormErr({
          key: 'priceOfPlusKm'
        })
      )
      .test(
        'priceOfPlusKm',
        sharedFormErr({
          key: 'priceOfPlusKm',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v) => {
          let result = true;
          if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
          return result;
        }
      )
  });

export default formSchema;
