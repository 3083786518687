import {
  BagIcon,
  BikeIcon,
  CheckMarkIcon,
  CloseIcon,
  StoreIcon,
  TimeLineIcon,
  UserIcon,
  WarningIcon
} from '@icons/index';

export const responseCodes = {
  successCode: 200 || 201,
  unAuthCode: 401,
  notFoundCode: 404
};

export const accountTypes = () => {
  return {
    user: 1,
    merchant: 2,
    employee: 3
  };
};

export const confirmModalTypesValues = {
  successModal: 1,
  errorModal: 2,
  infoModal: 3
};

export const activeTypes = {
  active: 1,
  notActive: 2
};

export const activeTypesArr = (t) => {
  return [
    {
      id: 1,
      name: t('activeType'),
      value: activeTypes.active,
      color: 'green',
      icon: <CheckMarkIcon width={12} height={12} color="green" />
    },
    {
      id: 2,
      name: t('notActiveType'),
      value: activeTypes.notActive,
      color: 'red',
      icon: <CloseIcon width={12} height={12} color="red" />
    }
  ];
};

export const orderStatusValues = {
  new: 1,
  accepted: 2,
  arrivedToStore: 3,
  receiveOrderFromStore: 4,
  arrivedToClient: 5,
  completed: 6
};

export const orderStatusArr = (t) => [
  {
    id: 1,
    name: t('new'),
    value: orderStatusValues.new,
    color: 'orange',
    icon: <WarningIcon width={12} height={12} color="orange" />
  },
  {
    id: 2,
    name: t('accepted'),
    value: orderStatusValues.accepted,
    color: 'green',
    icon: <CheckMarkIcon width={12} height={12} color="green" />
  },
  {
    id: 3,
    name: t('arrivedToStore'),
    value: orderStatusValues.arrivedToStore,
    color: 'cyan',
    icon: <StoreIcon width={12} height={12} color="#0a979c" />
  },
  {
    id: 4,
    name: t('receiveOrderFromStore'),
    value: orderStatusValues.receiveOrderFromStore,
    color: 'magenta',
    icon: <BikeIcon width={12} height={12} color="#c41d7f" />
  },
  {
    id: 5,
    name: t('arrivedToClient'),
    value: orderStatusValues.arrivedToClient,
    color: 'green',
    icon: <BagIcon width={12} height={12} color="green" />
  },
  {
    id: 6,
    name: t('completed'),
    value: orderStatusValues.completed,
    color: 'green',
    icon: <CheckMarkIcon width={12} height={12} color="green" />
  }
];

export const orderStatusSelectOptionArr = (t) => [
  {
    id: 3,
    name: t('arrivedToStore'),
    value: orderStatusValues.arrivedToStore,
    color: 'cyan',
    icon: <StoreIcon width={12} height={12} color="#0a979c" />
  },
  {
    id: 4,
    name: t('receiveOrderFromStore'),
    value: orderStatusValues.receiveOrderFromStore,
    color: 'magenta',
    icon: <BikeIcon width={12} height={12} color="#c41d7f" />
  },
  {
    id: 5,
    name: t('arrivedToClient'),
    value: orderStatusValues.arrivedToClient,
    color: 'green',
    icon: <BagIcon width={12} height={12} color="green" />
  },
  {
    id: 6,
    name: t('completed'),
    value: orderStatusValues.completed,
    color: 'green',
    icon: <CheckMarkIcon width={12} height={12} color="green" />
  }
];

///
export const driverStatusValues = {
  active: 1,
  underReview: 2,
  rejected: 3
};

export const driverStatusArr = (t) => [
  {
    id: 1,
    name: t('active'),
    value: driverStatusValues.active,
    color: 'green',
    icon: <CheckMarkIcon width={12} height={12} color="green" />
  },
  {
    id: 2,
    name: t('underReview'),
    value: driverStatusValues.underReview,
    color: 'orange',
    icon: <TimeLineIcon width={12} height={12} color="orange" />
  },
  {
    id: 3,
    name: t('rejected'),
    value: driverStatusValues.rejected,
    color: 'red',
    icon: <CloseIcon width={12} height={12} color="red" />
  }
];
///
///
export const carTypesValues = {
  general: 1,
  private: 2
};

export const carTypesArr = (t) => [
  {
    id: 1,
    name: t('public'),
    value: carTypesValues.general,
    color: 'green',
    icon: <CheckMarkIcon width={12} height={12} color="green" />
  },
  {
    id: 2,
    name: t('private'),
    value: carTypesValues.private,
    color: 'blue',
    icon: <UserIcon width={12} height={12} color="blue" />
  }
];
///

export const genderTypesValues = {
  male: 1,
  female: 2
};

export const genderTypesArr = (t) => [
  {
    id: 1,
    name: t('male'),
    value: genderTypesValues.male
  },
  {
    id: 2,
    name: t('female'),
    value: genderTypesValues.female
  }
];

////////////////////////
export const clientRolesValues = {
  normal: 1,
  prime: 2
};

export const clientRolesArr = (t) => [
  {
    id: 1,
    name: t('normalClient'),
    value: clientRolesValues.normal
  },
  {
    id: 2,
    name: t('primeClient'),
    value: clientRolesValues.prime
  }
];

//////////////
export const isSpecialValues = {
  yes: 1,
  no: 2
};

export const isSpecialArr = (t) => [
  {
    id: 1,
    name: t('yes'),
    value: isSpecialValues.yes,
    color: 'green'
  },
  {
    id: 2,
    name: t('no'),
    value: isSpecialValues.no,
    color: 'red'
  }
];
//////////////
export const paymentTypesValues = {
  cash: 1,
  online: 2
};

export const paymentTypesArr = (t) => [
  {
    id: 1,
    name: t('cashPayment'),
    value: paymentTypesValues.cash
  },
  {
    id: 2,
    name: t('onlinePayment'),
    value: paymentTypesValues.online
  }
];
//////////////
// * Delivery mocks
export const deliveryMethodsTypes = {
  fixed: 1,
  min: 3,
  range: 2
};

//
export const walletTransactionsTypesValues = {
  withdrawal: 1,
  collection: 2,
  fine: 3
};
export const walletTransactionsTypesArr = (t) => {
  return [
    {
      id: 1,
      name: t('withdrawal'),
      value: walletTransactionsTypesValues.withdrawal,
      color: 'blue'
    },
    {
      id: 2,
      name: t('collection'),
      value: walletTransactionsTypesValues.collection,
      color: 'green'
    },
    {
      id: 3,
      name: t('fine'),
      value: walletTransactionsTypesValues.fine,
      color: 'red'
    }
  ];
};
////////
// * Withdrawals status values
export const withdrawalsStatusValues = {
  accepted: 1,
  inReview: 2,
  rejected: 3
};

export const withdrawalsStatusArr = (t) => {
  return [
    {
      id: 1,
      name: t('acceptedWithdrawal'),
      value: withdrawalsStatusValues.accepted,
      color: 'green'
    },
    {
      id: 2,
      name: t('inReview'),
      value: withdrawalsStatusValues.inReview,
      color: 'orange'
    },
    {
      id: 1,
      name: t('rejectedWithdrawal'),
      value: withdrawalsStatusValues.rejected,
      color: 'red'
    }
  ];
};
