/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import { createWalletTransactionAction } from '@redux/wallet-transactions-slice/sliceActionsThunkApis';
import { Button, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';
import AntdTextarea from '@components/shared/antd-form-components/AntdTextarea';
import { walletTransactionsTypesArr } from '@mocks/index';
import DriversSelectOptions from './DriversSelectOptions';
import getFormSchema from './formSchema';
import './styles.scss';

const WalletTransactionForm = ({ isSubmittingForm }) => {
  const { i18n } = useTranslation();

  const defaultValues = {
    type: '',
    user_id: '',
    amount: '',
    desc_en: '',
    desc_ar: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        t
      })
    ),
    mode: 'all',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`transactionForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      ...defaultValues
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('type', data.type);
    formData.append('user_id', data.user_id);
    formData.append('amount', data.amount);
    formData.append('desc_ar', data.desc_ar);
    formData.append('desc_en', data.desc_en);

    const action = await dispatch(
      createWalletTransactionAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action.payload.res;
    if (checkSuccessRes(res)) {
      resetFormInputs();
    }
  };

  return (
    <div className="transaction-form-wrapper">
      <Form
        className="transaction-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">
            {t('createWalletTransaction')}
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="amount"
              type="text"
              errorMsg={errors?.amount?.message}
              validateStatus={errors?.amount ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('amount', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('amount')}</p>
          </div>

          <DriversSelectOptions
            label={sharedFormLabel('user_id')}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name="type"
              errorMsg={errors?.type && errors.type.message}
              validateStatus={errors?.type ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                walletTransactionsTypesArr(t)?.length > 0 &&
                walletTransactionsTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <p className="custom-label">{sharedFormLabel('type')}</p>
          </div>

          <div className={`custom-text-area-wrapper client-note ${i18n.dir()}`}>
            <AntdTextarea
              rows={6}
              className="custom-text-area"
              name="desc_ar"
              errorMsg={errors?.desc_ar?.message}
              validateStatus={errors?.desc_ar ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{sharedFormLabel('desc_ar')}</p>
          </div>
          <div className={`custom-text-area-wrapper client-note ${i18n.dir()}`}>
            <AntdTextarea
              rows={6}
              className="custom-text-area"
              name="desc_en"
              errorMsg={errors?.desc_en?.message}
              validateStatus={errors?.desc_en ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{sharedFormLabel('desc_en')}</p>
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {watch('priceForStart') && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={() => {
                      resetFormInputs();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm ? t('saving') : t('createWalletTransaction')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default WalletTransactionForm;
