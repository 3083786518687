const formDefaultValues = {
  orderId: '',
  createdTime: '',
  acceptedTimeRestaurant: '',
  pickupTimeRestaurant: '',
  isSpecial: '',
  paymentType: '',
  clientData: {
    name: '',
    phone: '',
    gender: '',
    role: '',
    city: '',
    address: '',
    lat: '',
    lng: '',
    region: '',
    district: '',
    street: '',
    floorNumber: '',
    houseNumber: '',
    note: '',
    rate: ''
  },
  storeData: {
    name: '',
    category: '',
    logo: '',
    rate: '',
    city: '',
    region: '',
    district: '',
    lat: '',
    lng: '',
    note: '',
    phone: ''
  },
  products: [
    {
      name: '',
      quantity: '',
      unitPrice: '',
      note: '',
      addon: [],
      sizes: []
    }
  ],
  productPrice: '',
  productTax: '',
  deliveryPrice: '',
  deliveryTax: '',
  note: ''
};

export default formDefaultValues;
