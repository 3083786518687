import { Modal } from "antd";
import { useContext } from "react";
import successCheckImg from "@assets/imgs/success-check.png";
import ForgetPasswordContext from "@context/forget-password-context/ForgetPasswordContext";
import ForgetPasswordFormEnterCode from "./ForgetPasswordFormEnterCode";
import ForgetPasswordFormEnterEmail from "./ForgetPasswordFormEnterEmail";
import ForgetPasswordFormResetPassword from "./ForgetPasswordFormResetPassword";
import "./pageModal.scss";

const ForgetPasswordModal = () => {
  const {
    forgetPasswordModalOpened,
    setForgetPasswordModalOpened,
    forgetPasswordFormEnterEmailAppended,
    forgetPasswordFormEnterCodeAppended,
    forgetPasswordFormResetPasswordAppended,
    successLock,
  } = useContext(ForgetPasswordContext);
  return (
    <Modal
      className="forget-password-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: "642px" }}
      title="هل نسيت كلمة المرور؟"
      visible={forgetPasswordModalOpened}
      onOk={() => {
        setForgetPasswordModalOpened(false);
      }}
      onCancel={() => {
        setForgetPasswordModalOpened(false);
      }}
      footer={false}
    >
      {forgetPasswordFormEnterEmailAppended && <ForgetPasswordFormEnterEmail />}
      {forgetPasswordFormEnterCodeAppended && <ForgetPasswordFormEnterCode />}
      {forgetPasswordFormResetPasswordAppended && (
        <ForgetPasswordFormResetPassword />
      )}
      {successLock && <img src={successCheckImg} alt="success" />}
    </Modal>
  );
};

export default ForgetPasswordModal;
