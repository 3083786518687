import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  getAllWalletTransactionsAction,
  createWalletTransactionAction,
  getDriversBankAccountAction
} from './sliceActionsThunkApis';

export function getAllWalletTransactions({ builder }) {
  const { pending, fulfilled, rejected } = getAllWalletTransactionsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingWalletTransaction = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingWalletTransaction = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedWalletTransactions = data?.data;
        if (data?.pagination)
          sliceState.walletTransactionsPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingWalletTransaction = false;
    });
}
export function createWalletTransaction({ builder }) {
  const { pending, fulfilled, rejected } = createWalletTransactionAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingTransactionForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingTransactionForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchedWalletTransactionCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingTransactionForm = false;
    });
}

export function getDriversBankAccounts({ builder }) {
  const { pending, fulfilled, rejected } = getDriversBankAccountAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingDriverBankAccounts = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingDriverBankAccounts = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedDriversBankAccounts = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingDriverBankAccounts = false;
    });
}
