/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setDeleteRangeMethodModalOpenedAction,
  setSelectedRangeMethodAction
} from '@redux/delivery-methods-slice';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '@components/shared/loading-modal';
import {
  getPriceRangeMethodAction,
  deletePriceRangeMethodAction
} from '@redux/delivery-methods-slice/sliceActionsThunkApis';
import './styles.scss';
import TableColumns from './TableColumns';

const RangeMethodsTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingRangeMethod = useSelector(
    (store) => store.deliverMethods.isLoadingRangeMethod
  );
  const isSubmittingRangeMethod = useSelector(
    (store) => store.deliverMethods.isSubmittingRangeMethod
  );
  const deleteRangeMethodModalOpened = useSelector(
    (store) => store.deliverMethods.deleteRangeMethodModalOpened
  );
  const fetchedRangeMethodCount = useSelector(
    (store) => store.deliverMethods.fetchedRangeMethodCount
  );
  const fetchedRangeMethods = useSelector(
    (store) => store.deliverMethods.fetchedRangeMethods
  );
  const rangeMethodPagination = useSelector(
    (store) => store.deliverMethods.rangeMethodPagination
  );
  const selectedRangeMethod = useSelector(
    (store) => store.deliverMethods.selectedRangeMethod
  );

  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        getPriceRangeMethodAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchedRangeMethodCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedRangeMethods?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedRangeMethods);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchedRangeMethodCount,
    fetchedRangeMethods,
    fetchedRangeMethods?.length
  ]);

  let isMounted = true;
  const handleDelete = () => {
    if (isMounted && selectedRangeMethod?.id) {
      const formData = new FormData();
      formData.append('price_id', selectedRangeMethod.id);
      dispatch(
        deletePriceRangeMethodAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        width={900}
        // scroll={900}
        scroll={500}
        showHeader={true}
        className={`range-methods-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedRangeMethods}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteRangeMethodModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedRangeMethodAction(v))}
        //
        isLoadingTableData={isLoadingRangeMethod}
        user={user}
        handleRowClick={(record, x) => {
          navigate(appRouterLinks.updateRangeMethodRout(record?.id));
        }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={rangeMethodPagination}
        baseTablePageUrl={`${appRouterLinks.rangeMethodRoute}`}
      />

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteRangeMethodModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedRangeMethodAction(null));
          }
          dispatch(setDeleteRangeMethodModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedRangeMethod?.id || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {isSubmittingRangeMethod && <LoadingModal />}
    </>
  );
};

export default RangeMethodsTable;
