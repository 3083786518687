import ProfileMenu from '@components/profile-menu';
import LanguageButton from '@components/shared/language-button';
import { MenuFold, MenuIcon, MenuRtlIcon, MenuUnFold } from '@icons/index';
import {
  setAppInFullScreen,
  setAppMenuCollapsed
} from '@redux/general-app-slice/generalAppSlice';
import { Button, Tooltip } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
// import 'moment/locale/ar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreenExitIcon, FullScreenIcon } from '../icons';
import './styles.scss';

const AppTopBar = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const appMenuCollapsed = useSelector(
    (store) => store.generalApp.appMenuCollapsed
  );
  const appInFullScreen = useSelector(
    (store) => store.generalApp.appInFullScreen
  );

  const handleClickAppMenuBtn = () => {
    dispatch(setAppMenuCollapsed(!appMenuCollapsed));
  };

  // const myMoment = moment();
  // myMoment.locale(i18n.language);
  // useEffect(() => {
  // }, [i18n.language]);

  const [timeNow, setTimeNow] = useState(
    moment().format('Do / MMM / YYYY , h:mm:ss a')
  );
  useEffect(() => {
    const cb = () => {
      setTimeNow(moment().format('Do / MMM / YYYY , h:mm:ss a'));
    };
    setInterval(cb, 1000);
    return () => {
      clearInterval(cb);
    };
  }, []);

  const [scrollY, setScrollY] = useState(
    window.scrollY || document.documentElement.scrollTop
  );
  const [scrollDir, setScrollDir] = useState(null);
  useEffect(() => {
    const handleScroll = () => {
      if ((window.scrollY || document.documentElement.scrollTop) > scrollY) {
        setScrollDir('scroll-down');
      } else {
        setScrollDir('scroll-up');
      }
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <div
      className={`app-top-bar ${scrollDir || ''} ${
        scrollY > 0 ? 'exceeds0' : ''
      }`}
    >
      <div className="custom-container">
        <div className="app-bar-content">
          <Button
            icon={
              appMenuCollapsed ? (
                <div className="svg-wrap arrow-wrap">
                  {i18n.dir() === 'rtl' ? (
                    <MenuFold width={18} height={18} />
                  ) : (
                    <MenuUnFold width={18} height={18} />
                  )}
                </div>
              ) : (
                <div className="svg-wrap menu-wrap">
                  {i18n.dir() === 'rtl' ? (
                    <MenuRtlIcon width={18} height={18} />
                  ) : (
                    <MenuIcon width={18} height={18} />
                  )}
                </div>
              )
            }
            type="ghost"
            shape="circle"
            onClick={handleClickAppMenuBtn}
            className="app-menu-btn"
            size="large"
          />

          <div className="bar-actions-wrap">
            <p className="date-p">{timeNow}</p>

            <Tooltip title={appInFullScreen ? t('exitFs') : t('enterFs')}>
              <Button
                icon={
                  appInFullScreen ? <FullScreenExitIcon /> : <FullScreenIcon />
                }
                shape="circle"
                className="full-screen-btn"
                size="large"
                type="text"
                onClick={() => {
                  dispatch(setAppInFullScreen(!appInFullScreen));
                }}
              />
            </Tooltip>

            <LanguageButton />

            <ProfileMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppTopBar;
