import './styles.scss';
import CustomImage from '@components/shared/custom-image';
import userImg from '@assets/imgs/user.jpg';

const MessageListItem = () => {
  return (
    <div className="message-list-item">
      <CustomImage src={userImg} className="user-img" />

      <div className="item-body">
        <div className="name-time">
          <p className="name">Mohamed Ramy</p>
          <p className="date">13-05-2023</p>
        </div>

        <p className="msg">Short message goes here...</p>
      </div>
    </div>
  );
};

export default MessageListItem;
