// import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { checkIfNumberWithDecimalPoint } from '@helpers/checkValidNumber';

const sharedSchema = (t) => {
  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `rangeMethodForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  return {
    from: Yup.string()
      .required(
        sharedFormErr({
          key: 'from'
        })
      )
      .test(
        'from',
        sharedFormErr({
          key: 'from',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      ),
    to: Yup.string()
      .required(
        sharedFormErr({
          key: 'to'
        })
      )
      .test(
        'to',
        sharedFormErr({
          key: 'to',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      ),
    price: Yup.string()
      .required(
        sharedFormErr({
          key: 'price'
        })
      )
      .test(
        'price',
        sharedFormErr({
          key: 'price',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      )
  };
};

const getFormSchema = ({ fetchedObj, t }) => {
  if (fetchedObj) {
    return Yup.object().shape({
      ...sharedSchema(t)
    });
  }
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

export default getFormSchema;
