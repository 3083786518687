import { Select } from 'antd';
import { t } from 'i18next';
import './styles.scss';

const StatusSelectOption = ({
  statusArr,
  selectedStatus,
  fetchedDefaultStatus,
  handleChangeSelectOption,
  width
}) => {
  return (
    <div className="status-select-option-wrapper">
      <div className="select-label-wrap">
        <div className="select-label">{t('changeStatus')}</div>
        <Select
          value={
            selectedStatus?.value
              ? String(selectedStatus?.value)
              : String(fetchedDefaultStatus)
          }
          defaultValue={
            fetchedDefaultStatus ? String(fetchedDefaultStatus) : ''
          }
          style={{
            width: width || 132
          }}
          onChange={handleChangeSelectOption}
          options={
            // {
            //   label: 'Select status',
            //   value: ''
            // },
            statusArr?.length > 0
              ? statusArr.map((obj) => {
                  return {
                    label: obj?.name,
                    value: String(obj?.value)
                  };
                })
              : []
          }
        />
      </div>
    </div>
  );
};

export default StatusSelectOption;
