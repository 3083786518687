/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { CarIcon } from '@icons/index';
import { t } from 'i18next';
import WalletSettingsForm from './wallet-settings-form';
import './styles.scss';

const WalletSettingsPage = () => {
  return (
    <div className="wallet-settings-page">
      <PageHeader
        title={t('walletSettings')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('walletSettings')
          }
        ]}
      />

      <WalletSettingsForm />
    </div>
  );
};

export default WalletSettingsPage;
