/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  allDeliveryMethodsAction,
  updateDeliveryMethodsAction
} from '@redux/delivery-methods-slice/sliceActionsThunkApis';
import { setFetchDeliveryDataCountAction } from '@redux/delivery-methods-slice';
import { Button, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getFormSchema from './formSchema';
import './styles.scss';
import { useEffect } from 'react';
import LoadingModal from '@components/shared/loading-modal';

const WalletSettingsForm = () => {
  const { i18n } = useTranslation();

  const defaultValues = {
    walletStart: '',
    walletEnd: '',
    walletRest: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        t
      })
    ),
    mode: 'all',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`calcDeliveryForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      walletStart:
        fetchedDeliveryData?.walletStart || defaultValues.walletStart,
      walletEnd: fetchedDeliveryData?.walletEnd || defaultValues.walletEnd,
      walletRest: fetchedDeliveryData?.walletRest || defaultValues.walletRest
    });
  };

  const dispatch = useDispatch();
  const fetchedDeliveryData = useSelector(
    (store) => store.deliverMethods.fetchedDeliveryData
  );
  const fetchDeliveryDataCount = useSelector(
    (store) => store.deliverMethods.fetchDeliveryDataCount
  );
  const isSubmittingForm = useSelector(
    (store) => store.deliverMethods.isUpdatingDeliveryData
  );

  useEffect(() => {
    dispatch(allDeliveryMethodsAction());
  }, [fetchDeliveryDataCount]);

  // handle default values
  useEffect(() => {
    if (fetchedDeliveryData) {
      setValue(
        'walletStart',
        fetchedDeliveryData?.walletStart || defaultValues.walletStart
      );
      setValue(
        'walletEnd',
        fetchedDeliveryData?.walletEnd || defaultValues.walletEnd
      );
      setValue(
        'walletRest',
        fetchedDeliveryData?.walletRest || defaultValues.walletRest
      );
    }
  }, [fetchedDeliveryData]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('walletStart', data.walletStart);
    formData.append('walletEnd', data.walletEnd);
    formData.append('walletRest', data.walletRest);

    const action = await dispatch(
      updateDeliveryMethodsAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action.payload.res;
    if (checkSuccessRes(res)) {
      dispatch(setFetchDeliveryDataCountAction(fetchDeliveryDataCount + 1));
    }
  };

  return (
    <div className="transaction-form-wrapper">
      <Form
        className="transaction-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">{t('walletSettings')}</div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="walletStart"
              type="text"
              errorMsg={errors?.walletStart?.message}
              validateStatus={errors?.walletStart ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('walletStart', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('walletStart')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="walletEnd"
              type="text"
              errorMsg={errors?.walletEnd?.message}
              validateStatus={errors?.walletEnd ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('walletEnd', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('walletEnd')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="walletRest"
              type="text"
              errorMsg={errors?.walletRest?.message}
              validateStatus={errors?.walletRest ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('walletRest', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('walletRest')}</p>
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {watch('walletStart') && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={() => {
                      resetFormInputs();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm ? t('saving') : t('save')}
            </Button>
          </div>
        </div>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </div>
  );
};

export default WalletSettingsForm;
