/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteRegionModalOpenedAction,
  setSelectedRegionAction,
  setDeleteMultiRegionModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/regions-slice/regionsSlice';
import {
  allRegionsAction,
  changeRegionStatusAction,
  deleteRegionAction
} from '@redux/regions-slice/regionsSliceThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '../../../components/shared/loading-modal';
import './styles.scss';
import TableColumns from './TableColumns';

const RegionsTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingRegion = useSelector((store) => store.regions.isLoadingRegion);
  const isSubmittingRegion = useSelector(
    (store) => store.regions.isSubmittingRegion
  );
  const isSubmittingRegionStatus = useSelector(
    (store) => store.regions.isSubmittingRegionStatus
  );
  const deleteRegionModalOpened = useSelector(
    (store) => store.regions.deleteRegionModalOpened
  );
  const fetchRegionsCount = useSelector(
    (store) => store.regions.fetchRegionsCount
  );
  const fetchedRegions = useSelector((store) => store.regions.fetchedRegions);
  const regionsPagination = useSelector(
    (store) => store.regions.regionsPagination
  );
  const selectedRegion = useSelector((store) => store.regions.selectedRegion);
  const confirmModalType = useSelector(
    (store) => store.regions.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.regions.confirmModal.opened
  );
  const selectedRowsIds = useSelector((store) => store.regions.selectedRowsIds);
  const deleteMultiRegionModalOpened = useSelector(
    (store) => store.regions.deleteMultiRegionModalOpened
  );

  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allRegionsAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchRegionsCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedRegions?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedRegions);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchRegionsCount, fetchedRegions, fetchedRegions?.length]);

  let isMounted = true;
  const handleChangeStatus = () => {
    if (isMounted && selectedRegion?.id) {
      const formData = new FormData();
      formData.append('region_id', selectedRegion.id);
      dispatch(
        changeRegionStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDelete = () => {
    if (isMounted && selectedRegion?.id) {
      const formData = new FormData();
      formData.append('region_id', selectedRegion.id);
      dispatch(
        deleteRegionAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('region_id', selectedRowsIds);
      dispatch(
        deleteRegionAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={800}
        showHeader={true}
        className={`regions-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedRegions}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteRegionModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedRegionAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        //
        isLoadingTableData={isLoadingRegion}
        user={user}
        handleRowClick={(record) => {
          navigate(appRouterLinks.editRegion(record?.id));
        }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={regionsPagination}
        baseTablePageUrl={`${appRouterLinks.regions}?searchText=${
          values?.searchText || ''
        }&status=${values?.status}&city_id=${values?.city_id || ''}`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedRegionAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeStatus}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>
            {`${t('sureActivate')}`}{' '}
            <span className="bold-font">{selectedRegion?.name || ''} !?</span>{' '}
          </h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>
            {`${t('sureDeactivate')}`}{' '}
            <span className="bold-font">{selectedRegion?.name || ''} !?</span>{' '}
          </h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteRegionModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedRegionAction(null));
          }
          dispatch(setDeleteRegionModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedRegion?.name || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiRegionModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiRegionModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {(isSubmittingRegion || isSubmittingRegionStatus) && <LoadingModal />}
    </>
  );
};

export default RegionsTable;
