import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allOrdersAction,
  singleOrderAction,
  makeOrderAction,
  changeOrderStatusAction,
  deleteOrderAction,
  changeSingleOrderStatusAction,
  deleteSingleOrderAction,
  getOrderFreeDriversAction,
  assignDriverToOrderAction
} from './ordersSliceThunkApi';

export function getOrders({ builder }) {
  const { pending, fulfilled, rejected } = allOrdersAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingOrders = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingOrders = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedOrders = data?.data;
        if (data?.pagination) sliceState.ordersPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingOrders = false;
    });
}
export function getSingleOrder({ builder }) {
  const { pending, fulfilled, rejected } = singleOrderAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleOrder = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleOrder = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleOrder = false;
    });
}
export function getOrderFreeDrivers({ builder }) {
  const { pending, fulfilled, rejected } = getOrderFreeDriversAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingOrderFreeDrivers = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingOrderFreeDrivers = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedFreeDrivers = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingOrderFreeDrivers = false;
    });
}

export function assignDriverToOrder({ builder }) {
  const { pending, fulfilled, rejected } = assignDriverToOrderAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.assignDriverModalOpened = false;
        sliceState.fetchSingleOrderCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = false;
    });
}

export function makeOrder({ builder }) {
  const { pending, fulfilled, rejected } = makeOrderAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingOrder = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchOrdersCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingOrder = false;
    });
}

export function changeOrderStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeOrderStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingOrder = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchOrdersCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingOrder = false;
    });
}
export function changeSingleOrderStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleOrderStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleOrderCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = false;
    });
}
export function deleteOrder({ builder }) {
  const { pending, fulfilled, rejected } = deleteOrderAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingOrder = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteOrderModalOpened = false;
        sliceState.deleteMultiOrderModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.fetchOrdersCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingOrder = false;
    });
}
export function deleteSingleOrder({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleOrderAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = false;
      if (checkSuccessRes(action?.payload?.res)) {
        // sliceState.fetchOrdersCount += 1;
        sliceState.deleteOrderModalOpened = false;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleOrder = false;
    });
}
