import { createSlice } from '@reduxjs/toolkit';
import {
  getOrders,
  getSingleOrder,
  makeOrder,
  changeOrderStatus,
  changeSingleOrderStatus,
  deleteOrder,
  deleteSingleOrder,
  getOrderFreeDrivers,
  assignDriverToOrder
} from './sliceExtraReducers';

const initialState = {
  // all orders
  isLoadingOrders: false,
  isLoadingOrderFreeDrivers: false,
  assignDriverModalOpened: false,
  fetchOrdersCount: 0,
  fetchedOrders: [],
  fetchedFreeDrivers: [],
  selectedOrder: null,
  selectedRowsIds: '',
  isSubmittingOrder: false,
  ordersPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // single order
  isLoadingSingleOrder: false,
  fetchSingleOrderCount: 0,
  fetchedSingleOrder: null,
  isSubmittingSingleOrder: false,
  ///////////////////////
  //
  deleteOrderModalOpened: false,
  deleteMultiOrderModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSelectedOrderAction: (sliceState, action) => {
      sliceState.selectedOrder = action.payload;
    },
    setAssignDriverModalOpenedAction: (sliceState, action) => {
      sliceState.assignDriverModalOpened = action.payload;
    },
    setDeleteOrderModalOpenedAction: (sliceState, action) => {
      sliceState.deleteOrderModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setFetchedOrdersAction: (sliceState, action) => {
      sliceState.fetchedOrders = action.payload;
    },
    setFetchedSingleOrderAction: (sliceState, action) => {
      sliceState.fetchedSingleOrder = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteMultiOrderModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiOrderModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    getOrders({ builder });
    getSingleOrder({ builder });
    getOrderFreeDrivers({ builder });
    assignDriverToOrder({ builder });
    makeOrder({ builder });
    changeOrderStatus({ builder });
    changeSingleOrderStatus({ builder });
    deleteOrder({ builder });
    deleteSingleOrder({ builder });
  }
});

const ordersReducer = ordersSlice.reducer;

export const {
  setSelectedOrderAction,
  setAssignDriverModalOpenedAction,
  setFetchedOrdersAction,
  setFetchedSingleOrderAction,
  setConfirmModalAction,
  setDeleteOrderModalOpenedAction,
  setSelectedRowsIdsAction,
  setDeleteMultiOrderModalOpenedAction
} = ordersSlice.actions;

export default ordersReducer;
