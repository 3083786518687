/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteCityModalOpenedAction,
  setSelectedCityAction,
  setDeleteMultiCityModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/cities-slice/citiesSlice';
import {
  allCitiesAction,
  changeCityStatusAction,
  deleteCityAction
} from '@redux/cities-slice/citiesSliceThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '@components/shared/loading-modal';
import './styles.scss';
import TableColumns from './TableColumns';

const CitiesTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingCity = useSelector((store) => store.cities.isLoadingCity);
  const isSubmittingCity = useSelector(
    (store) => store.cities.isSubmittingCity
  );
  const isSubmittingCityStatus = useSelector(
    (store) => store.cities.isSubmittingCityStatus
  );
  const deleteCityModalOpened = useSelector(
    (store) => store.cities.deleteCityModalOpened
  );
  const fetchCitiesCount = useSelector(
    (store) => store.cities.fetchCitiesCount
  );
  const fetchedCities = useSelector((store) => store.cities.fetchedCities);
  const citiesPagination = useSelector(
    (store) => store.cities.citiesPagination
  );
  const selectedCity = useSelector((store) => store.cities.selectedCity);
  const confirmModalType = useSelector(
    (store) => store.cities.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.cities.confirmModal.opened
  );
  const selectedRowsIds = useSelector((store) => store.cities.selectedRowsIds);
  const deleteMultiCityModalOpened = useSelector(
    (store) => store.cities.deleteMultiCityModalOpened
  );
  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allCitiesAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCitiesCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedCities?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedCities);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCitiesCount, fetchedCities, fetchedCities?.length]);

  let isMounted = true;

  const handleChangeStatus = () => {
    if (isMounted && selectedCity?.id) {
      const formData = new FormData();
      formData.append('city_id', selectedCity.id);
      dispatch(
        changeCityStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDelete = () => {
    if (isMounted && selectedCity?.id) {
      const formData = new FormData();
      formData.append('city_id', selectedCity.id);
      dispatch(
        deleteCityAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('city_id', selectedRowsIds);
      dispatch(
        deleteCityAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={800}
        showHeader={true}
        className={`cities-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedCities}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteCityModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedCityAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        //
        isLoadingTableData={isLoadingCity}
        user={user}
        // handleRowClick={(record) => {
        //   navigate(appRouterLinks.editCity(record?.id));
        // }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={citiesPagination}
        baseTablePageUrl={`${appRouterLinks.cities}?searchText=${
          values?.searchText || ''
        }&country_id=${values?.country_id || ''}&status=${
          values?.status || ''
        }`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedCityAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeStatus}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>
            {`${t('sureActivate')}`}{' '}
            <span className="bold-font">{selectedCity?.name || ''} !?</span>{' '}
          </h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>
            {`${t('sureDeactivate')}`}{' '}
            <span className="bold-font">{selectedCity?.name || ''} !?</span>{' '}
          </h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteCityModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedCityAction(null));
          }
          dispatch(setDeleteCityModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedCity?.name || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiCityModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiCityModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {(isSubmittingCity || isSubmittingCityStatus) && <LoadingModal />}
    </>
  );
};

export default CitiesTable;
