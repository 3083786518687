import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useSingleCarYear from '@customHooks/useSingleCarYear';
import CarYearForm from './car-year-form';

const NewCarYearPage = () => {
  const params = useParams();
  const isLoadingSingleCarYear = useSelector(
    (store) => store.carYears.isLoadingSingleCarYear
  );
  const isSubmittingCarYearForm = useSelector(
    (store) => store.carYears.isSubmittingCarYearForm
  );
  const fetchedSingleCarYear = useSelector(
    (store) => store.carYears.fetchedSingleCarYear
  );

  // * get single car year
  useSingleCarYear();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleCarYear && params?.year_id
            ? `${t('update')} ${fetchedSingleCarYear?.year}` ||
              `${
                fetchedSingleCarYear?.id ? `#${fetchedSingleCarYear.id}` : ''
              }` ||
              ' '
            : t('newYear')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.vehiclesYears}>{t('vehicleYears')}</Link>
            )
          },
          {
            title:
              fetchedSingleCarYear && params?.year_id
                ? fetchedSingleCarYear?.name ||
                  `${
                    fetchedSingleCarYear?.id
                      ? `#${fetchedSingleCarYear.id}`
                      : ''
                  }` ||
                  ' '
                : t('newYear')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-car-year-page">
        <SharedPageHeader />

        <CarYearForm
          fetchedObj={params?.year_id ? fetchedSingleCarYear : null}
          isSubmittingForm={isSubmittingCarYearForm}
        />
      </div>

      {(isSubmittingCarYearForm || isLoadingSingleCarYear) && <LoadingModal />}
    </PageWrapperMotion>
  );
};

export default NewCarYearPage;
