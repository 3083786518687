import sharedRouterLinks from './shared-routes/sharedRouterLinks';
import authenticationRouterLinks from './authentication-routes/authenticationRouterLinks';
import generalRouterLinks from './general-routes/generalRouterLinks';
import adminRouterLinks from './protected-routes/admin-routes/adminRouterLinks';
import sharedAuthRouterLinks from './protected-routes/shared-auth-routes/sharedAuthRouterLinks';

const appRouterLinks = {
  ...generalRouterLinks,
  ...authenticationRouterLinks,
  ...sharedAuthRouterLinks,
  ...adminRouterLinks,
  ...sharedRouterLinks
};

export default appRouterLinks;
