import { createSlice } from '@reduxjs/toolkit';
import {
  resendSignupCodeAction,
  signinAction,
  userInfoAction,
  userSignoutAction
} from './userSliceThunkApis';

const getUserFromSession = () => {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  } else if (localStorage.getItem('currentUser')) {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  return null;
};

const initialState = {
  notAuthedModalOpened: false,
  isLoadingUser: false,
  isSubmitting: false,
  loggedIn:
    localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser')
      ? true
      : false,
  user: getUserFromSession()
};

function login(builder) {
  const { pending, fulfilled, rejected } = signinAction;
  builder
    .addCase(pending, (state, action) => {
      state.isSubmitting = true;
    })
    .addCase(fulfilled, (state, action) => {
      state.isSubmitting = false;
    })
    .addCase(rejected, (state, action) => {
      state.isSubmitting = false;
    });
}

function resendSignupActiveCode(builder) {
  const { pending, fulfilled, rejected } = resendSignupCodeAction;
  builder
    .addCase(pending, (state, action) => {
      state.isSubmitting = true;
    })
    .addCase(fulfilled, (state, action) => {
      state.isSubmitting = false;
    })
    .addCase(rejected, (state, action) => {
      state.isSubmitting = false;
    });
}
function userInfo(builder) {
  const { pending, fulfilled, rejected } = userInfoAction;
  builder
    .addCase(pending, (state, action) => {
      state.isLoadingUser = true;
    })
    .addCase(fulfilled, (state, action) => {
      state.isLoadingUser = false;
    })
    .addCase(rejected, (state, action) => {
      state.isLoadingUser = false;
    });
}
function logout(builder) {
  const { pending, fulfilled, rejected } = userSignoutAction;
  builder
    .addCase(pending, (state, action) => {
      state.isSubmitting = true;
    })
    .addCase(fulfilled, (state, action) => {
      state.isSubmitting = false;
    })
    .addCase(rejected, (state, action) => {
      state.isSubmitting = false;
    });
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoadingUser: (stateSlice, action) => {
      stateSlice.isLoadingUser = action.payload;
    },
    setUserToStateAction: (sliceState, action) => {
      sliceState.user = action.payload;
      sliceState.loggedIn = true;
      sessionStorage.setItem('currentUser', JSON.stringify(action.payload));
    },
    setCurrentUserAction: (sliceState, action) => {
      sliceState.user = action.payload;
      sliceState.loggedIn = true;
      localStorage.setItem('currentUser', JSON.stringify(action.payload));
    },
    removeCurrentUserAction: (sliceState, action) => {
      sliceState.user = null;
      sliceState.loggedIn = false;
      sessionStorage.removeItem('currentUser');
      localStorage.removeItem('currentUser');
    },
    setNotAuthedModalOpened: (sliceState, action) => {
      sliceState.notAuthedModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    login(builder);
    resendSignupActiveCode(builder);
    userInfo(builder);
    logout(builder);
  }
});

const userReducer = userSlice.reducer;

export const {
  setIsLoadingUser,
  setUserToStateAction,
  setCurrentUserAction,
  removeCurrentUserAction,
  setNotAuthedModalOpened
} = userSlice.actions;

export default userReducer;
