// import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { checkIfNumberWithDecimalPoint } from '@helpers/checkValidNumber';

const sharedSchema = (t) => {
  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `transactionForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  return {
    amount: Yup.string()
      .required(
        sharedFormErr({
          key: 'amount'
        })
      )
      .test(
        'amount',
        sharedFormErr({
          key: 'amount',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      ),
    user_id: Yup.string().required(
      sharedFormErr({
        key: 'user_id'
      })
    ),
    type: Yup.string().required(
      sharedFormErr({
        key: 'type'
      })
    ),
    desc_ar: Yup.string().required(
      sharedFormErr({
        key: 'desc_ar'
      })
    ),
    desc_en: Yup.string().required(
      sharedFormErr({
        key: 'desc_en'
      })
    )
  };
};

const getFormSchema = ({ t }) => {
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

export default getFormSchema;
