/* eslint-disable eqeqeq */

import { Tag } from 'antd';

const TableColumns = ({ setSelectedTableRow, t }) => {
  const renderNamesArr = (arr, nameKey) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 8,
          flexWrap: 'wrap'
        }}
      >
        {arr.map((item) => (
          <Tag key={item?.id}>
            <span>
              {item[nameKey]} ( {item?.price || 0} {t('currency.sar')} )
            </span>
          </Tag>
        ))}
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: 282,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.name || '---'}</div>;
      }
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.quantity || '---'}</div>
        );
      }
    },
    {
      title: t('unitPrice'),
      dataIndex: 'unitPrice',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.unitPrice || 0} {t('currency.sar')}
          </div>
        );
      }
    },
    {
      title: t('totalPrice'),
      dataIndex: 'totalPrice',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.totalPrice || 0} {t('currency.sar')}
          </div>
        );
      }
    },
    {
      title: t('adds'),
      dataIndex: 'adds',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.adds?.length > 0
              ? renderNamesArr(record.adds, 'name')
              : '---'}
          </div>
        );
      }
    },
    {
      title: t('sizes'),
      dataIndex: 'sizes',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.sizes?.length > 0
              ? renderNamesArr(record.sizes, 'size')
              : '---'}
          </div>
        );
      }
    },
    {
      title: t('note'),
      dataIndex: 'note',
      width: 282,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.note || '---'}</div>;
      }
    }
  ];
};

export default TableColumns;
