import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import { WalletIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import WalletTransactionForm from './wallet-transaction-form';

const CreateWalletTransactionPage = () => {
  const isLoadingWalletTransaction = useSelector(
    (store) => store.walletTransactions.isLoadingWalletTransaction
  );
  const isSubmittingTransactionForm = useSelector(
    (store) => store.walletTransactions.isSubmittingTransactionForm
  );

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={t('createWalletTransaction')}
        icon={<WalletIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.walletTransactionsRoute}>
                {t('walletTransactions')}
              </Link>
            )
          },
          {
            title: t('createWalletTransaction')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-car-year-page">
        <SharedPageHeader />

        <WalletTransactionForm isSubmittingForm={isSubmittingTransactionForm} />
      </div>

      {(isSubmittingTransactionForm || isLoadingWalletTransaction) && (
        <LoadingModal />
      )}
    </PageWrapperMotion>
  );
};

export default CreateWalletTransactionPage;
