/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { singleRegionAction } from '@redux/regions-slice/regionsSliceThunkApis';
import { useTranslation } from 'react-i18next';

const useSingleRegion = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const fetchSingleRegionCount = useSelector(
    (store) => store.regions.fetchSingleRegionCount
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && params?.region_id) {
      dispatch(
        singleRegionAction({
          filterObj: {
            region_id: params?.region_id || '',
            ...values
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSingleRegionCount, search, i18n.language]);
};

export default useSingleRegion;
