// import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  checkIfNumberWithDecimalPoint,
  checkIfNumberWithNoDecimalPoint
} from '@helpers/checkValidNumber';

const sharedSchema = (t) => {
  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `orderForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  return {
    orderId: Yup.string()
      .required(
        sharedFormErr({
          key: 'orderId'
        })
      )
      .test(
        'orderId',
        sharedFormErr({
          key: 'orderId',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v) => {
          let result = true;
          if (v && !checkIfNumberWithNoDecimalPoint(v)) result = false;
          return result;
        }
      ),
    productPrice: Yup.string()
      .required(
        sharedFormErr({
          key: 'productPrice'
        })
      )
      .test(
        'productPrice',
        sharedFormErr({
          key: 'productPrice',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v) => {
          let result = true;
          if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
          return result;
        }
      ),

    // clientData: Yup.object().shape({
    //   name: Yup.string().required(
    //     sharedFormErr({
    //       key: 'clientName'
    //     })
    //   )
    // }),
    storeData: Yup.object()
      .notRequired()
      .shape({
        logo: Yup.string().url(
          sharedFormErr({
            key: 'storeLogo'
          })
        )
      }),
    //////////////////////////
    products: Yup.array()
      .notRequired()
      .of(
        Yup.object().shape({
          name: Yup.string().test(
            'products.index.name',
            sharedFormErr({
              key: 'productName'
            }),
            (v, ctx) => {
              let result = true;
              if (!v && (ctx?.parent?.unitPrice || ctx?.parent?.quantity))
                result = false;
              return result;
            }
          ),
          quantity: Yup.string()
            .test(
              'products.index.quantity',
              sharedFormErr({
                key: 'productQty'
              }),
              (v, ctx) => {
                let result = true;
                if (!v && (ctx?.parent?.name || ctx?.parent?.price))
                  result = false;
                return result;
              }
            )
            .test(
              'products.index.quantity',
              sharedFormErr({
                key: 'productQty',
                typeObj: {
                  type: 'onlyNumbers'
                }
              }),
              (v) => {
                let result = true;
                if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
                return result;
              }
            ),
          unitPrice: Yup.string()
            .test(
              'products.index.unitPrice',
              sharedFormErr({
                key: 'productPrice'
              }),
              (v, ctx) => {
                let result = true;
                if (!v && (ctx?.parent?.name || ctx?.parent?.quantity))
                  result = false;
                return result;
              }
            )
            .test(
              'products.index.unitPrice',
              sharedFormErr({
                key: 'productPrice',
                typeObj: {
                  type: 'onlyNumbers'
                }
              }),
              (v) => {
                let result = true;
                if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
                return result;
              }
            ),
          addon: Yup.array()
            .notRequired()
            .of(
              Yup.object().shape({
                name: Yup.string().test(
                  'products.i.addon.j.name',
                  sharedFormErr({
                    key: 'addonName'
                  }),
                  (v, ctx) => {
                    let result = true;
                    if (!v && ctx?.parent?.price) result = false;
                    return result;
                  }
                ),
                price: Yup.string()
                  .test(
                    'products.i.addon.j.price',
                    sharedFormErr({
                      key: 'addonPrice'
                    }),
                    (v, ctx) => {
                      let result = true;
                      if (!v && ctx?.parent?.name) result = false;
                      return result;
                    }
                  )
                  .test(
                    'products.i.addon.j.price',
                    sharedFormErr({
                      key: 'addonPrice',
                      typeObj: {
                        type: 'onlyNumbers'
                      }
                    }),
                    (v) => {
                      let result = true;
                      if (v && !checkIfNumberWithDecimalPoint(v))
                        result = false;
                      return result;
                    }
                  )
              })
            ),
          sizes: Yup.array()
            .notRequired()
            .of(
              Yup.object().shape({
                size: Yup.string().test(
                  'products.i.sizes.j.size',
                  sharedFormErr({
                    key: 'sizeName'
                  }),
                  (v, ctx) => {
                    let result = true;
                    if (!v && ctx?.parent?.price) result = false;
                    return result;
                  }
                ),
                price: Yup.string()
                  .test(
                    'products.i.sizes.j.price',
                    sharedFormErr({
                      key: 'sizePrice'
                    }),
                    (v, ctx) => {
                      let result = true;
                      if (!v && ctx?.parent?.size) result = false;
                      return result;
                    }
                  )
                  .test(
                    'products.i.sizes.j.price',
                    sharedFormErr({
                      key: 'sizePrice',
                      typeObj: {
                        type: 'onlyNumbers'
                      }
                    }),
                    (v) => {
                      let result = true;
                      if (v && !checkIfNumberWithDecimalPoint(v))
                        result = false;
                      return result;
                    }
                  )
              })
            )
        })
      )
  };
};

const getFormSchema = ({ fetchedObj, t }) => {
  if (fetchedObj) {
    return Yup.object().shape({
      ...sharedSchema(t)
    });
  }
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

export default getFormSchema;
