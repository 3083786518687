import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allCarYearsAction,
  singleCarYearAction,
  deleteCarYearAction,
  createCarYearAction,
  updateCarYearAction
} from './carsYearsSliceThunkApis';

export function getCarsYears({ builder }) {
  const { pending, fulfilled, rejected } = allCarYearsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingCarYears = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingCarYears = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedCarsYears = data?.data;
        if (data?.pagination) sliceState.carYearsPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingCarYears = false;
    });
}
export function getSingleCarYear({ builder }) {
  const { pending, fulfilled, rejected } = singleCarYearAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleCarYear = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleCarYear = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleCarYear = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleCarYear = false;
    });
}
export function createCarYear({ builder }) {
  const { pending, fulfilled, rejected } = createCarYearAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarYearForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarYearForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchCarYearsCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarYearForm = false;
    });
}
export function updateCarYear({ builder }) {
  const { pending, fulfilled, rejected } = updateCarYearAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarYearForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarYearForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleCarYearCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarYearForm = false;
    });
}

export function deleteCarYear({ builder }) {
  const { pending, fulfilled, rejected } = deleteCarYearAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingCarYear = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingCarYear = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteCarYearModalOpened = false;
        sliceState.deleteMultiCarYearModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.fetchCarYearsCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingCarYear = false;
    });
}
