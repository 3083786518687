import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allCarBrandsApi,
  singleCarBrandApi,
  createCarBrandApi,
  updateCarBrandApi,
  deleteCarBrandApi,
  changeCarBrandStatusApi
} from '@apis/carBrandsApis';
import customApiRequest from '@helpers/customApiRequest';

export const allCarsBrandsAction = createAsyncThunk(
  'carsBrands/allCarsBrandsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allCarBrandsApi(filterObj),
      thunkAPI
    });
  }
);

export const singleCarsBrandsAction = createAsyncThunk(
  'carsBrands/singleCarsBrandsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleCarBrandApi(filterObj),
      thunkAPI
    });
  }
);

export const createCarBrandAction = createAsyncThunk(
  'carsBrands/createCarBrandAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCarBrandApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateCarBrandAction = createAsyncThunk(
  'carsBrands/updateCarBrandAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateCarBrandApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeCarsBrandsStatusAction = createAsyncThunk(
  'carsBrands/changeCarsBrandsStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeCarBrandStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const changeSingleCarBrandStatusAction = createAsyncThunk(
  'carsBrands/changeSingleCarBrandStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeCarBrandStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteCarBrandsAction = createAsyncThunk(
  'carsBrands/deleteCarBrandsAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCarBrandApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deleteSingleCarBrandAction = createAsyncThunk(
  'carsBrands/deleteSingleCarBrandAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCarBrandApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
