import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allCarYearsApi,
  singleCarYearApi,
  createCarYearApi,
  updateCarYearApi,
  deleteCarYearApi
} from '@apis/carYearsApis';
import customApiRequest from '@helpers/customApiRequest';

export const allCarYearsAction = createAsyncThunk(
  'carYears/allCarYearsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allCarYearsApi(filterObj),
      thunkAPI
    });
  }
);

export const singleCarYearAction = createAsyncThunk(
  'carYears/singleCarYearAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleCarYearApi(filterObj),
      thunkAPI
    });
  }
);

export const createCarYearAction = createAsyncThunk(
  'carYears/createCarYearAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCarYearApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateCarYearAction = createAsyncThunk(
  'carYears/updateCarYearAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateCarYearApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteCarYearAction = createAsyncThunk(
  'carYears/deleteCarYearAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCarYearApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
