/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { checkIfNumberWithDecimalPoint } from '@helpers/checkValidNumber';
import * as Yup from 'yup';
import { updateDeliveryMethodsAction } from '@redux/delivery-methods-slice/sliceActionsThunkApis';
import checkSuccessRes from '@helpers/checkSuccessRes';

const FixedPriceForm = ({ setModalOpened }) => {
  const { i18n } = useTranslation();
  const fetchedObj = useSelector(
    (store) => store.deliverMethods.fetchedDeliveryData
  );
  const isSubmittingForm = useSelector(
    (store) => store.deliverMethods.isUpdatingDeliveryData
  );

  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `calcDeliveryForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  const defaultValues = {
    fixedPrice: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        fixedPrice: Yup.string()
          .required(
            sharedFormErr({
              key: 'fixedPrice'
            })
          )
          .test(
            'fixedPrice',
            sharedFormErr({
              key: 'fixedPrice',
              typeObj: {
                type: 'onlyNumbers'
              }
            }),
            (v) => {
              let result = true;
              if (v && !checkIfNumberWithDecimalPoint(v)) result = false;
              return result;
            }
          )
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values
  useEffect(() => {
    if (fetchedObj) {
      setValue(
        'fixedPrice',
        fetchedObj?.fixedPrice || defaultValues.fixedPrice
      );
    }
  }, [fetchedObj]);

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`calcDeliveryForm.${key}.label`);

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('fixedPrice', data.fixedPrice);
    // formData.append('status', data.status);

    const action = await dispatch(
      updateDeliveryMethodsAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action.payload.res;
    if (checkSuccessRes(res)) {
      setModalOpened(false);
    }
  };

  return (
    <div className="car-brand-form-wrapper">
      <Form
        className="car-brand-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">{t('fixedPriceMethods')}</div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="fixedPrice"
              type="text"
              errorMsg={errors?.fixedPrice?.message}
              validateStatus={errors?.fixedPrice ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('fixedPrice')}</p>
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {watch('fixedPrice') && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={() => {
                      setModalOpened(false);
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm
                ? t('saving')
                : fetchedObj
                ? t('edit')
                : t('save')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FixedPriceForm;
