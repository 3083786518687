import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useSingleRegion from '@customHooks/useSingleRegion';
import RegionForm from './region-form';

const NewRegionPage = () => {
  const params = useParams();
  const isLoadingSingleRegion = useSelector(
    (store) => store.regions.isLoadingSingleRegion
  );
  const isSubmittingRegionForm = useSelector(
    (store) => store.regions.isSubmittingRegionForm
  );
  const fetchedSingleRegion = useSelector(
    (store) => store.regions.fetchedSingleRegion
  );

  // get single region
  useSingleRegion();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleRegion && params?.region_id
            ? `${t('update')} ${fetchedSingleRegion?.name}` ||
              `${
                fetchedSingleRegion?.id ? `#${fetchedSingleRegion.id}` : ''
              }` ||
              ' '
            : t('newRegion')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: <Link to={appRouterLinks.regions}>{t('regions')}</Link>
          },
          {
            title:
              fetchedSingleRegion && params?.region_id
                ? fetchedSingleRegion?.name ||
                  `${
                    fetchedSingleRegion?.id ? `#${fetchedSingleRegion.id}` : ''
                  }` ||
                  ' '
                : t('newRegion')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-region-page">
        <SharedPageHeader />

        <RegionForm
          fetchedObj={
            params?.region_id && fetchedSingleRegion
              ? fetchedSingleRegion
              : null
          }
          isSubmittingForm={isSubmittingRegionForm}
        />
      </div>

      {(isSubmittingRegionForm || isLoadingSingleRegion) && <LoadingModal />}
    </PageWrapperMotion>
  );
};

export default NewRegionPage;
