import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deliveryMethodsApi,
  updateDeliveryMethodsApi,
  priceRangeMethodApi,
  singlePriceRangeApi,
  createPriceRangeApi,
  updatePriceRangeApi,
  deletePriceRangeApi,
  allWithdrawalApi,
  acceptOrRejectWithdrawalApi,
  allWalletTransactionsApi,
  createWalletTransactionsApi,
  getDriversBankAccountApi
} from '@apis/delivery-methods-apis';
import customApiRequest from '@helpers/customApiRequest';

const customReq = ({ actionName, actionApi }) => {
  return createAsyncThunk(
    `/delivery/${actionName}`,
    async ({ filterObj, dataToBeSubmitted }, thunkAPI) => {
      if (filterObj)
        return customApiRequest({
          apiCall: await actionApi(filterObj),
          thunkAPI
        });
      else if (dataToBeSubmitted) {
        return customApiRequest({
          apiCall: await actionApi(dataToBeSubmitted),
          thunkAPI
        });
      }
      return customApiRequest({
        apiCall: await actionApi(),
        thunkAPI
      });
    }
  );
};

// Start method 1, 3
export const allDeliveryMethodsAction = createAsyncThunk(
  'delivery/allDeliveryMethodsAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await deliveryMethodsApi(),
      thunkAPI
    });
  }
);

export const updateDeliveryMethodsAction = createAsyncThunk(
  'delivery/updateDeliveryMethodsAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateDeliveryMethodsApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const activateDeliveryMethodsAction = createAsyncThunk(
  'delivery/activateDeliveryMethodsAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateDeliveryMethodsApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// * End method 1, 3
///////////

/////////////
// * Start method 2
export const getPriceRangeMethodAction = createAsyncThunk(
  'delivery/getPriceRangeMethodAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await priceRangeMethodApi(),
      thunkAPI
    });
  }
);
export const getSinglePriceRangeMethodAction = createAsyncThunk(
  'delivery/getSinglePriceRangeMethodAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singlePriceRangeApi(filterObj),
      thunkAPI
    });
  }
);
export const createPriceRangeMethodAction = createAsyncThunk(
  'delivery/createPriceRangeMethodAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createPriceRangeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updatePriceRangeMethodAction = createAsyncThunk(
  'delivery/updatePriceRangeMethodAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updatePriceRangeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deletePriceRangeMethodAction = createAsyncThunk(
  'delivery/deletePriceRangeMethodAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deletePriceRangeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// * Ene method 2
//////////////
// * Start withdrawal actions
export const getAllWithdrawalAction = createAsyncThunk(
  'delivery/getAllWithdrawalAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allWithdrawalApi(filterObj),
      thunkAPI
    });
  }
);
export const acceptOrRejectWithdrawalAction = createAsyncThunk(
  'delivery/acceptOrRejectWithdrawalAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await acceptOrRejectWithdrawalApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// * End withdrawal actions
////////////
// * Start wallet transactions actions
export const getAllWalletTransactionsAction = createAsyncThunk(
  'delivery/getAllWalletTransactionsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allWalletTransactionsApi(filterObj),
      thunkAPI
    });
  }
);
export const createWalletTransactionAction = createAsyncThunk(
  'delivery/createWalletTransactionAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWalletTransactionsApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// * End wallet transactions actions
//////////////////
// get drivers bank account
export const getDriversBankAccountAction = createAsyncThunk(
  'delivery/getDriversBankAccountAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getDriversBankAccountApi(filterObj),
      thunkAPI
    });
  }
);
