import { useNavigate } from "react-router-dom";
import { removeCurrentUserAction } from "@redux/user-slice/userSlice";
import { useDispatch } from "react-redux";
import appRouterLinks from "@routes/appRouterLinks";
import checkSuccessRes from "@helpers/checkSuccessRes";

const checkUnAuthenticated = (err) => {
  return err?.response?.status === 401;
};

const useCustomApiRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customApiRequest = async (req, successCallback, errorCallback) => {
    try {
      const res = await req;
      // console.log('custom res : ', res);
      if (checkSuccessRes(res)) {
        successCallback &&
          typeof successCallback === "function" &&
          successCallback(res);
      }
    } catch (error) {
      if (errorCallback && typeof errorCallback === "function") {
        if (checkUnAuthenticated(error)) {
          dispatch(removeCurrentUserAction());
          navigate(appRouterLinks?.login);
        }
        errorCallback(error);

        // console.log('err : ', error?.response);
        // console.log('err req : ', error?.request);
        // console.log('err config : ', error?.config);
      }
    }
  };

  return customApiRequest;
};

export default useCustomApiRequest;
