import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
// import useSingleCountry from '@customHooks/useSingleCountry';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Link, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import NewOrderForm from './new-order-form';
import LoadingModal from '@components/shared/loading-modal';
import './styles.scss';

const NewOrderPage = () => {
  const params = useParams();
  const isLoadingSingleOrder = useSelector(
    (store) => store.orders.isLoadingSingleOrder
  );
  const isSubmittingOrder = useSelector(
    (store) => store.orders.isSubmittingOrder
  );
  const fetchedSingleOrder = useSelector(
    (store) => store.orders.fetchedSingleOrder
  );

  // useSingleCountry();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleOrder && params?.order_id
            ? `${t('update')} ${fetchedSingleOrder?.name}` ||
              `${fetchedSingleOrder?.id ? `#${fetchedSingleOrder.id}` : ''}` ||
              ' '
            : t('newOrder')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: <Link to={appRouterLinks.orders}>{t('orders')}</Link>
          },
          {
            title:
              fetchedSingleOrder && params?.order_id
                ? fetchedSingleOrder?.name ||
                  `${
                    fetchedSingleOrder?.id ? `#${fetchedSingleOrder.id}` : ''
                  }` ||
                  ' '
                : t('newOrder')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-order-page">
        <SharedPageHeader />

        <NewOrderForm
          fetchedObj={params?.order_id ? fetchedSingleOrder : null}
          isSubmittingForm={isSubmittingOrder}
        />
      </div>

      {(isSubmittingOrder || isLoadingSingleOrder) && <LoadingModal />}
    </PageWrapperMotion>
  );
};

export default NewOrderPage;
