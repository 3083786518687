/* eslint-disable eqeqeq */
import appRouterLinks from '@routes/appRouterLinks';
import { Link } from 'react-router-dom';
import CustomTag from '../../../components/shared/custom-tag';
import { walletTransactionsTypesArr } from '../../../mocks';

const TableColumns = ({
  setSelectedTableRow,
  setDeleteModalOpened,
  t,
  navigate
}) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.amount ? `${record.amount} ${t('currency.sar')}` : '---'}
          </div>
        );
      }
    },
    {
      title: t('type'),
      dataIndex: 'type',
      width: 152,
      render: (_, record) => {
        return record?.type ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CustomTag
              status={record?.type}
              arr={walletTransactionsTypesArr(t)}
            />
          </div>
        ) : (
          '---'
        );
      }
    },
    {
      title: t('driver'),
      dataIndex: 'driver.name',
      width: 152,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.user?.name && record?.user?.id ? (
              <Link
                style={{
                  textDecoration: 'underline'
                }}
                to={appRouterLinks.driverDetails(record?.user?.id)}
              >
                {record.user.name}
              </Link>
            ) : (
              '----'
            )}
          </div>
        );
      }
    },
    {
      title: t('wallet'),
      dataIndex: 'wallet',
      width: 212,
      render: (_, record) => {
        return record?.user?.profits
          ? `${record.user.profits} ${t('currency.sar')}`
          : '---';
      }
    },
    {
      title: t('requiredToBeRepaid'),
      dataIndex: 'requiredToBeRepaid',
      width: 222,
      render: (_, record) => {
        return record?.user?.requiredToBeRepaid
          ? `${record.user.requiredToBeRepaid} ${t('currency.sar')}`
          : '---';
      }
    },
    {
      title: t('date'),
      dataIndex: 'date',
      width: 122,
      render: (_, record) => {
        return record?.date ? record.date : '---';
      }
    },
    {
      title: t('desc_ar'),
      dataIndex: 'desc_ar',
      width: 332,
      render: (_, record) => {
        return record?.desc_ar ? record.desc_ar : '---';
      }
    },
    {
      title: t('desc_en'),
      dataIndex: 'desc_en',
      width: 332,
      render: (_, record) => {
        return record?.desc_en ? record.desc_en : '---';
      }
    }
  ];
};

export default TableColumns;
