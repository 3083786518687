/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { PlusIcon, WalletIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button } from 'antd';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import WalletTransactionsTable from './transactions-table';
import TableFilter from './transactions-table/table-filter';

const WalletTransactionsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="wallet-transactions-page">
      <PageHeader
        title={t('walletTransactions')}
        icon={<WalletIcon />}
        breadcrumbItems={[
          {
            title: t('walletTransactions')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.createWalletTransaction);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('createWalletTransaction')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <TableFilter />
        </div>
      </div>
      <WalletTransactionsTable />
    </div>
  );
};

export default WalletTransactionsPage;
