/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button } from 'antd';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import CarModelsTable from './car-models-table';
import TableFilter from './car-models-table/table-filter';
import {
  setDeleteMultiCarModelModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/cars-models-slice/carsModelsSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteAllBtn from '@components/delete-all-btn';

const CarModelsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedRowsIds = useSelector(
    (store) => store.carModels.selectedRowsIds
  );
  return (
    <div className="car-models-page">
      <PageHeader
        title={t('vehicleModels')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('vehicleModels')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.newVehicleModel);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addModel')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiCarModelModalOpenedAction(true))
            }
          />
          <TableFilter />
        </div>
      </div>
      <CarModelsTable />
    </div>
  );
};

export default CarModelsPage;
