import { Modal, Rate } from 'antd';
import { t } from 'i18next';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';
import FreeDriversForm from './free-drivers-form';

const FreeDriversModal = ({
  modalOpened,
  setModalOpened,
  order_id,
  isSubmittingForm,
  isLoadingModal,
  fetchedFreeDrivers
}) => {
  return (
    <Modal
      className="shared-custom-modal rates-modal"
      width="96%"
      style={{ maxWidth: '522px' }}
      title={t('assignDriver')}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
      destroyOnClose
    >
      {isLoadingModal ? (
        <div
          style={{
            minHeight: 192,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      ) : (
        <FreeDriversForm
          order_id={order_id}
          isSubmittingForm={isSubmittingForm}
          fetchedFreeDrivers={fetchedFreeDrivers}
        />
      )}
    </Modal>
  );
};

export default FreeDriversModal;
