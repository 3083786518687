import { motion } from 'framer-motion';

const PageWrapperMotion = ({ children, props }) => {
  return (
    <motion.div
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.7
        }
      }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default PageWrapperMotion;
