/* eslint-disable eqeqeq */
import CustomImage from '@components/shared/custom-image';
import { activeTypes, activeTypesArr } from '@mocks/index';
import { Button, Switch, Tooltip } from 'antd';
import { confirmModalTypesValues } from '@mocks/index';
import { TrashIcon, UpdateIcon } from '@icons/index';
import appRouterLinks from '../../../routes/appRouterLinks';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setPreviewNationalityImgModalOpened,
  setIsLoading,
  setConfirmModal,
  setDeleteModalOpened,
  token,
  t,
  dispatch,
  navigate
}) => {
  const handleToggleAgent = (record) => {
    if (record) {
      setSelectedTableRow(record);
      setConfirmModal({
        opened: true,
        type:
          record?.status == activeTypes?.active
            ? confirmModalTypesValues.errorModal
            : confirmModalTypesValues.successModal
      });
    }
  };

  const renderChangeStatusSwitch = (record) => {
    const found = activeTypesArr(t).find((obj) => obj?.value === record.status);
    if (found)
      return (
        <div className="action-btns-wrap">
          <Switch
            checked={record?.status === activeTypes?.active}
            onChange={(v, e) => {
              e.stopPropagation();
              e.preventDefault();
              handleToggleAgent(record);
            }}
            className={`affiliate-toggle ${
              record?.status === activeTypes?.notActive ? 'not-active' : ''
            }`}
          />
        </div>
      );
    return '---';
  };

  const renderUpdateBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('update')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            // to={`${appRouterLinks?.vehicleBrandDetails(record?.id)}`}
            className="action-btn view"
            onClick={(e) => {
              e.stopPropagation();
              navigate(appRouterLinks.editNationality(record.id));
            }}
          >
            <UpdateIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const renderDeleteBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('delete')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
            htmlType="button"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTableRow(record);
              setDeleteModalOpened(true);
            }}
          >
            <TrashIcon color="red" />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('photo'),
      dataIndex: 'photo',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell asset-img-row">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTableRow(record);
                setPreviewNationalityImgModalOpened(true);
              }}
              className="img-preview-btn"
            >
              <CustomImage src={record?.logo} className="asset-img" />
            </button>
          </div>
        );
      }
    },
    {
      title: t('nationalityEn'),
      dataIndex: 'nationalityEn',
      width: 182,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.name_en || '---'}</div>
        );
      }
    },
    {
      title: t('nationalityAr'),
      dataIndex: 'nationalityAr',
      width: 182,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.name_ar || '---'}</div>
        );
      }
    },
    {
      title: t('update'),
      dataIndex: 'update',
      with: 62,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderUpdateBtn(record)}</div>
        );
      }
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderDeleteBtn(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
