/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import {
  setDeleteMultiCarYearModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/cars-years-slice/carsYearsSlice';
import appRouterLinks from '@routes/appRouterLinks';
import { Button } from 'antd';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteAllBtn from '@components/delete-all-btn';
import YearsTable from './years-table';
import TableFilter from './years-table/table-filter';
import './styles.scss';

const CarYearsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedRowsIds = useSelector(
    (store) => store.carYears.selectedRowsIds
  );

  return (
    <div className="years-page">
      <PageHeader
        title={t('vehicleYears')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('vehicleYears')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.newVehicleYear);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addYear')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiCarYearModalOpenedAction(true))
            }
          />
          <TableFilter />
        </div>
      </div>
      <YearsTable />
    </div>
  );
};

export default CarYearsPage;
