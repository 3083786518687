import CustomImage from '@components/shared/custom-image';
import CustomTag from '@components/shared/custom-tag';
import { carTypesArr, driverStatusArr } from '@mocks/index';
import { Tooltip } from 'antd';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setPreviewDriverImgModalOpened,
  setPreviewFrontCarImgModalOpened,
  setPreviewBackImgModalOpened,
  setIsLoading,
  setConfirmModal,
  token,
  t
}) => {
  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <Tooltip placement="top" title={t('details')}>
            <div
              // to={`${appRouterLinks?.orderDetails(record?.id)}`}
              className="action-btn view"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                // width="24"
                height="24"
                fill="#196c10"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('photo'),
      dataIndex: 'photo',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell asset-img-row">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTableRow(record);
                setPreviewDriverImgModalOpened(true);
              }}
              className="img-preview-btn"
            >
              <CustomImage src={record?.image} className="asset-img" />
            </button>
          </div>
        );
      }
    },
    {
      title: t('driverName'),
      dataIndex: 'driverName',
      width: 132,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.name || '---'}</div>;
      }
    },
    {
      title: t('driverStatus'),
      dataIndex: 'driverStatus',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.status ? (
              <CustomTag status={record?.status} arr={driverStatusArr(t)} />
            ) : (
              '---'
            )}
          </div>
        );
      }
    },
    {
      title: t('idNumber'),
      dataIndex: 'idNumber',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.idNumber || '---'}</span>
          </div>
        );
      }
      // sorter: (a, b) => {
      //   let nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   let nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // }
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.phone || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('birthDate'),
      dataIndex: 'birthDate',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.birthDate || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('carType'),
      dataIndex: 'carType',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.carData?.carType ? (
              <CustomTag
                status={record?.carData?.carType}
                arr={carTypesArr(t)}
              />
            ) : (
              '---'
            )}
          </div>
        );
      }
    },
    {
      title: t('carMark'),
      dataIndex: 'carMark',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.carData?.brand?.name || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('carModel'),
      dataIndex: 'carModel',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.carData?.carModel?.name || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('carYear'),
      dataIndex: 'carYear',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.carData?.year?.name || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('plateNumbers'),
      dataIndex: 'plateNumbers',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.carData?.plateNumbers || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('plateLetters'),
      dataIndex: 'plateLetters',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.carData?.plateLetters || '---'}</span>
          </div>
        );
      }
    },
    {
      title: t('carFrontImg'),
      dataIndex: 'carFrontImg',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell asset-img-row">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTableRow(record);
                setPreviewFrontCarImgModalOpened(true);
              }}
              className="img-preview-btn"
            >
              <CustomImage
                src={record?.carData?.carImageFront}
                className="car-front-img"
              />
            </button>
          </div>
        );
      }
    },
    {
      title: t('carBackImg'),
      dataIndex: 'carBackImg',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell asset-img-row">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTableRow(record);
                setPreviewBackImgModalOpened(true);
              }}
              className="img-preview-btn"
            >
              <CustomImage
                src={record?.carData?.carImageBack}
                className="car-back-img"
              />
            </button>
          </div>
        );
      }
    },

    {
      title: t('carSerialNumber'),
      dataIndex: 'carSerialNumber',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.carData?.serialNumber || '---'}</span>
          </div>
        );
      }
    },

    {
      title: t('nationality'),
      dataIndex: 'nationality',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.nationality?.name || '---'}
          </div>
        );
      }
    },
    {
      title: t('country'),
      dataIndex: 'country',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.country?.name || '---'}
          </div>
        );
      }
    },
    {
      title: t('city'),
      dataIndex: 'city',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.city?.name || '---'}</div>
        );
      }
    },
    {
      title: t('region'),
      dataIndex: 'region',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.region?.name || '---'}
          </div>
        );
      }
    },
    {
      title: t('mangerName'),
      dataIndex: 'mangerName',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.managerName || '---'}
          </div>
        );
      }
    },

    {
      title: t('details'),
      dataIndex: 'details',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
