/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { singleCountryAction } from '@redux/countries-slice/countriesSliceThunkApis';

const useSingleCountry = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const fetchSingleCountryCount = useSelector(
    (store) => store.countries.fetchSingleCountryCount
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && params?.country_id) {
      dispatch(
        singleCountryAction({
          filterObj: {
            country_id: params?.country_id || '',
            ...values
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSingleCountryCount, search, i18n.language]);
};

export default useSingleCountry;
