import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useSingleRangeMethod from '@customHooks/useSingleRangeMethod';
import RangeMethodForm from './range-method-form';

const CreateRangeMethodPage = () => {
  const params = useParams();
  const isLoadingSingleRangeMethod = useSelector(
    (store) => store.deliverMethods.isLoadingSingleRangeMethod
  );
  const isSubmittingRangeForm = useSelector(
    (store) => store.deliverMethods.isSubmittingRangeForm
  );
  const fetchedSingleRangeMethod = useSelector(
    (store) => store.deliverMethods.fetchedSingleRangeMethod
  );

  // * get single range method
  useSingleRangeMethod();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleRangeMethod && params?.price_id
            ? `${
                fetchedSingleRangeMethod?.id
                  ? `#${fetchedSingleRangeMethod.id}`
                  : ''
              }` || ' '
            : t('createPriceRange')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.deliveryCalcMethods}>
                {t('deliveryCalcMethods')}
              </Link>
            )
          },
          {
            title: (
              <Link to={appRouterLinks.rangeMethodRoute}>
                {t('priceRangeMethod')}
              </Link>
            )
          },
          {
            title:
              fetchedSingleRangeMethod && params?.price_id
                ? fetchedSingleRangeMethod?.name ||
                  `${
                    fetchedSingleRangeMethod?.id
                      ? `#${fetchedSingleRangeMethod.id}`
                      : ''
                  }` ||
                  ' '
                : t('createPriceRange')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="create-range-method-page">
        <SharedPageHeader />

        <RangeMethodForm
          fetchedObj={params?.price_id ? fetchedSingleRangeMethod : null}
          isSubmittingForm={isSubmittingRangeForm}
        />
      </div>

      {(isSubmittingRangeForm || isLoadingSingleRangeMethod) && (
        <LoadingModal />
      )}
    </PageWrapperMotion>
  );
};

export default CreateRangeMethodPage;
