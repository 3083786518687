import { createContext, useState } from "react";
// import { useNavigate } from 'react-router-dom';

const INITIAL_VALUES = {
  isSubmitting: false,
  setIsSubmitting: (v) => {},
  forgetPasswordModalOpened: false,
  setForgetPasswordModalOpened: (v) => {},
  forgetPasswordFormEnterEmailAppended: true,
  setForgetPasswordFormEnterEmailAppended: (v) => {},
  forgetPasswordFormEnterCodeAppended: false,
  setForgetPasswordFormEnterCodeAppended: (v) => {},
  forgetPasswordFormResetPasswordAppended: false,
  setForgetPasswordFormResetPasswordAppended: (v) => {},
  successLock: false,
  setSuccessLock: (v) => {},
  user: localStorage.getItem("forgetPasswordUser")
    ? JSON.parse(localStorage.getItem("forgetPasswordUser"))
    : null,
  setUser: (v) => {},
  resetContext: () => {},
};

const ForgetPasswordContext = createContext(INITIAL_VALUES);

export const ForgetPasswordProvider = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(
    INITIAL_VALUES?.isSubmitting
  );
  const [forgetPasswordModalOpened, setForgetPasswordModalOpened] = useState(
    INITIAL_VALUES.forgetPasswordModalOpened
  );
  const [
    forgetPasswordFormEnterEmailAppended,
    setForgetPasswordFormEnterEmailAppended,
  ] = useState(INITIAL_VALUES.forgetPasswordFormEnterEmailAppended);
  const [
    forgetPasswordFormEnterCodeAppended,
    setForgetPasswordFormEnterCodeAppended,
  ] = useState(INITIAL_VALUES.forgetPasswordFormEnterCodeAppended);
  const [
    forgetPasswordFormResetPasswordAppended,
    setForgetPasswordFormResetPasswordAppended,
  ] = useState(INITIAL_VALUES.forgetPasswordFormResetPasswordAppended);
  const [successLock, setSuccessLock] = useState(INITIAL_VALUES.successLock);
  const [user, setUser] = useState(INITIAL_VALUES.user);
  const resetContext = () => {
    setForgetPasswordFormEnterEmailAppended(true);
    setForgetPasswordFormEnterCodeAppended(false);
    setForgetPasswordFormResetPasswordAppended(false);
    setUser(null);
    // navigate(routerLinks?.signinPage);
  };
  return (
    <ForgetPasswordContext.Provider
      value={{
        isSubmitting,
        setIsSubmitting,
        forgetPasswordModalOpened,
        setForgetPasswordModalOpened,
        forgetPasswordFormEnterEmailAppended,
        setForgetPasswordFormEnterEmailAppended,
        forgetPasswordFormEnterCodeAppended,
        setForgetPasswordFormEnterCodeAppended,
        forgetPasswordFormResetPasswordAppended,
        setForgetPasswordFormResetPasswordAppended,
        successLock,
        setSuccessLock,
        user,
        setUser: (v) => {
          if (v) localStorage.setItem("forgetPasswordUser", JSON.stringify(v));
          else localStorage.removeItem("forgetPasswordUser");
          setUser(v);
        },
        resetContext,
      }}
    >
      {children}
    </ForgetPasswordContext.Provider>
  );
};

export default ForgetPasswordContext;
