/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import CarBrandsTable from './car-brands-table';
import TableFilter from './car-brands-table/table-filter';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';
import { useDispatch, useSelector } from 'react-redux';
import DeleteAllBtn from '@components/delete-all-btn';
import {
  setSelectedRowsIdsAction,
  setDeleteMultiCarBrandModalOpenedAction
} from '@redux/cars-brands-slice/carsBrandsSlice';

const CarBrandsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedRowsIds = useSelector(
    (store) => store.carBrands.selectedRowsIds
  );

  return (
    <div className="car-brands-page">
      <PageHeader
        title={t('vehicleBrands')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('vehicleBrands')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.newVehicleBrand);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addBrand')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiCarBrandModalOpenedAction(true))
            }
          />
          <TableFilter />
        </div>
      </div>
      <CarBrandsTable />
    </div>
  );
};

export default CarBrandsPage;
