/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import LoadingModal from '@components/shared/loading-modal';
import { getAllWalletTransactionsAction } from '@redux/wallet-transactions-slice/sliceActionsThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import TableColumns from './TableColumns';

const WalletTransactionsTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingWalletTransaction = useSelector(
    (store) => store.walletTransactions.isLoadingWalletTransaction
  );
  const isSubmittingWalletTransaction = useSelector(
    (store) => store.walletTransactions.isSubmittingWalletTransaction
  );
  const fetchedWalletTransactionCount = useSelector(
    (store) => store.walletTransactions.fetchedWalletTransactionCount
  );
  const fetchedWalletTransactions = useSelector(
    (store) => store.walletTransactions.fetchedWalletTransactions
  );
  const walletTransactionsPagination = useSelector(
    (store) => store.walletTransactions.walletTransactionsPagination
  );
  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        getAllWalletTransactionsAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchedWalletTransactionCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedWalletTransactions?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedWalletTransactions);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchedWalletTransactionCount,
    fetchedWalletTransactions,
    fetchedWalletTransactions?.length
  ]);

  return (
    <>
      <AntdTable
        bordered
        width={1200}
        scroll={1500}
        showHeader={true}
        className={`car-years-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedWalletTransactions}
        isLoadingTableData={isLoadingWalletTransaction}
        user={user}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={walletTransactionsPagination}
        baseTablePageUrl={`${appRouterLinks.walletTransactionsRoute}?type=${
          values?.type || ''
        }`}
      />

      {isSubmittingWalletTransaction && <LoadingModal />}
    </>
  );
};

export default WalletTransactionsTable;
