import { createAsyncThunk } from '@reduxjs/toolkit';
import customApiRequest from '@helpers/customApiRequest';
import {
  allNationalitiesApi,
  changeNationalityStatusApi,
  createNationalityApi,
  deleteNationalityApi,
  singleNationalityApi,
  updateNationalityApi
} from '@apis/nationalitiesApis';

export const allNationalitiesAction = createAsyncThunk(
  'nationalities/allNationalitiesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allNationalitiesApi(filterObj),
      thunkAPI
    });
  }
);

export const singleNationalityAction = createAsyncThunk(
  'nationalities/singleNationalityAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleNationalityApi(filterObj),
      thunkAPI
    });
  }
);

export const createNationalityAction = createAsyncThunk(
  'nationalities/createNationalityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createNationalityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateNationalityAction = createAsyncThunk(
  'nationalities/updateNationalityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateNationalityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeNationalityStatusAction = createAsyncThunk(
  'nationalities/changeNationalityStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeNationalityStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const changeSingleNationalityStatusAction = createAsyncThunk(
  'nationalities/changeSingleNationalityStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeNationalityStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteNationalityAction = createAsyncThunk(
  'nationalities/deleteNationalityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteNationalityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deleteSingleNationalityAction = createAsyncThunk(
  'nationalities/deleteSingleNationalityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteNationalityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
