/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import CustomMap from '@components/custom-map';
import AntdTextarea from '@components/shared/antd-form-components/AntdTextarea';
import { Rate } from 'antd';
import { useTranslation } from 'react-i18next';
import SharedOrderTextField from './SharedOrderTextField';
import './styles.scss';

const StoreFormFields = ({
  control,
  setValue,
  errors,
  sharedFormLabel,
  watch,
  register,
  setR,
  selectedAddress,
  setSelectedAddress,
  selectedLocation,
  setSelectedLocation
}) => {
  const { i18n } = useTranslation();

  return (
    <div className="store-data-fields-wrapper">
      <div className="fields-wrapper">
        <SharedOrderTextField
          name="storeData.name"
          transName="storeName"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="storeData.category"
          transName="storeCategory"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="storeData.phone"
          transName="storePhone"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="storeData.logo"
          transName="storeLogo"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
          errorMsg={
            errors.storeData &&
            errors.storeData.logo &&
            errors.storeData.logo.message
          }
          validateStatus={
            errors.storeData && errors.storeData.logo ? 'error' : ''
          }
        />
        <SharedOrderTextField
          name="storeData.city"
          transName="storeCity"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="storeData.region"
          transName="storeRegion"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />
        <SharedOrderTextField
          name="storeData.district"
          transName="storeDistrict"
          control={control}
          setValue={setValue}
          errors={errors}
          sharedFormLabel={sharedFormLabel}
          sm
        />

        <div
          style={{
            gap: 18
          }}
          className="store-rate-wrapper"
        >
          <p className="rate-label">{sharedFormLabel('storeRate')}</p>
          <Rate
            className="rate-store"
            allowClear={false}
            allowHalf={false}
            onChange={(v) => {
              setR(v);
            }}
          />
        </div>

        <div className={`custom-text-area-wrapper store-note ${i18n.dir()}`}>
          <AntdTextarea
            rows={6}
            className="custom-text-area"
            name="storeData.note"
            errorMsg={errors['storeData.note']?.message}
            validateStatus={errors['storeData.note'] ? 'error' : ''}
            control={control}
          />
          <p className="custom-label">{sharedFormLabel('storeNote')}</p>
        </div>

        <div className="map-wrap">
          {/* <img src={mapImg} alt="map" /> */}
          <CustomMap
            width="100%"
            height="322px"
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isDraggable={true}
            hasAutoComplete={true}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreFormFields;
