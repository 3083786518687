import axios from 'axios';
import apisUrls from './apisUrls';

export const allCarYearsApi = async (filterObj) => {
  return axios.get(apisUrls.carYears(filterObj));
};

export const singleCarYearApi = async (filterObj) => {
  return axios.get(apisUrls.singleCarYear(filterObj));
};

export const createCarYearApi = async (values) => {
  return axios.post(apisUrls.createCarYear, values);
};

export const updateCarYearApi = async (values) => {
  return axios.post(apisUrls.updateCarYear, values);
};

export const deleteCarYearApi = async (values) => {
  return axios.post(apisUrls.deleteCarYear, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
