/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import ConfirmModal from '@components/shared/confirm-modal';
import CustomTag from '@components/shared/custom-tag';
import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import { db } from '@fb';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { ShoppingCartIcon } from '@icons/index';
import {
  confirmModalTypesValues,
  isSpecialArr,
  orderStatusArr,
  orderStatusValues,
  paymentTypesArr
} from '@mocks/index';
import {
  setAssignDriverModalOpenedAction,
  setConfirmModalAction,
  setDeleteOrderModalOpenedAction,
  setFetchedSingleOrderAction
} from '@redux/orders-slice/ordersSlice';
import {
  changeSingleOrderStatusAction,
  deleteSingleOrderAction,
  singleOrderAction
} from '@redux/orders-slice/ordersSliceThunkApi';
import appRouterLinks from '@routes/appRouterLinks';
import { Descriptions } from 'antd';
import { onValue, ref } from 'firebase/database';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomEmpty from '@components/shared/custom-empty';
import useFreeDrivers from '@customHooks/useFreeDrivers';
import CustomerInfo from './customer-info';
import DriverInfo from './driver-info';
import FreeDriversModal from './free-drivers-modal';
import OrderActions from './order-actions';
import ProductsInfo from './products-info';
import StoreInfo from './store-info';
import './styles.scss';

const SingleOrderPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  //
  const fetchedSingleOrder = useSelector(
    (store) => store.orders.fetchedSingleOrder
  );
  const fetchSingleOrderCount = useSelector(
    (store) => store.orders.fetchSingleOrderCount
  );
  const isLoadingSingleOrder = useSelector(
    (store) => store.orders.isLoadingSingleOrder
  );
  const confirmModalOpened = useSelector(
    (store) => store.orders.confirmModal.opened
  );
  const confirmModalType = useSelector(
    (store) => store.orders.confirmModal.type
  );
  const isSubmittingSingleOrder = useSelector(
    (store) => store.orders.isSubmittingSingleOrder
  );
  const deleteOrderModalOpened = useSelector(
    (store) => store.orders.deleteOrderModalOpened
  );
  const isLoadingOrderFreeDrivers = useSelector(
    (store) => store.orders.isLoadingOrderFreeDrivers
  );
  const fetchedFreeDrivers = useSelector(
    (store) => store.orders.fetchedFreeDrivers
  );
  const assignDriverModalOpened = useSelector(
    (store) => store.orders.assignDriverModalOpened
  );
  //

  const [firebaseData, setFirebaseData] = useState([]);
  const [driverLiveLocation, setDriverLiveLocation] = useState(null);

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);
    const getData = async () => {
      if (isMounted) {
        const action = await dispatch(
          singleOrderAction({
            filterObj: {
              order_id: params?.order_id,
              ...values
            }
          })
        );
        const res = action?.payload?.res;
        if (checkSuccessRes(res)) {
          const singleOrderRef = ref(db, `Orders/${params?.order_id}`);
          onValue(singleOrderRef, (snapShot) => {
            const data = snapShot.val();
            if (data) {
              setFirebaseData(data);
            }
          });
        }
      }
    };

    getData();

    return () => {
      isMounted = false;
    };
  }, [fetchSingleOrderCount, search, params?.order_id, i18n.language]);

  ////////////////////////////////
  ////////////////////////////////
  const getNewOrder = (dataBaseObj, firebaseObj) => {
    let newOrder = dataBaseObj ? { ...dataBaseObj } : null;
    if (dataBaseObj && Object.keys(firebaseData)?.length > 0) {
      newOrder = {
        ...newOrder,
        firebaseObj: { ...firebaseObj }
      };
    }

    return newOrder;
  };

  // * handle fetch single order
  useEffect(() => {
    dispatch(
      setFetchedSingleOrderAction(getNewOrder(fetchedSingleOrder, firebaseData))
    );
  }, [firebaseData]);

  //* handle fetch driver location
  useEffect(() => {
    if (
      fetchedSingleOrder?.driver?.id &&
      (fetchedSingleOrder?.firebaseObj?.status
        ? fetchedSingleOrder?.firebaseObj?.status !==
          orderStatusValues?.completed
        : fetchedSingleOrder?.status !== orderStatusValues?.completed)
    ) {
      const driverLiveLocationRef = ref(
        db,
        `usersLocation/${fetchedSingleOrder.driver.id}`
      );
      onValue(driverLiveLocationRef, (snapShot) => {
        const data = snapShot.val();
        if (data) {
          setDriverLiveLocation(data);
        }
      });
    } else setDriverLiveLocation(null);
  }, [JSON.stringify(fetchedSingleOrder)]);

  ///////////////////////////

  const [selectedStatus, setSelectedStatus] = useState('');
  let isMounted = true;
  const handleChangeSelectOption = (value) => {
    const foundStatus = orderStatusArr(t).find((obj) => obj?.value == value);
    setSelectedStatus(foundStatus);

    dispatch(
      setConfirmModalAction({
        opened: true,
        type:
          foundStatus?.value !== orderStatusValues?.rejected
            ? confirmModalTypesValues?.successModal
            : confirmModalTypesValues?.errorModal
      })
    );
  };

  const handleChangeStatus = () => {
    if (isMounted && fetchedSingleOrder?.id) {
      const formData = new FormData();
      formData.append('order_id', fetchedSingleOrder.id);
      formData.append('status', selectedStatus?.value);

      dispatch(
        changeSingleOrderStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const handleDelete = async () => {
    if (isMounted && fetchedSingleOrder?.id) {
      const formData = new FormData();
      formData.append('order_id', fetchedSingleOrder.id);

      const action = await dispatch(
        deleteSingleOrderAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action.payload.res;
      if (checkSuccessRes(res)) {
        navigate(appRouterLinks?.orders);
      }
    }
  };

  useFreeDrivers({
    order_id: params?.order_id || '',
    fetchCount: fetchedSingleOrder && JSON.stringify(fetchedSingleOrder)
  });

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={`${t('order')} ${
          fetchedSingleOrder?.name ||
          `${fetchedSingleOrder?.id ? `#${fetchedSingleOrder.id}` : ''}` ||
          ' '
        }`}
        icon={<ShoppingCartIcon />}
        breadcrumbItems={[
          {
            title: <Link to={appRouterLinks.drivers}>{t('orders')}</Link>
          },
          {
            title: `${
              fetchedSingleOrder?.name ||
              `${fetchedSingleOrder?.id ? `#${fetchedSingleOrder.id}` : ''}` ||
              ' '
            }`
          }
          // {
          //   title: t('details')
          // }
        ]}
      />
    );
  };

  if (isLoadingSingleOrder) {
    return (
      <>
        <SharedPageHeader />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300
          }}
        >
          <LoadingOutlined />
        </div>
      </>
    );
  } else if (!fetchedSingleOrder) {
    return (
      <>
        <SharedPageHeader />
        <CustomEmpty>
          <h2
            style={{
              margin: '18px auto',
              textAlign: 'center'
            }}
          >
            {t('empty')}
          </h2>
        </CustomEmpty>
      </>
    );
  } else if (fetchedSingleOrder)
    return (
      <PageWrapperMotion>
        <div className="single-order-page">
          <SharedPageHeader />

          <div className="page-content">
            <OrderActions
              status={
                fetchedSingleOrder?.firebaseObj?.status ||
                fetchedSingleOrder?.status
              }
              handleChangeSelectOption={handleChangeSelectOption}
              selectedStatus={selectedStatus}
              orderId={fetchedSingleOrder?.orderId}
            />

            <Descriptions size="middle" column={2} title={''}>
              {/* <Descriptions.Item label={t('orderId')}>
              {'#'} {fetchedSingleOrder?.orderId || '---'}
            </Descriptions.Item> */}
              <Descriptions.Item label={t('receiveStoreTime')}>
                {fetchedSingleOrder?.receiveStoreTime || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('acceptedTimeRestaurant')}>
                {fetchedSingleOrder?.acceptedTimeRestaurant || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('arrivedClientTime')}>
                {fetchedSingleOrder?.arrivedClientTime || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('arrivedStoreTime')}>
                {fetchedSingleOrder?.arrivedStoreTime || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('pickupTimeRestaurant')}>
                {fetchedSingleOrder?.pickupTimeRestaurant || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('completedTime')}>
                {fetchedSingleOrder?.completedTime || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('createdTime')}>
                {fetchedSingleOrder?.firebaseObj?.orderTime ||
                  fetchedSingleOrder?.createdTime ||
                  '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('isSpecial')}>
                {(
                  <CustomTag
                    status={fetchedSingleOrder?.isSpecial}
                    arr={isSpecialArr(t)}
                  />
                ) || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('note')}>
                {fetchedSingleOrder?.note || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('paymentType')}>
                {(
                  <CustomTag
                    status={fetchedSingleOrder?.paymentType}
                    arr={paymentTypesArr(t)}
                  />
                ) || '---'}
              </Descriptions.Item>
              <Descriptions.Item label={t('status')}>
                {(
                  <CustomTag
                    arr={orderStatusArr(t)}
                    status={
                      fetchedSingleOrder?.firebaseObj?.status ||
                      fetchedSingleOrder?.status
                    }
                  />
                ) || '---'}
              </Descriptions.Item>
            </Descriptions>

            {fetchedSingleOrder?.products?.length > 0 ? (
              <ProductsInfo products={fetchedSingleOrder?.products} />
            ) : null}

            <div className="prices-desc-wrapper">
              <div className="summary-title bold-font">{t('orderSummary')}</div>
              <Descriptions size="small" column={1} title={''} bordered>
                <Descriptions.Item label={t('productPrice')}>
                  {fetchedSingleOrder?.firebaseObj?.productPrice ||
                    fetchedSingleOrder?.productPrice ||
                    0}{' '}
                  {t('currency.sar')}
                </Descriptions.Item>
                <Descriptions.Item label={t('deliveryPrice')}>
                  {fetchedSingleOrder?.firebaseObj?.deliveryPrice ||
                    fetchedSingleOrder?.deliveryPrice ||
                    0}{' '}
                  {t('currency.sar')}
                </Descriptions.Item>
                <Descriptions.Item label={t('deliveryTaxPrice')}>
                  {fetchedSingleOrder?.firebaseObj?.deliveryTaxPrice ||
                    fetchedSingleOrder?.deliveryTaxPrice ||
                    0}{' '}
                  {t('currency.sar')}
                </Descriptions.Item>
                <Descriptions.Item label={t('taxProductPrice')}>
                  {fetchedSingleOrder?.firebaseObj?.taxProductPrice ||
                    fetchedSingleOrder?.taxProductPrice ||
                    0}{' '}
                  {t('currency.sar')}
                </Descriptions.Item>
                <Descriptions.Item label={t('totalPrice')}>
                  {fetchedSingleOrder?.firebaseObj?.totalPrice ||
                    fetchedSingleOrder?.totalPrice ||
                    0}{' '}
                  {t('currency.sar')}
                </Descriptions.Item>
              </Descriptions>
            </div>

            <CustomerInfo
              obj={fetchedSingleOrder?.clientData}
              firebaseObj={fetchedSingleOrder?.firebaseObj}
            />
            <DriverInfo
              obj={fetchedSingleOrder?.driver}
              firebaseObj={fetchedSingleOrder?.firebaseObj}
              driverLiveLocation={driverLiveLocation}
            />
            <StoreInfo
              obj={fetchedSingleOrder?.storeData}
              firebaseObj={fetchedSingleOrder?.firebaseObj}
            />
          </div>
        </div>

        <ConfirmModal
          modalOpened={confirmModalOpened}
          setModalOpened={(v) =>
            dispatch(
              setConfirmModalAction({
                opened: v,
                type: ''
              })
            )
          }
          handleAccept={handleChangeStatus}
          modalTitle=""
          modalType={confirmModalType}
          handleCancel={() => {
            const foundStatus = orderStatusArr(t).find(
              (obj) => obj?.value == fetchedSingleOrder?.status
            );
            setSelectedStatus(foundStatus);
          }}
        >
          <h3>
            {t('sureChangeOrderStatus')} {selectedStatus?.name}
          </h3>
        </ConfirmModal>

        <ConfirmModal
          modalOpened={deleteOrderModalOpened}
          setModalOpened={(v) => dispatch(setDeleteOrderModalOpenedAction(v))}
          handleAccept={handleDelete}
          modalTitle=""
          modalType={confirmModalTypesValues?.errorModal}
        >
          <h3>{t('sureDelete')}</h3>
        </ConfirmModal>

        <FreeDriversModal
          isSubmittingForm={isSubmittingSingleOrder}
          order_id={params?.order_id}
          modalOpened={assignDriverModalOpened}
          setModalOpened={(v) => dispatch(setAssignDriverModalOpenedAction(v))}
          isLoadingModal={isLoadingOrderFreeDrivers}
          fetchedFreeDrivers={fetchedFreeDrivers}
        />

        {isSubmittingSingleOrder && <LoadingModal />}
      </PageWrapperMotion>
    );

  return null;
};

export default SingleOrderPage;
