/* eslint-disable react-hooks/exhaustive-deps */
import { TrashIcon } from '../icons';

import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const DeleteAllBtn = ({
  selectedRowsIds,
  setSelectedRowsIds,
  onClickDelete
}) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    return () => {
      if (setSelectedRowsIds && typeof setSelectedRowsIds === 'function')
        setSelectedRowsIds('');
    };
  }, []);

  return (
    <AnimatePresence>
      {selectedRowsIds ? (
        <motion.button
          initial={{
            opacity: 0,
            translateX: i18n.dir() === 'ltr' ? -52 : 52
          }}
          animate={{
            opacity: 1,
            translateX: 0,
            transition: {
              duration: 0.5,
              type: 'spring'
            }
          }}
          type="button"
          className="delete-all-btn"
          onClick={() => {
            if (onClickDelete && typeof onClickDelete === 'function')
              onClickDelete();
          }}
        >
          <TrashIcon color="red" width={14} height={14} />
          {t('delete')}
        </motion.button>
      ) : null}
    </AnimatePresence>
  );
};

export default DeleteAllBtn;
