import axios from 'axios';
import apisUrls from './apisUrls';

export const allDriversApi = async (filterObj) => {
  return axios.get(apisUrls.drivers(filterObj));
};

export const singleDriverApi = async (filterObj) => {
  return axios.get(apisUrls.singleDriver(filterObj));
};

export const changeDriverStatusApi = async (values) => {
  return axios.post(apisUrls.changeDriverStatus, values);
};

export const deleteDriverApi = async (values) => {
  return axios.post(apisUrls.deleteDriver, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
