import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allNationalitiesAction,
  singleNationalityAction,
  changeNationalityStatusAction,
  changeSingleNationalityStatusAction,
  deleteNationalityAction,
  deleteSingleNationalityAction,
  createNationalityAction,
  updateNationalityAction
} from './nationalitiesSliceThunkApi';

export function getNationalities({ builder }) {
  const { pending, fulfilled, rejected } = allNationalitiesAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingNationalities = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingNationalities = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedNationalities = data?.data;
        if (data?.pagination)
          sliceState.nationalitiesPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingNationalities = false;
    });
}

export function getSingleNationality({ builder }) {
  const { pending, fulfilled, rejected } = singleNationalityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleNationality = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleNationality = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleNationality = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleNationality = false;
    });
}

export function createNationality({ builder }) {
  const { pending, fulfilled, rejected } = createNationalityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingNationalityForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingNationalityForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchNationalitiesCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingNationalityForm = false;
    });
}

export function updateNationality({ builder }) {
  const { pending, fulfilled, rejected } = updateNationalityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingNationalityForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingNationalityForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleNationalityCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingNationalityForm = false;
    });
}

export function changeNationalityStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeNationalityStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingNationalityStatus = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingNationalityStatus = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchNationalitiesCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingNationalityStatus = false;
    });
}

export function changeSingleNationalityStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleNationalityStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleNationality = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleNationality = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleNationalityCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleNationality = false;
    });
}

export function deleteNationality({ builder }) {
  const { pending, fulfilled, rejected } = deleteNationalityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingNationality = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingNationality = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteNationalityModalOpened = false;
        sliceState.selectedRowsIds = false;
        sliceState.deleteMultiNationalityModalOpened = false;
        sliceState.fetchNationalitiesCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingNationality = false;
    });
}

export function deleteSingleNationality({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleNationalityAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleNationality = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleNationality = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        // sliceState.fetchSingleNationalityCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleNationality = false;
    });
}
