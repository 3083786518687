import StatisticsCard from '@components/shared/statistics-card';
import { t } from 'i18next';
import {
  LineChartSolid,
  PieChartSolid,
  BarChartSolid,
  ArrowUpIcon,
  VehicleIcon,
  BubbleChartSolid
} from '@icons/index';
import './styles.scss';

const HomeOrdersStatistics = () => {
  return (
    <div className="home-orders-statistics">
      <StatisticsCard
        title="Active Orders"
        value={
          <div className="value-wrapper">
            <ArrowUpIcon color="green" />
            <p>34+</p>
          </div>
        }
        icon={<LineChartSolid />}
      />
      <StatisticsCard
        title="Orders"
        value={
          <div className="value-wrapper">
            <ArrowUpIcon color="green" />
            <p>8234+</p>
          </div>
        }
        icon={<BubbleChartSolid />}
      />
      <StatisticsCard
        title="Sales"
        value={
          <div className="value-wrapper">
            <ArrowUpIcon color="green" />
            <p>
              1934+ <span className="curr-span">{t('currency.sar')}</span>
            </p>
          </div>
        }
        icon={<PieChartSolid />}
      />
      <StatisticsCard
        title="Customers"
        value={
          <div className="value-wrapper">
            <ArrowUpIcon color="green" />
            <p>76123+</p>
          </div>
        }
        icon={<BarChartSolid />}
      />
      <StatisticsCard
        title="On Duty Drivers"
        value={
          <div className="value-wrapper">
            <ArrowUpIcon color="green" />
            <p>81+</p>
          </div>
        }
        icon={<VehicleIcon />}
      />
      <StatisticsCard
        title="Available Drivers"
        value={
          <div className="value-wrapper">
            <ArrowUpIcon color="green" />
            <p>241+</p>
          </div>
        }
        icon={<VehicleIcon />}
      />
    </div>
  );
};

export default HomeOrdersStatistics;
