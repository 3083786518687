/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import ImageModal from '@components/shared/image-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteNationalityModalOpenedAction,
  setPreviewNationalityImgModalOpened,
  setSelectedNationalityAction,
  setDeleteMultiNationalityModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/nationalities-slice/nationalitiesSlice';
import {
  allNationalitiesAction,
  changeNationalityStatusAction,
  deleteNationalityAction
} from '@redux/nationalities-slice/nationalitiesSliceThunkApi';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '@components/shared/loading-modal';
import './styles.scss';
import TableColumns from './TableColumns';

const NationalitiesTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingNationalities = useSelector(
    (store) => store.nationalities.isLoadingNationalities
  );
  const isSubmittingNationalityStatus = useSelector(
    (store) => store.nationalities.isSubmittingNationalityStatus
  );
  const isSubmittingNationality = useSelector(
    (store) => store.nationalities.isSubmittingNationality
  );
  const deleteNationalityModalOpened = useSelector(
    (store) => store.nationalities.deleteNationalityModalOpened
  );
  const fetchNationalitiesCount = useSelector(
    (store) => store.nationalities.fetchNationalitiesCount
  );
  const fetchedNationalities = useSelector(
    (store) => store.nationalities.fetchedNationalities
  );
  const nationalitiesPagination = useSelector(
    (store) => store.nationalities.nationalitiesPagination
  );
  const selectedNationality = useSelector(
    (store) => store.nationalities.selectedNationality
  );
  const confirmModalType = useSelector(
    (store) => store.nationalities.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.nationalities.confirmModal.opened
  );
  const previewNationalityImgModalOpened = useSelector(
    (store) => store.nationalities.previewNationalityImgModalOpened
  );
  const selectedRowsIds = useSelector(
    (store) => store.nationalities.selectedRowsIds
  );
  const deleteMultiNationalityModalOpened = useSelector(
    (store) => store.nationalities.deleteMultiNationalityModalOpened
  );

  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allNationalitiesAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchNationalitiesCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedNationalities?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedNationalities);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchNationalitiesCount,
    fetchedNationalities,
    fetchedNationalities?.length
  ]);

  let isMounted = true;
  const handleChangeStatus = () => {
    if (isMounted && selectedNationality?.id) {
      const formData = new FormData();
      formData.append('nationality_id', selectedNationality.id);
      dispatch(
        changeNationalityStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDelete = () => {
    if (isMounted && selectedNationality?.id) {
      const formData = new FormData();
      formData.append('nationality_id', selectedNationality.id);
      dispatch(
        deleteNationalityAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('nationality_id', selectedRowsIds);
      dispatch(
        deleteNationalityAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={600}
        showHeader={true}
        className={`nationalities-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedNationalities}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteNationalityModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedNationalityAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        setPreviewNationalityImgModalOpened={(v) =>
          dispatch(setPreviewNationalityImgModalOpened(v))
        }
        //
        isLoadingTableData={isLoadingNationalities}
        user={user}
        handleRowClick={(record) => {
          navigate(appRouterLinks.editNationality(record?.id));
        }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={nationalitiesPagination}
        baseTablePageUrl={`${appRouterLinks.nationalities}?searchText=${
          values?.searchText || ''
        }`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedNationalityAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeStatus}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>
            {`${t('sureActivate')}`}{' '}
            <span className="bold-font">
              {selectedNationality?.name || ''} !?
            </span>{' '}
          </h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>
            {`${t('sureDeactivate')}`}{' '}
            <span className="bold-font">
              {selectedNationality?.name || ''} !?
            </span>{' '}
          </h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteNationalityModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedNationalityAction(null));
          }
          dispatch(setDeleteNationalityModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">
            {selectedNationality?.name || ''} !?
          </span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiNationalityModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiNationalityModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {/* Preview image modals */}
      <ImageModal
        title={t('nationalityLogo')}
        open={previewNationalityImgModalOpened}
        onCancel={() => {
          dispatch(setSelectedNationalityAction(null));
          dispatch(setPreviewNationalityImgModalOpened(false));
        }}
        src={selectedNationality?.logo}
      />

      {/* Preview image modals */}

      {(isSubmittingNationalityStatus || isSubmittingNationality) && (
        <LoadingModal />
      )}
    </>
  );
};

export default NationalitiesTable;
