/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteCarModelModalOpenedAction,
  setPreviewModelImgModalOpened,
  setSelectedCarModelAction,
  setDeleteMultiCarModelModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/cars-models-slice/carsModelsSlice';
import {
  allCarModelsAction,
  deleteCarModelAction
} from '@redux/cars-models-slice/carsModelsSliceThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '../../../components/shared/loading-modal';
import './styles.scss';
import TableColumns from './TableColumns';

const CarModelsTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingCarModels = useSelector(
    (store) => store.carModels.isLoadingCarModels
  );
  const isSubmittingCarModel = useSelector(
    (store) => store.carModels.isSubmittingCarModel
  );
  const deleteCarModelModalOpened = useSelector(
    (store) => store.carModels.deleteCarModelModalOpened
  );
  const fetchCarModelsCount = useSelector(
    (store) => store.carModels.fetchCarModelsCount
  );
  const fetchedCarsModels = useSelector(
    (store) => store.carModels.fetchedCarsModels
  );
  const carModelsPagination = useSelector(
    (store) => store.carModels.carModelsPagination
  );
  const selectedCarModel = useSelector(
    (store) => store.carModels.selectedCarModel
  );
  const selectedRowsIds = useSelector(
    (store) => store.carModels.selectedRowsIds
  );
  const deleteMultiCarModelModalOpened = useSelector(
    (store) => store.carModels.deleteMultiCarModelModalOpened
  );
  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allCarModelsAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCarModelsCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedCarsModels?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedCarsModels);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCarModelsCount, fetchedCarsModels, fetchedCarsModels?.length]);

  let isMounted = true;
  const handleDelete = () => {
    if (isMounted && selectedCarModel?.id) {
      const formData = new FormData();
      formData.append('model_id', selectedCarModel.id);
      dispatch(
        deleteCarModelAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('model_id', selectedRowsIds);
      dispatch(
        deleteCarModelAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        width={1000}
        bordered
        // scroll={900}
        scroll={800}
        showHeader={true}
        className={`car-models-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedCarsModels}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteCarModelModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedCarModelAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        setPreviewModelImgModalOpened={(v) =>
          dispatch(setPreviewModelImgModalOpened(v))
        }
        //
        isLoadingTableData={isLoadingCarModels}
        user={user}
        // handleRowClick={(record) => {
        //   navigate(appRouterLinks.editVehicleModel(record?.id));
        // }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={carModelsPagination}
        baseTablePageUrl={`${appRouterLinks.vehiclesModels}?searchText=${
          values?.searchText || ''
        }`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteCarModelModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedCarModelAction(null));
          }
          dispatch(setDeleteCarModelModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedCarModel?.name || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiCarModelModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiCarModelModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {isSubmittingCarModel && <LoadingModal />}
    </>
  );
};

export default CarModelsTable;
