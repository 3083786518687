/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import ImageModal from '@components/shared/image-modal';
import LoadingModal from '@components/shared/loading-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteMultiDriverModalOpenedAction,
  setPreviewCarBackImgModalOpened,
  setPreviewCarFrontImgModalOpened,
  setPreviewDriverImgModalOpened,
  setSelectedDriverAction,
  setSelectedRowsIdsAction
} from '@redux/drivers-slice/driversSlice';
import {
  allDriversAction,
  changeDriverStatusAction,
  deleteDriverAction
} from '@redux/drivers-slice/driversSliceThunkApi';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import TableColumns from './TableColumns';

const DriversTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingDrivers = useSelector(
    (store) => store.drivers.isLoadingDrivers
  );
  const fetchDriversCount = useSelector(
    (store) => store.drivers.fetchDriversCount
  );
  const fetchedDrivers = useSelector((store) => store.drivers.fetchedDrivers);
  const selectedDriver = useSelector((store) => store.drivers.selectedDriver);
  const confirmModalType = useSelector(
    (store) => store.drivers.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.drivers.confirmModal.opened
  );
  const previewDriverImgModalOpened = useSelector(
    (store) => store.drivers.previewDriverImgModalOpened
  );
  const previewCarFrontImgModalOpened = useSelector(
    (store) => store.drivers.previewCarFrontImgModalOpened
  );
  const previewCarBackImgModalOpened = useSelector(
    (store) => store.drivers.previewCarBackImgModalOpened
  );
  const driversPagination = useSelector(
    (store) => store.drivers.driversPagination
  );
  const selectedRowsIds = useSelector((store) => store.drivers.selectedRowsIds);
  const deleteMultiDriverModalOpened = useSelector(
    (store) => store.drivers.deleteMultiDriverModalOpened
  );
  const isSubmittingDriver = useSelector(
    (store) => store.drivers.isSubmittingDriver
  );

  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allDriversAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchDriversCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedDrivers?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedDrivers);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [JSON.stringify(fetchedDrivers)]);

  let isMounted = true;
  const handleChangeStatus = () => {
    if (isMounted && selectedDriver?.id) {
      const formData = new FormData();
      formData.append('driver_id', selectedDriver.id);
      dispatch(
        changeDriverStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDelete = () => {
    if (isMounted && selectedDriver?.id) {
      const formData = new FormData();
      formData.append('driver_id', selectedDriver.id);
      dispatch(
        deleteDriverAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('driver_id', selectedRowsIds);
      dispatch(
        deleteDriverAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={2850}
        showHeader={true}
        className={`drivers-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedDrivers}
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedDriverAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        setPreviewDriverImgModalOpened={(v) =>
          dispatch(setPreviewDriverImgModalOpened(v))
        }
        setPreviewFrontCarImgModalOpened={(v) =>
          dispatch(setPreviewCarFrontImgModalOpened(v))
        }
        setPreviewBackImgModalOpened={(v) =>
          dispatch(setPreviewCarBackImgModalOpened(v))
        }
        //
        isLoadingTableData={isLoadingDrivers}
        user={user}
        handleRowClick={(record) => {
          navigate(appRouterLinks.driverDetails(record?.id));
        }}
        hasPagination={true}
        tablePagination={driversPagination}
        baseTablePageUrl={`${appRouterLinks.drivers}?searchText=${
          values?.searchText || ''
        }&status=${values?.status || ''}`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedDriverAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeStatus}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من تفعيل ${selectedDriver?.name || ''}`}</h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من ايقاف تفعيل ${selectedDriver?.name || ''}`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedDriverAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleDelete}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من  ${selectedDriver?.name || ''}`}</h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من حذف  ${selectedDriver?.name || ''}`}</h3>
        )}
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiDriverModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiDriverModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {/* Preview image modals */}
      <ImageModal
        title={t('driverPhoto')}
        open={previewDriverImgModalOpened}
        onCancel={() => {
          dispatch(setSelectedDriverAction(null));
          dispatch(setPreviewDriverImgModalOpened(false));
        }}
        src={selectedDriver?.image}
      />
      <ImageModal
        title={t('carFrontImg')}
        open={previewCarFrontImgModalOpened}
        onCancel={() => {
          dispatch(setSelectedDriverAction(null));
          dispatch(setPreviewCarFrontImgModalOpened(false));
        }}
        src={selectedDriver?.image}
      />
      <ImageModal
        title={t('carBackImg')}
        open={previewCarBackImgModalOpened}
        onCancel={() => {
          dispatch(setSelectedDriverAction(null));
          dispatch(setPreviewCarBackImgModalOpened(false));
        }}
        src={selectedDriver?.image}
      />
      {/* Preview image modals */}

      {isSubmittingDriver && <LoadingModal />}
    </>
  );
};

export default DriversTable;
