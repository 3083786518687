import PageHeader from '@components/shared/page-header';
import { t } from 'i18next';
import { VehicleIcon } from '@icons/index';
import DriversTable from './drivers-table';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import './styles.scss';
import TableFilter from './drivers-table/table-filter';
import { useDispatch, useSelector } from 'react-redux';
import DeleteAllBtn from '@components/delete-all-btn';
import {
  setDeleteMultiDriverModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/drivers-slice/driversSlice';

const DriversPage = () => {
  const dispatch = useDispatch();
  const selectedRowsIds = useSelector((store) => store.drivers.selectedRowsIds);
  return (
    <PageWrapperMotion>
      <div className="drivers-page">
        <PageHeader
          title={t('drivers')}
          icon={<VehicleIcon />}
          breadcrumbItems={[
            {
              title: t('drivers')
            }
          ]}
        />

        <div className="table-add-btn-filter-wrapper">
          <span></span>
          {/* <Button
          onClick={() => {
            navigate(appRouterLinks.newVehicleYear);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addYear')}
        </Button> */}

          <div className="table-filter-delete-all-btn">
            <DeleteAllBtn
              selectedRowsIds={selectedRowsIds}
              setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
              onClickDelete={() =>
                dispatch(setDeleteMultiDriverModalOpenedAction(true))
              }
            />
            <TableFilter />
          </div>
        </div>
        <DriversTable />
      </div>
    </PageWrapperMotion>
  );
};

export default DriversPage;
