import axios from 'axios';
import apisUrls from './apisUrls';

export const deliveryMethodsApi = async () => {
  return axios.get(apisUrls.deliveryMethods);
};
// methods 1, and 3 api
export const updateDeliveryMethodsApi = async (values) => {
  return axios.post(apisUrls.updateDeliveryMethods, values);
};

// * method 2 apis
export const priceRangeMethodApi = async () => {
  return axios.get(apisUrls.priceRange);
};
export const singlePriceRangeApi = async (filterObj) => {
  return axios.get(apisUrls.singlePriceRange(filterObj));
};
export const createPriceRangeApi = async (values) => {
  return axios.post(apisUrls.createPriceRange, values);
};
export const updatePriceRangeApi = async (values) => {
  return axios.post(apisUrls.updatePriceRange, values);
};
export const deletePriceRangeApi = async (values) => {
  return axios.post(apisUrls.deletePriceRange, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
// * End method 2 apis
///////////////////////////
// * Withdrawal apis
export const allWithdrawalApi = async (filterObj) => {
  return axios.get(apisUrls.allWithdrawal(filterObj));
};
export const acceptOrRejectWithdrawalApi = async (values) => {
  return axios.post(apisUrls.acceptOrRejectWithdrawal, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
// * End withdrawal apis
////////////////////////////
// * Wallet transactions apis
export const allWalletTransactionsApi = async (filterObj) => {
  return axios.get(apisUrls.allWalletTransactions(filterObj));
};
export const createWalletTransactionsApi = async (values) => {
  return axios.post(apisUrls.createWalletTransaction, values);
};
// * End wallet transactions apis
///////////////////////////
// getDriverBankAccount
export const getDriversBankAccountApi = async (filterObj) => {
  return axios.get(apisUrls.getDriversBankAccount(filterObj));
};
