/* eslint-disable eqeqeq */
import { TrashIcon, UpdateIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button, Tooltip } from 'antd';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setIsLoading,
  setConfirmModal,
  setDeleteModalOpened,
  token,
  t,
  dispatch,
  navigate
}) => {
  const renderUpdateBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('update')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            // to={`${appRouterLinks?.vehicleBrandDetails(record?.id)}`}
            className="action-btn view"
            onClick={(e) => {
              e.stopPropagation();
              navigate(appRouterLinks.editVehicleModel(record.id));
            }}
          >
            <UpdateIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const renderDeleteBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('delete')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
            htmlType="button"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTableRow(record);
              setDeleteModalOpened(true);
            }}
          >
            <TrashIcon color="red" />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('modelNameAr'),
      dataIndex: 'modelNameAr',
      width: 282,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.name_ar || '---'}</div>
        );
      }
    },
    {
      title: t('modelNameEn'),
      dataIndex: 'modelNameEn',
      width: 282,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.name_en || '---'}</div>
        );
      }
    },
    {
      title: t('update'),
      dataIndex: 'update',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderUpdateBtn(record)}</div>
        );
      }
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderDeleteBtn(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
