const apisUrls = {
  // auth urls
  loginUrl: '/Auth_general/login',
  editProfileUrl: '/Auth_private/edit_profile',
  myInfoUrl: '/Auth_private/my_info',
  signOutUrl: '/Auth_private/logout',
  // FORGET PASSWORD
  forgetPasswordUrl: '/Auth_general/forget_password',
  checkPasswordCodeUrl: '/Auth_private/check_password_code',
  resetPasswordUrl: '/Auth_private/reset_password',
  changePasswordUrl: '/Auth_private/change_password',
  resendCode: '/Auth_private/resend_code',
  ////////////////////
  changeLangUrl: '/Auth_private/change_lang',
  // end auth urls
  ////////////////////
  // orders urls
  orders: (filterObj) => `/Order/all?page=${filterObj?.page || 1}`,
  singleOrder: (filterObj) =>
    `/Order/single?order_id=${filterObj?.order_id || ''}`,
  orderFreeDrivers: (filterObj) =>
    `/Order/getFreeDriver?order_id=${filterObj?.order_id || ''}`,
  assignDriver: '/Order/assignOrderToDriver',
  makeOrder: '/Order/create',
  changeOrderStatus: `/Order/changeStatus`,
  deleteOrder: '/Order/delete',
  // end orders urls
  ////////////////////////////////
  // drivers urls
  drivers: (filterObj) =>
    `/Driver/all?searchText=${filterObj?.searchText || ''}&status=${
      filterObj?.status || ''
    }&page=${filterObj?.page || 1}`,
  singleDriver: (filterObj) =>
    `/Driver/single?driver_id=${filterObj?.driver_id || ''}`,
  changeDriverStatus: `/Driver/changeStatus`,
  deleteDriver: '/Driver/delete',
  // end drivers urls
  ////////////////////////////////
  // * Delivery methods urls
  // * start methods 1, 3
  deliveryMethods: '/PriceData/get', // for methods 1, 3
  updateDeliveryMethods: '/PriceData/update', // for methods 1, 3
  // * end methods 1, 3
  /////
  // * start method 2
  priceRange: '/PriceRange/all', // for method 2
  singlePriceRange: (filterObj) =>
    `/PriceRange/single?price_id=${filterObj?.price_id || ''}`,
  createPriceRange: '/PriceRange/create',
  updatePriceRange: '/PriceRange/update',
  deletePriceRange: '/PriceRange/delete',
  // * end method 2
  //////
  // * End Delivery methods urls
  //////////////////////////////
  // * Start withdrawal
  allWithdrawal: (filterObj) =>
    `/Withdrawal/all?status=${filterObj?.status || ''}`, // * 1=>new, 2=>in review ,1=>accepted ,3=>rejected
  acceptOrRejectWithdrawal: '/Withdrawal/acceptOrReject',
  // * End withdrawal
  /////////////////////////
  // * Wallet transactions
  allWalletTransactions: (filterObj) =>
    `/WalletTransaction/all?type=${filterObj?.type || ''}`, // * 1=>withdrawal request , 2=>collection , 3=>Fines
  createWalletTransaction: '/WalletTransaction/create',
  // * End wallet transactions
  /////////////////////////
  // * Drivers bank account
  getDriversBankAccount: '/Withdrawal/banks',
  /////////////////////////
  // car brands urls
  carBrands: (filterObj) =>
    `/CarBrand/all?searchText=${filterObj?.searchText || ''}&status=${
      filterObj?.status || ''
    }&page=${filterObj?.page || 1}`,
  singleCarBrand: (filterObj) =>
    `/CarBrand/single?brand_id=${filterObj?.brand_id || ''}`,
  createCarBrand: '/CarBrand/create',
  updateCarBrand: '/CarBrand/update',
  deleteCarBrand: '/CarBrand/delete',
  changeCarBrandStatus: '/CarBrand/changeStatus',
  // end car brands urls
  ////////////////////////////////
  // car models urls
  carModels: (filterObj) =>
    `/CarModel/all?brand_id=${filterObj?.brand_id || ''}&page=${
      filterObj?.page || 1
    }`,
  singleCarModel: (filterObj) =>
    `/CarModel/single?model_id=${filterObj?.model_id || ''}`,
  createCarModel: '/CarModel/create',
  updateCarModel: '/CarModel/update',
  deleteCarModel: '/CarModel/delete',
  // end car models urls
  ////////////////////////////////
  // car years urls
  carYears: (filterObj) =>
    `/CarYear/all?searchText=${filterObj?.searchText || ''}&page=${
      filterObj?.page || 1
    }`,
  singleCarYear: (filterObj) =>
    `/CarYear/single?year_id=${filterObj?.year_id || ''}`,
  createCarYear: '/CarYear/create',
  updateCarYear: '/CarYear/update',
  deleteCarYear: '/CarYear/delete',
  // end car years urls
  ////////////////////////////////
  // countries urls
  countries: (filterObj) =>
    `/Country/all?status=${filterObj?.status || ''}&searchText=${
      filterObj?.searchText || ''
    }&page=${filterObj?.page || 1}`,
  singleCountry: (filterObj) =>
    `/Country/single?country_id=${filterObj?.country_id || ''}`,
  createCountry: '/Country/create',
  updateCountry: '/Country/update',
  deleteCountry: '/Country/delete',
  changeCountryStatus: `/Country/changeStatus`,
  // end countries urls
  ////////////////////////////////
  // cities urls
  cities: (filterObj) =>
    `/City/all?status=${filterObj?.status || ''}&searchText=${
      filterObj?.searchText || ''
    }&country_id=${filterObj?.country_id || ''}&page=${filterObj?.page || 1}`,
  singleCity: (filterObj) => `/City/single?city_id=${filterObj?.city_id || ''}`,
  createCity: '/City/create',
  updateCity: '/City/update',
  deleteCity: '/City/delete',
  changeCityStatus: '/City/changeStatus',
  // end cities urls
  ////////////////////////////////
  // Regions urls
  regions: (filterObj) =>
    `/Region/all?searchText=${filterObj?.searchText || ''}&status=${
      filterObj?.status || ''
    }&city_id=${filterObj?.city_id || ''}&page=${filterObj?.page || 1}`,
  singleRegion: (filterObj) =>
    `/Region/single?region_id=${filterObj?.region_id || ''}`,
  createRegion: '/Region/create',
  updateRegion: '/Region/update',
  deleteRegion: '/Region/delete',
  changeRegionStatus: '/Region/changeStatus',
  // end Regions urls
  ////////////////////////////////
  // Nationalities urls
  nationalities: (filterObj) =>
    `/Nationality/all?searchText=${filterObj?.searchText || ''}&page=${
      filterObj?.page || 1
    }`,
  singleNationality: (filterObj) =>
    `/Nationality/single?nationality_id=${filterObj?.nationality_id || ''}`,
  createNationality: '/Nationality/create',
  updateNationality: '/Nationality/update',
  deleteNationality: '/Nationality/delete',
  changeNationalityStatus: '/Nationality/changeStatus',
  // end Nationalities urls
  ////////////////////////////////
  ////////////////////////////////
  ////////////////////////////////
  ////////////////////////////////

  // general data urls
  paymentUrl: (filterObj) =>
    `http://bnaa.ahmeds.club/api/Payment/preparePayment?order_id=${
      filterObj?.order_id || ''
    }`
};

export default apisUrls;
