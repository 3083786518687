import './styles.scss';
import { Tag } from 'antd';

const CustomTag = ({ status, arr }) => {
  const foundObj = arr.find((obj) => obj.value === status);
  if (foundObj)
    return (
      <Tag
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          width: 'fit-content',
          padding: '2px 10px',
          margin: 0,
          borderRadius: 5
        }}
        color={foundObj?.color}
      >
        {foundObj.name} {foundObj?.icon}
      </Tag>
    );

  return '-----';
};

export default CustomTag;
