import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allCitiesApi,
  singleCityApi,
  createCityApi,
  updateCityApi,
  deleteCityApi,
  changeCityStatusApi
} from '@apis/citiesApis';
import customApiRequest from '@helpers/customApiRequest';

export const allCitiesAction = createAsyncThunk(
  'cities/allCitiesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allCitiesApi(filterObj),
      thunkAPI
    });
  }
);

export const singleCityAction = createAsyncThunk(
  'cities/singleCityAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleCityApi(filterObj),
      thunkAPI
    });
  }
);

export const createCityAction = createAsyncThunk(
  'cities/createCityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateCityAction = createAsyncThunk(
  'cities/updateCityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateCityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const changeCityStatusAction = createAsyncThunk(
  'cities/changeCityStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeCityStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const changeSingleCityStatusAction = createAsyncThunk(
  'cities/changeSingleCityStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeCityStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteCityAction = createAsyncThunk(
  'cities/deleteCityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deleteSingleCityAction = createAsyncThunk(
  'cities/deleteSingleCityAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCityApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
