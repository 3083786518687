/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import fixedImg from '@assets/imgs/delivery-calc/fixed.png';
import minImg from '@assets/imgs/delivery-calc/min.png';
import rangeImg from '@assets/imgs/delivery-calc/range.png';
import './styles.scss';
import { useEffect, useState } from 'react';
import FormModal from './form-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateDeliveryMethodsAction,
  allDeliveryMethodsAction
} from '@redux/delivery-methods-slice/sliceActionsThunkApis';
import { LoadingOutlined } from '@ant-design/icons';
import { confirmModalTypesValues, deliveryMethodsTypes } from '@mocks/index';
import { CheckMarkIcon } from '../../components/icons';
import ConfirmModal from '../../components/shared/confirm-modal';
import { setConfirmModalAction } from '../../redux/delivery-methods-slice';
import LoadingModal from '../../components/shared/loading-modal';
import { useNavigate } from 'react-router-dom';
import appRouterLinks from '../../routes/appRouterLinks';

const DeliveryCalcMethodsPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formModalOpened, setFormModalOpened] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const arr = [
    {
      id: 1,
      title: t('fixedPriceMethods'),
      imgUrl: fixedImg,
      type: deliveryMethodsTypes.fixed,
      onClick: () => {
        console.log('fixed');
      }
    },
    {
      id: 2,
      title: t('minMethod'),
      type: deliveryMethodsTypes.min,
      imgUrl: minImg,
      onClick: () => {
        console.log('min');
      }
    },
    {
      id: 3,
      title: t('priceRangeMethod'),
      type: deliveryMethodsTypes.range,
      imgUrl: rangeImg,
      onClick: () => {
        console.log('range');
      }
    }
  ];

  const getKey = (store, key) => store.deliverMethods[key];
  const isLoadingDeliveryData = useSelector((store) =>
    getKey(store, 'isLoadingDeliveryData')
  );
  const fetchedDeliveryData = useSelector((store) =>
    getKey(store, 'fetchedDeliveryData')
  );
  const fetchDeliveryDataCount = useSelector((store) =>
    getKey(store, 'fetchDeliveryDataCount')
  );
  const isUpdatingDeliveryData = useSelector((store) =>
    getKey(store, 'isUpdatingDeliveryData')
  );
  const confirmModalOpened = useSelector(
    (store) => store.deliverMethods.confirmModal.opened
  );

  useEffect(() => {
    dispatch(allDeliveryMethodsAction());
  }, [fetchDeliveryDataCount]);

  let isMounted = true;
  const handleActivateMethod = () => {
    if (isMounted && selectedType?.type) {
      const formData = new FormData();
      formData.append('priceActive', selectedType.type);
      dispatch(
        activateDeliveryMethodsAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const renderPageContent = () => {
    if (isLoadingDeliveryData)
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300
          }}
        >
          <LoadingOutlined />
        </div>
      );
    else if (fetchedDeliveryData)
      return (
        <>
          <div className="prices-list">
            {arr.map((item) => (
              <div
                key={item.id}
                className={`price-method-card ${
                  fetchedDeliveryData?.priceActive === item.type ? 'active' : ''
                }`}
                onClick={() => {
                  if (item.type !== deliveryMethodsTypes?.range) {
                    setSelectedType(item);
                    setFormModalOpened(true);
                  } else {
                    navigate(appRouterLinks?.rangeMethodRoute);
                  }
                }}
              >
                <img src={item.imgUrl} alt={item.title} />
                <p className="method-title">{item.title}</p>
                <div className={`btn-badge ${i18n.dir()}`}>
                  {fetchedDeliveryData?.priceActive === item.type ? (
                    <div className="checkmark-wrap">
                      <CheckMarkIcon color="#fff" />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="activate-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedType(item);
                        dispatch(
                          setConfirmModalAction({
                            opened: true,
                            type: confirmModalTypesValues.successModal
                          })
                        );
                      }}
                    >
                      {t('activate')}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Method 1, 3 form modal */}
          <FormModal
            modalOpened={formModalOpened}
            setModalOpened={setFormModalOpened}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
          {/*  */}
          {/* Activate method modal */}
          {/* confirm modal */}
          <ConfirmModal
            modalTitle=""
            modalOpened={confirmModalOpened}
            modalType={confirmModalTypesValues?.successModal}
            setModalOpened={(v) => {
              dispatch(
                setConfirmModalAction({
                  opened: v,
                  type: ''
                })
              );
            }}
            handleAccept={handleActivateMethod}
          >
            <h3>
              {`${t('sureActivate')}`}{' '}
              <span className="bold-font">{selectedType?.title || ''} !?</span>{' '}
            </h3>
          </ConfirmModal>
          {/* end confirm modal */}
          {/*  */}
          {isUpdatingDeliveryData && <LoadingModal />}
        </>
      );

    return null;
  };
  return <div className="delivery-price-calc-page">{renderPageContent()}</div>;
};

export default DeliveryCalcMethodsPage;
