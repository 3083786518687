import AppMenu from '@components/app-menu';
import AppTopBar from '@components/app-top-bar';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import './styles.scss';

const AuthLayout = ({ children }) => {
  const appMenuCollapsed = useSelector(
    (store) => store.generalApp.appMenuCollapsed
  );

  return (
    <div className="app-auth-layout">
      <div className="auth-layout-content-wrapper">
        <AppMenu />
        <div
          className={`${
            appMenuCollapsed
              ? 'auth-layout-content'
              : 'auth-layout-content-full'
          }`}
        >
          <AppTopBar />
          <AnimatePresence>
            <div className="custom-container">{children}</div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
