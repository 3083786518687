/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '@components/shared/logo/Logo';
import appMenuLinks from './appMenuLinks';
import { useMediaQuery } from 'react-responsive';
import './styles.scss';
import { useEffect, useState } from 'react';
import { setAppMenuCollapsed } from '@redux/general-app-slice/generalAppSlice';

const AppMenu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const user = useSelector((store) => store.user.user);
  const appMenuCollapsed = useSelector(
    (store) => store.generalApp.appMenuCollapsed
  );

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  useEffect(() => {
    if (isTabletOrMobile) {
      dispatch(setAppMenuCollapsed(true));
    } else {
      dispatch(setAppMenuCollapsed(false));
    }
  }, [isTabletOrMobile]);

  const onMenuClick = (e) => {
    // console.log('click ', e);
  };

  function getItem({ label, key, icon, children, type, onClick }) {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick
    };
  }

  const getChildrenFormArr = (arr) => {
    if (arr?.length > 0) {
      return arr.map((obj, index) => ({
        key: obj?.key || index,
        label: obj?.label || '--',
        icon: obj?.icon || null,
        type: obj?.type,
        onClick: obj?.url ? () => navigate(obj.url) : null,
        children: getChildrenFormArr(obj?.children)
      }));
    }
    return null;
  };

  const items = appMenuLinks({ i18n, t, user, appMenuCollapsed }).map(
    (item) => {
      return getItem({
        key: item.key,
        label: item.label,
        icon: item.icon,
        type: item.type,
        onClick: item?.url ? () => navigate(item.url) : null,
        children: getChildrenFormArr(item.children)
      });
    }
  );

  const [width, setWidth] = useState(isTabletOrMobile ? 80 : 256);
  // const [height, setHeight] = useState('');

  useEffect(() => {
    if (appMenuCollapsed) setWidth(80);
    else setWidth(256);
  }, [appMenuCollapsed]);

  useEffect(() => {
    if (width >= 256) dispatch(setAppMenuCollapsed(false));
    else if (width < 256) dispatch(setAppMenuCollapsed(true));
  }, [width]);

  return (
    <div
      className={`app-menu-wrapper-parent ${i18n.dir()} ${
        appMenuCollapsed ? 'collapsed' : ''
      }`}
    >
      <div
        className={`app-menu-wrapper ${appMenuCollapsed ? 'collapsed' : ''}`}
      >
        <div className="app-logo">
          <Logo />
        </div>

        <Menu
          onClick={onMenuClick}
          // defaultSelectedKeys={['1']} => key
          // defaultOpenKeys={['orders_label_key', 'drivers_label_key', pathname]}
          defaultOpenKeys={
            appMenuCollapsed
              ? []
              : [
                  'parent_1',
                  'parent_2'
                  // 'parent_3'
                  // 'parent_4',
                  // 'parent_5',
                  // 'parent_6',
                  // 'parent_7',
                  // 'parent_8'
                ]
          }
          defaultSelectedKeys={[pathname]}
          mode="inline"
          items={items}
          className="app-menu"
          inlineCollapsed={appMenuCollapsed}
        />
      </div>
    </div>
  );
};

export default AppMenu;
