import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import useSingleCountry from '@customHooks/useSingleCountry';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Link, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import CountryForm from './country-form';
import LoadingModal from '@components/shared/loading-modal';

const NewCountryPage = () => {
  const params = useParams();
  const isLoadingSingleCountry = useSelector(
    (store) => store.countries.isLoadingSingleCountry
  );
  const isSubmittingCountryForm = useSelector(
    (store) => store.countries.isSubmittingCountryForm
  );
  const fetchedSingleCountry = useSelector(
    (store) => store.countries.fetchedSingleCountry
  );

  useSingleCountry();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleCountry && params?.country_id
            ? `${t('update')} ${fetchedSingleCountry?.name}` ||
              `${
                fetchedSingleCountry?.id ? `#${fetchedSingleCountry.id}` : ''
              }` ||
              ' '
            : t('newCountry')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: <Link to={appRouterLinks.countries}>{t('countries')}</Link>
          },
          {
            title:
              fetchedSingleCountry && params?.country_id
                ? fetchedSingleCountry?.name ||
                  `${
                    fetchedSingleCountry?.id
                      ? `#${fetchedSingleCountry.id}`
                      : ''
                  }` ||
                  ' '
                : t('newCountry')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-country-page">
        <SharedPageHeader />

        <CountryForm
          fetchedObj={params?.country_id ? fetchedSingleCountry : null}
          isSubmittingForm={isSubmittingCountryForm}
        />
      </div>

      {(isSubmittingCountryForm || isLoadingSingleCountry) && <LoadingModal />}
    </PageWrapperMotion>
  );
};

export default NewCountryPage;
