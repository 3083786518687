/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button } from 'antd';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import './styles.scss';
import PriceRangeTable from './price-range-table';

const RangeMethodPage = () => {
  const navigate = useNavigate();

  return (
    <div className="range-method-page">
      <PageHeader
        title={t('priceRangeMethod')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.deliveryCalcMethods}>
                {t('deliveryCalcMethods')}
              </Link>
            )
          },
          {
            title: t('priceRangeMethod')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          style={{
            marginBottom: 12
          }}
          onClick={() => {
            navigate(appRouterLinks.createRangeMethodRout);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('createPriceRange')}
        </Button>
      </div>
      <PriceRangeTable />
    </div>
  );
};

export default RangeMethodPage;
