import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import useSingleNationality from '@customHooks/useSingleNationality';
import { VehicleIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Link, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import NationalityForm from './nationality-form';
import LoadingModal from '@components/shared/loading-modal';

const NewNationalityPage = () => {
  const params = useParams();
  const isLoadingSingleNationality = useSelector(
    (store) => store.nationalities.isLoadingSingleNationality
  );
  const isSubmittingNationalityForm = useSelector(
    (store) => store.nationalities.isSubmittingNationalityForm
  );
  const fetchedSingleNationality = useSelector(
    (store) => store.nationalities.fetchedSingleNationality
  );

  useSingleNationality();

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleNationality && params?.nationality_id
            ? `${t('update')} ${fetchedSingleNationality?.name}` ||
              `${
                fetchedSingleNationality?.id
                  ? `#${fetchedSingleNationality.id}`
                  : ''
              }` ||
              ' '
            : t('newNationality')
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.nationalities}>
                {t('nationalities')}
              </Link>
            )
          },
          {
            title:
              fetchedSingleNationality && params?.nationality_id
                ? fetchedSingleNationality?.name ||
                  `${
                    fetchedSingleNationality?.id
                      ? `#${fetchedSingleNationality.id}`
                      : ''
                  }` ||
                  ' '
                : t('newNationality')
          }
        ]}
      />
    );
  };

  return (
    <PageWrapperMotion>
      <div className="new-nationalities-page">
        <SharedPageHeader />

        <NationalityForm
          fetchedObj={params?.nationality_id ? fetchedSingleNationality : null}
          isSubmittingForm={isSubmittingNationalityForm}
        />
      </div>

      {(isSubmittingNationalityForm || isLoadingSingleNationality) && (
        <LoadingModal />
      )}
    </PageWrapperMotion>
  );
};

export default NewNationalityPage;
