import { createSlice } from '@reduxjs/toolkit';
import {
  getAllWithdrawal,
  acceptOrRejectWithdrawal
} from './sliceExtraReducers';

const initialState = {
  isLoadingWithdrawal: false,
  fetchWithdrawalCount: 0,
  selectedWithdrawal: null,
  fetchedAllWithdrawals: [],
  isSubmittingWithdrawal: false,
  isSubmittingWithdrawalForm: false,
  withdrawalPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  confirmModal: {
    opened: '',
    type: ''
  }
};

const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    setSelectedWithdrawalAction: (sliceState, action) => {
      sliceState.selectedWithdrawal = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: (builder) => {
    getAllWithdrawal({ builder });
    acceptOrRejectWithdrawal({ builder });
  }
});

const withdrawalReducer = withdrawalSlice.reducer;

export const { setSelectedWithdrawalAction, setConfirmModalAction } =
  withdrawalSlice.actions;

export default withdrawalReducer;
