import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';
// import logoWhite from '../../assets/imgs/logo/logo-white.png';
// import logoColored from '../../assets/imgs/logo/logo-colored.png';
import logoWhite from '@assets/imgs/logo/logo-white.png';
import logoColored from '@assets/imgs/logo/logo-colored.png';
import './Logo.scss';

const Logo = memo(({ className, colored }) => {
  const { i18n } = useTranslation();
  return (
    <Link
      style={{
        position: 'relative'
      }}
      className={className}
      to={appRouterLinks.adminHome}
    >
      {/* <img src="/assets/imgs/logo/logo-white.png" alt="app logo" /> */}

      {colored ? (
        i18n.dir() === 'rtl' ? (
          <img src={logoWhite} alt="app logo" />
        ) : (
          <img src={logoWhite} alt="app logo" />
        )
      ) : i18n.dir() === 'rtl' ? (
        <img src={logoColored} alt="app logo" />
      ) : (
        <img src={logoColored} alt="app logo" />
      )}
    </Link>
  );
});

export default Logo;
