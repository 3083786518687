import { Area } from '@ant-design/plots';
import data from './data';

const AntdAreaChart = () => {
  const config = {
    data,
    xField: 'year',
    yField: 'value',
    seriesField: 'category',
    color: [
      '#6897a7',
      '#8bc0d6',
      '#60d7a7',
      '#dedede',
      '#fedca9',
      '#fab36f',
      '#d96d6f'
    ],
    xAxis: {
      type: 'time',
      mask: 'YYYY'
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
      }
    },
    legend: {
      position: 'top'
    }
  };

  return (
    <div
      style={{
        direction: 'ltr'
      }}
      className="antd-area-chart"
    >
      <Area {...config} />
    </div>
  );
};

export default AntdAreaChart;
