/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import ConfirmModal from '@components/shared/confirm-modal';
import CustomEmpty from '@components/shared/custom-empty';
import LoadingModal from '@components/shared/loading-modal';
import PageHeader from '@components/shared/page-header';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { VehicleIcon } from '@icons/index';
import { activeTypesArr, confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteCarBrandModalOpenedAction
} from '@redux/cars-brands-slice/carsBrandsSlice';
import {
  changeSingleCarBrandStatusAction,
  deleteSingleCarBrandAction
} from '@redux/cars-brands-slice/carsBrandsSliceThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useSingleCarBrand from '@customHooks/useSingleCarBrand';
import BrandDetailsCard from './brand-details-card';

const SingleCarBrandsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useSingleCarBrand();

  const isLoadingSingleCarBrand = useSelector(
    (store) => store.carBrands.isLoadingSingleCarBrand
  );
  const fetchedSingleCarBrand = useSelector(
    (store) => store.carBrands.fetchedSingleCarBrand
  );
  const confirmModalOpened = useSelector(
    (store) => store.carBrands.confirmModal.opened
  );
  const confirmModalType = useSelector(
    (store) => store.carBrands.confirmModal.type
  );
  const isSubmittingSingleCarBrand = useSelector(
    (store) => store.carBrands.isSubmittingSingleCarBrand
  );

  const deleteCarBrandModalOpened = useSelector(
    (store) => store.carBrands.deleteCarBrandModalOpened
  );

  const [selectedStatus, setSelectedStatus] = useState(null);

  let isMounted = true;
  const submitDelete = async () => {
    if (isMounted && fetchedSingleCarBrand?.id) {
      const formData = new FormData();
      formData.append('brand_id', fetchedSingleCarBrand.id);

      const action = await dispatch(
        deleteSingleCarBrandAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action.payload.res;
      if (checkSuccessRes(res)) {
        navigate(appRouterLinks?.vehiclesBrands);
      }
    }
  };
  const handleSubmitStatus = () => {
    if (isMounted && fetchedSingleCarBrand?.id) {
      const formData = new FormData();
      formData.append('brand_id', fetchedSingleCarBrand.id);

      dispatch(
        changeSingleCarBrandStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const SharedPageHeader = () => {
    return (
      <PageHeader
        title={
          fetchedSingleCarBrand?.name ||
          `${
            fetchedSingleCarBrand?.id ? `#${fetchedSingleCarBrand.id}` : ''
          }` ||
          ' '
        }
        icon={<VehicleIcon />}
        breadcrumbItems={[
          {
            title: (
              <Link to={appRouterLinks.vehiclesBrands}>
                {t('vehicleBrands')}
              </Link>
            )
          },
          {
            title: t('details')
          }
        ]}
      />
    );
  };

  if (isLoadingSingleCarBrand) {
    return (
      <>
        <SharedPageHeader />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300
          }}
        >
          <LoadingOutlined />
        </div>
      </>
    );
  } else if (!fetchedSingleCarBrand) {
    return (
      <>
        <SharedPageHeader />
        <CustomEmpty>
          <h2
            style={{
              margin: '18px auto',
              textAlign: 'center'
            }}
          >
            {t('empty')}
          </h2>
        </CustomEmpty>
      </>
    );
  } else if (fetchedSingleCarBrand)
    return (
      <PageWrapperMotion>
        <div className="single-car-brands-page">
          <SharedPageHeader />

          <BrandDetailsCard
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            obj={fetchedSingleCarBrand}
          />
        </div>

        {/* confirm status modal */}
        <ConfirmModal
          modalOpened={confirmModalOpened}
          setModalOpened={(v) =>
            dispatch(
              setConfirmModalAction({
                opened: v,
                type: ''
              })
            )
          }
          handleAccept={handleSubmitStatus}
          modalTitle=""
          modalType={confirmModalType}
          handleCancel={() => {
            const foundStatus = activeTypesArr(t).find(
              (obj) => obj?.value == fetchedSingleCarBrand?.status
            );
            setSelectedStatus(foundStatus);
          }}
        >
          <h3>
            {t('sureChangeDriverStatus')} {selectedStatus?.name}
          </h3>
        </ConfirmModal>
        {/* end confirm status modal */}

        {/* delete modal */}
        <ConfirmModal
          modalOpened={deleteCarBrandModalOpened}
          setModalOpened={(v) =>
            dispatch(setDeleteCarBrandModalOpenedAction(v))
          }
          handleAccept={submitDelete}
          modalTitle=""
          modalType={confirmModalTypesValues?.errorModal}
        >
          <h3>{t('sureDelete')}</h3>
        </ConfirmModal>
        {/* end delete modal */}

        {isSubmittingSingleCarBrand && <LoadingModal />}
      </PageWrapperMotion>
    );

  return null;
};

export default SingleCarBrandsPage;
