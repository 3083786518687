import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allWalletTransactionsApi,
  createWalletTransactionsApi,
  getDriversBankAccountApi
} from '@apis/delivery-methods-apis';
import customApiRequest from '@helpers/customApiRequest';

const customReq = ({ actionName, actionApi }) => {
  return createAsyncThunk(
    `/delivery/${actionName}`,
    async ({ filterObj, dataToBeSubmitted }, thunkAPI) => {
      if (filterObj)
        return customApiRequest({
          apiCall: await actionApi(filterObj),
          thunkAPI
        });
      else if (dataToBeSubmitted) {
        return customApiRequest({
          apiCall: await actionApi(dataToBeSubmitted),
          thunkAPI
        });
      }
      return customApiRequest({
        apiCall: await actionApi(),
        thunkAPI
      });
    }
  );
};

// * Start wallet transactions actions
export const getAllWalletTransactionsAction = createAsyncThunk(
  'delivery/getAllWalletTransactionsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allWalletTransactionsApi(filterObj),
      thunkAPI
    });
  }
);
export const createWalletTransactionAction = createAsyncThunk(
  'delivery/createWalletTransactionAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWalletTransactionsApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// * End wallet transactions actions
//////////////////
// get drivers bank account
export const getDriversBankAccountAction = createAsyncThunk(
  'delivery/getDriversBankAccountAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getDriversBankAccountApi(filterObj),
      thunkAPI
    });
  }
);
