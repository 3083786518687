import { createSlice } from '@reduxjs/toolkit';
import {
  getRegions,
  getSingleRegion,
  createRegion,
  updateRegion,
  changeRegionStatus,
  changeSingleRegionStatus,
  deleteRegion,
  deleteSingleRegion
} from './sliceExtraReducers';

const initialState = {
  // all regions
  isLoadingRegions: false,
  fetchRegionsCount: 0,
  fetchedRegions: [],
  selectedRegion: null,
  selectedRowsIds: '',
  isSubmittingRegion: false,
  isSubmittingRegionStatus: false,
  isSubmittingRegionForm: false,
  regionsPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // * single region
  isLoadingSingleRegion: false,
  fetchSingleRegionCount: 0,
  fetchedSingleRegion: null,
  isSubmittingSingleRegion: false,
  ///////////////////////
  ///////////////////////
  deleteRegionModalOpened: false,
  deleteMultiRegionModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setSelectedRegionAction: (sliceState, action) => {
      sliceState.selectedRegion = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteRegionModalOpenedAction: (sliceState, action) => {
      sliceState.deleteRegionModalOpened = action.payload;
    },
    setDeleteMultiRegionModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiRegionModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: (builder) => {
    getRegions({ builder });
    getSingleRegion({ builder });
    createRegion({ builder });
    updateRegion({ builder });
    changeRegionStatus({ builder });
    changeSingleRegionStatus({ builder });
    deleteRegion({ builder });
    deleteSingleRegion({ builder });
  }
});

const regionsReducer = regionsSlice.reducer;

export const {
  setSelectedRegionAction,
  setSelectedRowsIdsAction,
  setDeleteRegionModalOpenedAction,
  setDeleteMultiRegionModalOpenedAction,
  setConfirmModalAction
} = regionsSlice.actions;

export default regionsReducer;
