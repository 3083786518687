import { TrashIcon } from '@icons/index';
import './styles.scss';

const DeleteBtn = ({ btnText, handleClickDeleteBtn, props }) => {
  return (
    <div className="delete-btn-wrapper" {...props}>
      <button onClick={handleClickDeleteBtn} className="delete-btn">
        <TrashIcon />
        {btnText}
      </button>
    </div>
  );
};

export default DeleteBtn;
