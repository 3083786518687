import { createSlice } from '@reduxjs/toolkit';
import {
  getCountries,
  getSingleCountry,
  createCountry,
  updateCountry,
  changeCountryStatus,
  changeSingleCountryStatus,
  deleteCountry,
  deleteSingleCountry
} from './sliceExtraReducers';

const initialState = {
  // all countries
  isLoadingCountries: false,
  fetchCountriesCount: 0,
  fetchedCountries: [],
  selectedCountry: null,
  selectedRowsIds: '',
  isSubmittingCountry: false,
  isSubmittingCountryStatus: false,
  isSubmittingCountryForm: false,
  countriesPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // single country
  isLoadingSingleCountry: false,
  fetchSingleCountryCount: 0,
  fetchedSingleCountry: null,
  isSubmittingSingleCountry: false,
  ///////////////////////
  previewCountryLogoModalOpened: false,
  ///////////////////////
  deleteCountryModalOpened: false,
  deleteMultiCountryModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setSelectedCountryAction: (sliceState, action) => {
      sliceState.selectedCountry = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteCountryModalOpenedAction: (sliceState, action) => {
      sliceState.deleteCountryModalOpened = action.payload;
    },
    setDeleteMultiCountryModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiCountryModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setPreviewCountryLogoModalOpened: (sliceState, action) => {
      sliceState.previewCountryLogoModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    getCountries({ builder });
    getSingleCountry({ builder });
    createCountry({ builder });
    updateCountry({ builder });
    changeCountryStatus({ builder });
    changeSingleCountryStatus({ builder });
    deleteCountry({ builder });
    deleteSingleCountry({ builder });
  }
});

const countriesReducer = countriesSlice.reducer;

export const {
  setSelectedCountryAction,
  setSelectedRowsIdsAction,
  setDeleteCountryModalOpenedAction,
  setDeleteMultiCountryModalOpenedAction,
  setConfirmModalAction,
  setPreviewCountryLogoModalOpened
} = countriesSlice.actions;

export default countriesReducer;
