import { Breadcrumb } from 'antd';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';

const AppBreadcrumb = ({ items }) => {
  if (items?.length > 0)
    return (
      <div className="app-breadcrumb">
        <Breadcrumb
          items={[
            {
              title: <Link to={appRouterLinks.adminHome}>{t('admin')}</Link>
            },
            ...items
          ]}
        />
      </div>
    );
  return null;
};

export default AppBreadcrumb;
