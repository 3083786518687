/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import checkSuccessRes from '@helpers/checkSuccessRes';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createPriceRangeMethodAction,
  updatePriceRangeMethodAction
} from '@redux/delivery-methods-slice/sliceActionsThunkApis';
import { Button, Form } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingModal from '../../../../../components/shared/loading-modal';
import getFormSchema from './formSchema';
import './styles.scss';

const RangeMethodForm = ({ fetchedObj, isSubmittingForm }) => {
  const { i18n } = useTranslation();

  const params = useParams();

  const defaultValues = {
    from: '',
    to: '',
    price: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        fetchedObj,
        t
      })
    ),
    mode: 'all',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values
  useEffect(() => {
    if (fetchedObj && params?.price_id) {
      setValue('from', fetchedObj?.from || defaultValues.from);
      setValue('to', fetchedObj?.to || defaultValues.to);
      setValue('price', fetchedObj?.price || defaultValues.price);
    }
  }, [fetchedObj, params?.price_id]);

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`rangeMethodForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      from: fetchedObj?.from || '',
      to: fetchedObj?.to || '',
      price: fetchedObj?.price || ''
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('from', data.from);
    formData.append('to', data.to);
    formData.append('price', data.price);

    try {
      if (!fetchedObj && !params?.price_id) {
        const action = await dispatch(
          createPriceRangeMethodAction({
            dataToBeSubmitted: formData
          })
        );
        const res = action.payload.res;
        if (checkSuccessRes(res)) {
          resetFormInputs();
        }
      } else {
        formData.append('price_id', fetchedObj.id);
        await dispatch(
          updatePriceRangeMethodAction({
            dataToBeSubmitted: formData
          })
        );
      }
    } catch (e) {
      // console.log('e : ', e);
      throw e;
    }
  };

  return (
    <div className="car-year-form-wrapper">
      <Form
        className="car-year-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">
            {fetchedObj ? t('updatePriceRange') : t('createPriceRange')}
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="from"
              type="text"
              errorMsg={errors?.from?.message}
              validateStatus={errors?.from ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('from', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('from')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="to"
              type="text"
              errorMsg={errors?.to?.message}
              validateStatus={errors?.to ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('to', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('to')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="price"
              type="text"
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
              onChange={(v) => {
                if (v === '0') setValue('price', '');
              }}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('price')}</p>
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {(watch('from') || watch('to') || watch('price')) && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={resetFormInputs}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm
                ? t('saving')
                : fetchedObj
                ? t('update')
                : t('save')}
            </Button>
          </div>
        </div>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </div>
  );
};

export default RangeMethodForm;
