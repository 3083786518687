import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allCarModelsApi,
  singleCarModelApi,
  createCarModelApi,
  updateCarModelApi,
  deleteCarModelApi
} from '@apis/carModelsApis';
import customApiRequest from '@helpers/customApiRequest';

export const allCarModelsAction = createAsyncThunk(
  'carsModels/allCarModelsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allCarModelsApi(filterObj),
      thunkAPI
    });
  }
);

export const singleCarModelAction = createAsyncThunk(
  'carsModels/singleCarModelAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleCarModelApi(filterObj),
      thunkAPI
    });
  }
);

export const createCarModelAction = createAsyncThunk(
  'carsModels/createCarModelAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCarModelApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateCarModelAction = createAsyncThunk(
  'carsModels/updateCarModelAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateCarModelApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteCarModelAction = createAsyncThunk(
  'carsModels/deleteCarModelAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCarModelApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deleteSingleCarModelAction = createAsyncThunk(
  'carsModels/deleteSingleCarModelAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCarModelApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
