import { createSlice } from '@reduxjs/toolkit';
import {
  activateDeliveryMethod,
  getAllDeliveryMethods,
  updateDeliveryMethods,
  getPriceRangeMethod,
  getSinglePriceRangeMethod,
  createPriceRangeMethod,
  updatePriceRangeMethod,
  deletePriceRangeMethod
} from './sliceExtraReducers';

const initialState = {
  isLoadingDeliveryData: false,
  isUpdatingDeliveryData: false,
  fetchDeliveryDataCount: 0,
  fetchedDeliveryData: null,
  /////////////////////////
  // range method
  isLoadingRangeMethod: false,
  fetchedRangeMethodCount: 0,
  selectedRangeMethod: null,
  fetchedRangeMethods: [],
  isSubmittingRangeMethod: false,
  isSubmittingRangeForm: false,
  rangeMethodPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  // single range
  isLoadingSingleRangeMethod: false,
  fetchSingleRangeMethodCount: 0,
  fetchedSingleRangeMethod: null,
  isSubmittingSingleRangeMethod: false,
  ///////////////////////
  deleteRangeMethodModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const deliveryMethodsSlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setSelectedRangeMethodAction: (sliceState, action) => {
      sliceState.selectedRangeMethod = action.payload;
    },
    setDeleteRangeMethodModalOpenedAction: (sliceState, action) => {
      sliceState.deleteRangeMethodModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setFetchDeliveryDataCountAction: (sliceData, action) => {
      sliceData.fetchDeliveryDataCount = action.payload;
    }
  },
  extraReducers: (builder) => {
    activateDeliveryMethod({ builder });
    getAllDeliveryMethods({ builder });
    updateDeliveryMethods({ builder });
    getPriceRangeMethod({ builder });
    getSinglePriceRangeMethod({ builder });
    createPriceRangeMethod({ builder });
    updatePriceRangeMethod({ builder });
    deletePriceRangeMethod({ builder });
  }
});

const deliveryMethodsReducer = deliveryMethodsSlice.reducer;

export const {
  setSelectedRangeMethodAction,
  setSelectedRowsIdsAction,
  setDeleteRangeMethodModalOpenedAction,
  setConfirmModalAction,
  setFetchDeliveryDataCountAction
} = deliveryMethodsSlice.actions;

export default deliveryMethodsReducer;
