/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { ReloadIcon, SearchIcon } from '@components/icons';
import appRouterLinks from '@routes/appRouterLinks';
import { Button, Form, Select } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import queryString from 'query-string';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { walletTransactionsTypesArr } from '@mocks';
import './styles.scss';

const TableFilter = ({ isSubmittingForm }) => {
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const navigate = useNavigate();

  const defaultValues = {
    searchText: ''
  };

  const { control, handleSubmit, setValue, watch } = useForm({
    // resolver: yupResolver(

    //   )
    // ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values
  useEffect(() => {
    setValue('type', values?.type || defaultValues?.type);
  }, [search]);

  const onSubmit = (data) => {
    navigate(
      `${appRouterLinks.walletTransactionsRoute}?type=${data.type || ''}&page=1`
    );
  };

  const [form] = Form.useForm();
  return (
    <div className="shared-table-filter-form-wrapper">
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit(onSubmit)}
        className={`shared-filter-form filter-year-form ${
          watch('type') ? 'view-actions' : ''
        }`}
      >
        <div className="form-body">
          <Controller
            name="type"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  className="select-input"
                  showSearch
                  style={{
                    width: 160,
                    height: 32
                  }}
                  {...field}
                  placeholder={t('type')}
                  optionFilterProp="children"
                  // onChange={(value) => {}}
                  onSearch={(value) => {}}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      id: 'empty_id',
                      label: t('selectType'),
                      value: ''
                    },
                    ...walletTransactionsTypesArr(t).map((item) => ({
                      label: item?.name || '',
                      value: String(item.value)
                    }))
                  ]}
                />
              );
            }}
          />
        </div>
        <AnimatePresence>
          {watch('type') && (
            <motion.div
              initial={{
                opacity: 0,
                translateX: i18n.dir() === 'ltr' ? 192 : -192
              }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: {
                  duration: 0.5,
                  type: 'spring'
                }
              }}
              className={`form-actions ${i18n.dir()}`}
            >
              <Button
                className="reset-btn"
                htmlType="button"
                type="link"
                shape="circle"
                icon={
                  isSubmittingForm ? (
                    <LoadingOutlined />
                  ) : (
                    <ReloadIcon width={18} height={18} />
                  )
                }
                loading={isSubmittingForm}
                onClick={() => {
                  if (values?.type) {
                    navigate(appRouterLinks.walletTransactionsRoute);
                  } else {
                    setValue('type', '');
                  }
                }}
              />
              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                shape="circle"
                icon={
                  isSubmittingForm ? (
                    <LoadingOutlined />
                  ) : (
                    <SearchIcon width={18} height={18} />
                  )
                }
                loading={isSubmittingForm}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Form>
    </div>
  );
};

export default TableFilter;
