import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allDriversApi,
  singleDriverApi,
  changeDriverStatusApi,
  deleteDriverApi
} from '@apis/driversApis';
import customApiRequest from '@helpers/customApiRequest';

export const allDriversAction = createAsyncThunk(
  'drivers/allDriversAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await allDriversApi(filterObj),
      thunkAPI
    });
  }
);

export const singleDriverAction = createAsyncThunk(
  'drivers/singleDriverAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await singleDriverApi(filterObj),
      thunkAPI
    });
  }
);

export const changeDriverStatusAction = createAsyncThunk(
  'drivers/changeDriverStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeDriverStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const changeSingleDriverStatusAction = createAsyncThunk(
  'drivers/changeSingleDriverStatusAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeDriverStatusApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteDriverAction = createAsyncThunk(
  'drivers/deleteDriverAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteDriverApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const deleteSingleDriverAction = createAsyncThunk(
  'drivers/deleteSingleDriverAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteDriverApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
