/* eslint-disable eqeqeq */
import { TrashIcon, UpdateIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button, Switch, Tooltip } from 'antd';
import {
  activeTypes,
  activeTypesArr,
  confirmModalTypesValues
} from '../../../mocks';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setIsLoading,
  setConfirmModal,
  setDeleteModalOpened,
  token,
  t,
  dispatch,
  navigate
}) => {
  const renderUpdateBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('update')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            // to={`${appRouterLinks?.vehicleBrandDetails(record?.id)}`}
            className="action-btn view"
            onClick={(e) => {
              e.stopPropagation();
              navigate(appRouterLinks.editRegion(record.id));
            }}
          >
            <UpdateIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const renderDeleteBtn = (record) => {
    return (
      <div className="action-btns-wrap">
        <Tooltip placement="top" title={t('delete')}>
          <Button
            shape="circle"
            type="text"
            size="large"
            className="action-btn view"
            htmlType="button"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTableRow(record);
              setDeleteModalOpened(true);
            }}
          >
            <TrashIcon color="red" />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const handleToggleAgent = (record) => {
    if (record) {
      setSelectedTableRow(record);
      setConfirmModal({
        opened: true,
        type:
          record?.status == activeTypes?.active
            ? confirmModalTypesValues.errorModal
            : confirmModalTypesValues.successModal
      });
    }
  };

  const renderChangeStatusSwitch = (record) => {
    const found = activeTypesArr(t).find((obj) => obj?.value === record.status);
    if (found)
      return (
        <div className="action-btns-wrap">
          <Switch
            checked={record?.status === activeTypes?.active}
            onChange={(v, e) => {
              e.stopPropagation();
              e.preventDefault();
              handleToggleAgent(record);
            }}
            className={`affiliate-toggle ${
              record?.status === activeTypes?.notActive ? 'not-active' : ''
            }`}
          />
        </div>
      );
    return '---';
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('name_ar'),
      dataIndex: 'nameAr',
      width: 282,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.name_ar || '---'}</div>
        );
      }
    },
    {
      title: t('name_en'),
      dataIndex: 'NameEn',
      width: 282,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.name_en || '---'}</div>
        );
      }
    },
    {
      title: t('city'),
      dataIndex: 'city',
      width: 282,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.city?.name || '---'}</div>
        );
      }
    },

    {
      title: t('changeStatus'),
      dataIndex: 'changeStatus',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {renderChangeStatusSwitch(record)}
          </div>
        );
      }
    },

    {
      title: t('update'),
      dataIndex: 'update',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderUpdateBtn(record)}</div>
        );
      }
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderDeleteBtn(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
