import axios from 'axios';
import apisUrls from './apisUrls';

export const allOrdersApi = async (filterObj) => {
  return axios.get(apisUrls.orders(filterObj));
};

export const singleOrderApi = async (filterObj) => {
  return axios.get(apisUrls.singleOrder(filterObj));
};

export const orderFreeDriversApi = async (filterObj) => {
  return axios.get(apisUrls.orderFreeDrivers(filterObj));
};

export const assignDriverToOrderApi = async (values) => {
  return axios.post(apisUrls.assignDriver, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const makeOrderApi = async (values) => {
  return axios.post(apisUrls.makeOrder, values);
};

export const changeOrderStatusApi = async (values) => {
  return axios.post(apisUrls.changeOrderStatus, values);
};

export const deleteOrderApi = async (values) => {
  return axios.post(apisUrls.deleteOrder, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
