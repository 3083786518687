import axios from 'axios';
import apisUrls from './apisUrls';

export const allCitiesApi = async (filterObj) => {
  return axios.get(apisUrls.cities(filterObj));
};

export const singleCityApi = async (filterObj) => {
  return axios.get(apisUrls.singleCity(filterObj));
};

export const createCityApi = async (values) => {
  return axios.post(apisUrls.createCity, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateCityApi = async (values) => {
  return axios.post(apisUrls.updateCity, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteCityApi = async (values) => {
  return axios.post(apisUrls.deleteCity, values);
};

export const changeCityStatusApi = async (values) => {
  return axios.post(apisUrls.changeCityStatus, values);
};
