// import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { checkIfNumberWithDecimalPoint } from '@helpers/checkValidNumber';

const sharedSchema = (t) => {
  const sharedFormErr = ({ key, typeObj }) =>
    t(
      `calcDeliveryForm.${key}.errors.${typeObj?.type || 'required'}`,
      typeObj?.options || null
    );

  return {
    walletStart: Yup.string()
      .required(
        sharedFormErr({
          key: 'walletStart'
        })
      )
      .test(
        'walletStart',
        sharedFormErr({
          key: 'walletStart',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      ),
    walletEnd: Yup.string()
      .required(
        sharedFormErr({
          key: 'walletEnd'
        })
      )
      .test(
        'walletEnd',
        sharedFormErr({
          key: 'walletEnd',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      ),
    walletRest: Yup.string()
      .required(
        sharedFormErr({
          key: 'walletRest'
        })
      )
      .test(
        'walletRest',
        sharedFormErr({
          key: 'walletRest',
          typeObj: {
            type: 'onlyNumbers'
          }
        }),
        (v, context) => {
          let result = true;
          if (!v || Number(v) === 0 || !checkIfNumberWithDecimalPoint(v))
            result = false;

          return result;
        }
      )
  };
};

const getFormSchema = ({ t }) => {
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

export default getFormSchema;
