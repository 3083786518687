/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import ImageModal from '@components/shared/image-modal';
import { confirmModalTypesValues, withdrawalsStatusValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setSelectedWithdrawalAction
} from '@redux/withdrawal-slice';
import {
  getAllWithdrawalAction,
  acceptOrRejectWithdrawalAction
} from '@redux/withdrawal-slice/sliceActionsThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '@components/shared/loading-modal';
import './styles.scss';
import TableColumns from './TableColumns';

const WithdrawalsTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingWithdrawal = useSelector(
    (store) => store.withdrawals.isLoadingWithdrawal
  );
  const isSubmittingWithdrawal = useSelector(
    (store) => store.withdrawals.isSubmittingWithdrawal
  );
  const fetchWithdrawalCount = useSelector(
    (store) => store.withdrawals.fetchWithdrawalCount
  );
  const fetchedAllWithdrawals = useSelector(
    (store) => store.withdrawals.fetchedAllWithdrawals
  );
  const withdrawalPagination = useSelector(
    (store) => store.withdrawals.withdrawalPagination
  );
  const selectedWithdrawal = useSelector(
    (store) => store.withdrawals.selectedWithdrawal
  );
  const confirmModalType = useSelector(
    (store) => store.withdrawals.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.withdrawals.confirmModal.opened
  );

  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        getAllWithdrawalAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchWithdrawalCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedAllWithdrawals?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedAllWithdrawals);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchWithdrawalCount,
    fetchedAllWithdrawals,
    fetchedAllWithdrawals?.length
  ]);

  let isMounted = true;
  const handleChangeStatus = () => {
    if (isMounted && selectedWithdrawal?.id) {
      const formData = new FormData();
      formData.append('id', selectedWithdrawal.id);
      let status = '';
      if (confirmModalType === confirmModalTypesValues?.errorModal)
        status = withdrawalsStatusValues.rejected;
      else if (confirmModalType === confirmModalTypesValues?.successModal)
        status = withdrawalsStatusValues.accepted;
      formData.append('status', status);
      dispatch(
        acceptOrRejectWithdrawalAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={1300}
        width={1300}
        showHeader={true}
        className={`withdrawals-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedAllWithdrawals}
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedWithdrawalAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        //
        isLoadingTableData={isLoadingWithdrawal}
        user={user}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={withdrawalPagination}
        baseTablePageUrl={`${appRouterLinks.withdrawalsRoute}?status=${
          values?.status || ''
        }`}
      />

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedWithdrawalAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeStatus}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>
            {`${t('sureAcceptWithdrawal')}`}{' '}
            <span className="bold-font">
              {selectedWithdrawal?.amount
                ? `${selectedWithdrawal.amount} ${t('currency.sar')}`
                : ''}{' '}
              !?
            </span>{' '}
          </h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>
            {`${t('sureRejectWithdrawal')}`}{' '}
            <span className="bold-font">
              {selectedWithdrawal?.amount
                ? `${selectedWithdrawal.amount} ${t('currency.sar')}`
                : ''}{' '}
              !?
            </span>{' '}
          </h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {isSubmittingWithdrawal && <LoadingModal />}
    </>
  );
};

export default WithdrawalsTable;
