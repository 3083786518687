import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  allRegionsAction,
  singleRegionAction,
  changeRegionStatusAction,
  changeSingleRegionStatusAction,
  deleteRegionAction,
  deleteSingleRegionAction,
  createRegionAction,
  updateRegionAction
} from './regionsSliceThunkApis';

export function getRegions({ builder }) {
  const { pending, fulfilled, rejected } = allRegionsAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingRegions = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingRegions = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedRegions = data?.data;
        if (data?.pagination) sliceState.regionsPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingRegions = false;
    });
}

export function getSingleRegion({ builder }) {
  const { pending, fulfilled, rejected } = singleRegionAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingSingleRegion = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingSingleRegion = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedSingleRegion = data;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingSingleRegion = false;
    });
}

export function createRegion({ builder }) {
  const { pending, fulfilled, rejected } = createRegionAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRegionForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRegionForm = false;
      // if (checkSuccessRes(action?.payload?.res)) {
      //   sliceState.fetchRegionsCount += 1;
      // }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRegionForm = false;
    });
}

export function updateRegion({ builder }) {
  const { pending, fulfilled, rejected } = updateRegionAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRegionForm = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRegionForm = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.fetchSingleRegionCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRegionForm = false;
    });
}
export function changeRegionStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeRegionStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRegionStatus = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRegionStatus = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchRegionsCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRegionStatus = false;
    });
}
export function changeSingleRegionStatus({ builder }) {
  const { pending, fulfilled, rejected } = changeSingleRegionStatusAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleRegion = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleRegion = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchSingleRegionCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleRegion = false;
    });
}
export function deleteRegion({ builder }) {
  const { pending, fulfilled, rejected } = deleteRegionAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingRegion = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingRegion = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.deleteRegionModalOpened = false;
        sliceState.selectedRowsIds = '';
        sliceState.deleteMultiRegionModalOpened = false;
        sliceState.fetchRegionsCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingRegion = false;
    });
}

export function deleteSingleRegion({ builder }) {
  const { pending, fulfilled, rejected } = deleteSingleRegionAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingSingleRegion = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingSingleRegion = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        // sliceState.fetchSingleRegionCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingSingleRegion = false;
    });
}
