/* eslint-disable eqeqeq */
import { LoadingOutlined } from '@ant-design/icons';
import CustomImage from '@components/shared/custom-image';
import useSignout from '@customHooks/useSignout';
import { DropDownIcon, UserIcon, LogoutIcon, LogoutRtlIcon } from '@icons';
import { accountTypes } from '@mocks';
import appRouterLinks from '@routes/appRouterLinks';
import { Button, Dropdown, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingModal from '../shared/loading-modal';
import getProfileMenuLinks from './getProfileMenuLinks';
import './styles.scss';

const ProfileMenu = () => {
  const user = useSelector((store) => store.user.user);
  const { t, i18n } = useTranslation();
  const { isSubmitting, signMeOut } = useSignout();
  const handleSignout = () => {
    signMeOut();
  };

  const renderProfileMenu = () => {
    if (getProfileMenuLinks(i18n, t, user)?.length > 0) {
      return getProfileMenuLinks(i18n, t, user).map((item) => ({
        key: item?.id,
        label: (
          <Link className="menu-link" to={item?.link}>
            {item?.icon}
            {item?.name}
          </Link>
        )
      }));
      // <Menu.Item key={item?.id}>
      //   <RouterLink className="menu-link" to={item?.link}>
      //     {item?.icon}
      //     {item?.name}
      //   </RouterLink>
      // </Menu.Item>
    }
    return [];
  };

  return (
    <div className="profile-menu-wrapper">
      <Dropdown
        overlayClassName="main-app-profile-dropdown"
        arrow
        trigger={['click']}
        // disabled={loadingSignout}
        menu={{
          items: [
            {
              key: 'user_settings_key',
              label: (
                <Link
                  to={
                    user?.accountType == accountTypes()?.company ||
                    user?.accountType == accountTypes()?.employee
                      ? appRouterLinks?.merchentSettings
                      : appRouterLinks?.accountSettings
                  }
                  className="settings-link"
                >
                  <CustomImage src={user?.image} className="profile-img" />
                  <div className="name-wallet">
                    <div className="name">
                      {t('hello')}, {user?.name}
                    </div>
                  </div>
                </Link>
              )
            },
            // {
            //   key: 'profile_key',
            //   className: 'profile-label',
            //   label: t('profileMenu.profile')
            // },
            ...renderProfileMenu(),
            {
              key: 'logout_key',
              icon: i18n.dir() === 'rtl' ? <LogoutRtlIcon /> : <LogoutIcon />,
              className: 'signout-btn',
              label: t('logout'),
              onClick: () => {
                handleSignout();
              }
            }
          ]
        }}
      >
        <Button className="profile-menu-btn" type="text">
          <Avatar size={40} icon={<UserIcon />} src={user?.image} />
          <span
            style={{
              display: 'inline-block',
              maxWidth: '92px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize'
            }}
          >
            {user?.name}
          </span>
          {isSubmitting ? <LoadingOutlined /> : <DropDownIcon />}
        </Button>
      </Dropdown>

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default ProfileMenu;
