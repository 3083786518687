import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseIcon, PlusIcon } from '@components/icons';
import SharedOrderTextField from './SharedOrderTextField';

const ProductAddonFields = ({
  control,
  setValue,
  errors,
  sharedFormLabel,
  productIndex
}) => {
  const { i18n } = useTranslation();
  const {
    fields: productAddonFields,
    append: appendProductAddonField,
    remove: removeProductAddonField
  } = useFieldArray({
    control,
    name: `products.${productIndex}.addon`
  });

  return (
    <div className="order-form-addons-fields-wrapper">
      <button
        className={`add-addon-btn ${i18n.dir()}`}
        type="button"
        style={{
          cursor: !errors?.products ? 'pointer' : 'default',
          opacity: !errors?.products ? '1' : '0.5'
        }}
        onClick={() => {
          if (!errors?.products)
            appendProductAddonField({
              name: '',
              price: ''
            });
        }}
      >
        <PlusIcon />
        {t('orderForm.productAddons')}
      </button>

      {productAddonFields?.length > 0 &&
        productAddonFields.map((item, index) => {
          return (
            <AnimatePresence key={item.id}>
              {item && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      delay: 0.16,
                      duration: 0.3,
                      type: 'ease-in-out'
                    }
                  }}
                  className="addon-fields-wrapper"
                >
                  <div className="fields-wrapper">
                    <div className="name-price-delete">
                      <SharedOrderTextField
                        name={`products.${productIndex}.addon.${index}.name`}
                        transName="addonName"
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        sharedFormLabel={sharedFormLabel}
                        sm
                        errorMsg={
                          errors?.products?.length > 0 &&
                          errors.products[productIndex] &&
                          errors.products[productIndex]?.addon &&
                          errors.products[productIndex]?.addon?.length &&
                          errors.products[productIndex].addon[index] &&
                          errors.products[productIndex].addon[index]?.name &&
                          errors.products[productIndex].addon[index]?.name
                            ?.message
                        }
                        validateStatus={
                          errors?.products?.length > 0 &&
                          errors.products[productIndex] &&
                          errors.products[productIndex]?.addon &&
                          errors.products[productIndex]?.addon?.length &&
                          errors.products[productIndex].addon[index] &&
                          errors.products[productIndex].addon[index]?.name
                            ? 'error'
                            : ''
                        }
                      />
                      <div className="price-delete">
                        <SharedOrderTextField
                          name={`products.${productIndex}.addon.${index}.price`}
                          transName="addonPrice"
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          sharedFormLabel={sharedFormLabel}
                          prefix={t('currency.sar')}
                          sm
                          errorMsg={
                            errors?.products?.length > 0 &&
                            errors.products[productIndex] &&
                            errors.products[productIndex]?.addon &&
                            errors.products[productIndex]?.addon?.length &&
                            errors.products[productIndex].addon[index] &&
                            errors.products[productIndex].addon[index]?.price &&
                            errors.products[productIndex].addon[index]?.price
                              ?.message
                          }
                          validateStatus={
                            errors?.products?.length > 0 &&
                            errors.products[productIndex] &&
                            errors.products[productIndex]?.addon &&
                            errors.products[productIndex]?.addon?.length &&
                            errors.products[productIndex].addon[index] &&
                            errors.products[productIndex].addon[index]?.price
                              ? 'error'
                              : ''
                          }
                        />
                        <button
                          className="delete-addon-btn"
                          onClick={() => {
                            removeProductAddonField(index);
                          }}
                        >
                          <CloseIcon color="gray" />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          );
        })}
    </div>
  );
};

export default ProductAddonFields;
