/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import AntdTextField from '@antd/AntdTextField';
import { CheckMarkIcon, CloseIcon } from '@components/icons/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import getFormSchema from './regionFormSchema';
import './styles.scss';
import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  createRegionAction,
  updateRegionAction
} from '@redux/regions-slice/regionsSliceThunkApis';
import { AnimatePresence, motion } from 'framer-motion';
import { activeTypesArr } from '@mocks';
import AntdSelectOption from '@components/shared/antd-form-components/AntdSelectOption';
import FormCountrySelectOption from './FormCountrySelectOption';

const RegionForm = ({ fetchedObj, isSubmittingForm }) => {
  const { i18n } = useTranslation();

  const params = useParams();

  const defaultValues = {
    name_en: '',
    name_ar: '',
    country_id: '',
    status: ''
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      getFormSchema({
        fetchedObj,
        t
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  const fetchedCountries = useSelector(
    (store) => store.countries.fetchedCountries
  );

  // handle default values
  useEffect(() => {
    if (fetchedObj && params?.region_id && fetchedCountries?.length > 0) {
      setValue('name_en', fetchedObj?.name_en || defaultValues.name_en);
      setValue('name_ar', fetchedObj?.name_ar || defaultValues.name_ar);
      if (fetchedObj?.country?.id)
        setValue('country_id', String(fetchedObj.country.id));
      else setValue('country_id', '');
      setValue('status', String(fetchedObj?.status) || defaultValues.status);
    }
  }, [fetchedObj, params?.region_id, fetchedCountries]);

  const [form] = Form.useForm();
  const sharedFormLabel = (key) => t(`regionForm.${key}.label`);

  const resetFormInputs = () => {
    reset({
      name_en: fetchedObj?.name_en || '',
      name_ar: fetchedObj?.name_ar || '',
      country_id: fetchedObj?.country?.id ? String(fetchedObj.country.id) : '',
      status: fetchedObj?.status ? String(fetchedObj.status) : ''
    });
  };

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name_en', data.name_en);
    formData.append('name_ar', data.name_ar);
    formData.append('country_id', data.country_id);
    formData.append('status', data.status);
    if (!fetchedObj && !params?.region_id) {
      const action = await dispatch(
        createRegionAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action.payload.res;
      if (checkSuccessRes(res)) {
        resetFormInputs();
      }
    } else {
      formData.append('region_id', fetchedObj.id);
      await dispatch(
        updateRegionAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <div className="region-form-wrapper">
      <Form
        className="region-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body with-bg">
          <div className="form-header bold-font">
            {fetchedObj ? t('updateRegion') : t('newRegion')}
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name_en"
              type="text"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('name_en')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name_ar"
              type="text"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
              setValue={setValue}
            />
            <p className="custom-label">{sharedFormLabel('name_ar')}</p>
          </div>

          <FormCountrySelectOption
            label={sharedFormLabel('country_id')}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name="status"
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                activeTypesArr(t)?.length > 0 &&
                activeTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <p className="custom-label">{sharedFormLabel('status')}</p>
          </div>

          <div className="submit-cancel-btns">
            <AnimatePresence>
              {(watch('name_ar') ||
                watch('name_en') ||
                watch('country_id')) && (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: i18n.dir() === 'ltr' ? -92 : 92,
                    transition: {
                      duration: 0.8,
                      type: 'spring'
                    }
                  }}
                >
                  <Button
                    icon={<CloseIcon width={18} height={18} />}
                    className="cancel-btn"
                    onClick={resetFormInputs}
                  >
                    {t('cancel')}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={
                isSubmittingForm ? (
                  <LoadingOutlined />
                ) : (
                  <CheckMarkIcon width={18} height={18} />
                )
              }
              loading={isSubmittingForm}
            >
              {isSubmittingForm
                ? t('saving')
                : fetchedObj
                ? t('regionForm.editRegion')
                : t('regionForm.addRegion')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RegionForm;
