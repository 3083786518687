import OrdersTable from './orders-table';
import PageHeader from '@components/shared/page-header';
import { ShoppingCartIcon } from '@icons/index';
import { t } from 'i18next';
import PageWrapperMotion from '@components/shared/page-wrapper-motion';
import DeleteAllBtn from '@components/delete-all-btn';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDeleteMultiOrderModalOpenedAction,
  setSelectedRowsIdsAction
} from '@redux/orders-slice/ordersSlice';
import './styles.scss';

const OrdersPage = () => {
  const selectedRowsIds = useSelector((store) => store.orders.selectedRowsIds);
  const dispatch = useDispatch();
  return (
    <PageWrapperMotion>
      <div className="orders-page">
        <PageHeader
          title={t('orders')}
          icon={<ShoppingCartIcon />}
          breadcrumbItems={[
            {
              title: t('orders')
            }
          ]}
        />
        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiOrderModalOpenedAction(true))
            }
          />
        </div>
        <OrdersTable />
      </div>
    </PageWrapperMotion>
  );
};

export default OrdersPage;
