import './styles.scss';

export const DashboardIcon = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M13 21V11h8v10h-8zM3 13V3h8v10H3zm6-2V5H5v6h4zM3 21v-6h8v6H3zm2-2h4v-2H5v2zm10 0h4v-6h-4v6zM13 3h8v6h-8V3zm2 2v2h4V5h-4z"
      />
    </svg>
  );
};
//
export const ArrowRightIcon = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
      />
    </svg>
  );
};

///
export const ArrowLeftIcon = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
      />
    </svg>
  );
};

///
///
export const MenuUnFold = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0H24V24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M21 18v2H3v-2h18zM17.404 3.904L22 8.5l-4.596 4.596-1.414-1.414L19.172 8.5 15.99 5.318l1.414-1.414zM12 11v2H3v-2h9zm0-7v2H3V4h9z"
      />
    </svg>
  );
};

export const MenuFold = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0H24V24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M21 18v2H3v-2h18zM6.596 3.904L8.01 5.318 4.828 8.5l3.182 3.182-1.414 1.414L2 8.5l4.596-4.596zM21 11v2h-9v-2h9zm0-7v2h-9V4h9z"
      />
    </svg>
  );
};

///

export const DropDownIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"
      />
    </svg>
  );
};

//

export const EyeOffIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        className="fill-path"
        d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
      />
    </svg>
  );
};

//
export const EyeOnIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        className="fill-path"
        d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
      />
    </svg>
  );
};
//
export const LogoutIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"
      />
    </svg>
  );
};
//
export const LogoutRtlIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"
      />
    </svg>
  );
};
//
export const MenuIcon = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z"
      />
    </svg>
  );
};
//
export const MenuRtlIcon = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"
      />
    </svg>
  );
};
//
export const UserIcon = ({ color, width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color || '#000'}
        className="fill-path"
        d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
      />
    </svg>
  );
};
//
export const ProfileIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"
      />
    </svg>
  );
};
//
export const SettingsIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M2.213 14.06a9.945 9.945 0 0 1 0-4.12c1.11.13 2.08-.237 2.396-1.001.317-.765-.108-1.71-.986-2.403a9.945 9.945 0 0 1 2.913-2.913c.692.877 1.638 1.303 2.403.986.765-.317 1.132-1.286 1.001-2.396a9.945 9.945 0 0 1 4.12 0c-.13 1.11.237 2.08 1.001 2.396.765.317 1.71-.108 2.403-.986a9.945 9.945 0 0 1 2.913 2.913c-.877.692-1.303 1.638-.986 2.403.317.765 1.286 1.132 2.396 1.001a9.945 9.945 0 0 1 0 4.12c-1.11-.13-2.08.237-2.396 1.001-.317.765.108 1.71.986 2.403a9.945 9.945 0 0 1-2.913 2.913c-.692-.877-1.638-1.303-2.403-.986-.765.317-1.132 1.286-1.001 2.396a9.945 9.945 0 0 1-4.12 0c.13-1.11-.237-2.08-1.001-2.396-.765-.317-1.71.108-2.403.986a9.945 9.945 0 0 1-2.913-2.913c.877-.692 1.303-1.638.986-2.403-.317-.765-1.286-1.132-2.396-1.001zM4 12.21c1.1.305 2.007 1.002 2.457 2.086.449 1.085.3 2.22-.262 3.212.096.102.195.201.297.297.993-.562 2.127-.71 3.212-.262 1.084.45 1.781 1.357 2.086 2.457.14.004.28.004.42 0 .305-1.1 1.002-2.007 2.086-2.457 1.085-.449 2.22-.3 3.212.262.102-.096.201-.195.297-.297-.562-.993-.71-2.127-.262-3.212.45-1.084 1.357-1.781 2.457-2.086.004-.14.004-.28 0-.42-1.1-.305-2.007-1.002-2.457-2.086-.449-1.085-.3-2.22.262-3.212a7.935 7.935 0 0 0-.297-.297c-.993.562-2.127.71-3.212.262C13.212 6.007 12.515 5.1 12.21 4a7.935 7.935 0 0 0-.42 0c-.305 1.1-1.002 2.007-2.086 2.457-1.085.449-2.22.3-3.212-.262-.102.096-.201.195-.297.297.562.993.71 2.127.262 3.212C6.007 10.788 5.1 11.485 4 11.79c-.004.14-.004.28 0 .42zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
};
//
export const FullScreenIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"
      />
    </svg>
  );
};
//
export const FullScreenExitIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M18 7h4v2h-6V3h2v4zM8 9H2V7h4V3h2v6zm10 8v4h-2v-6h6v2h-4zM8 15v6H6v-4H2v-2h6z"
      />
    </svg>
  );
};
//
//
export const ShoppingCartIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 2a6 6 0 0 1 6 6v1h4v2h-1.167l-.757 9.083a1 1 0 0 1-.996.917H4.92a1 1 0 0 1-.996-.917L3.166 11H2V9h4V8a6 6 0 0 1 6-6zm6.826 9H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2zm-5-9a4 4 0 0 0-3.995 3.8L8 8v1h8V8a4 4 0 0 0-3.8-3.995L12 4z"
      />
    </svg>
  );
};
//
export const StatisticsIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0H24V24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z"
      />
    </svg>
  );
};
//
export const PlusCircled = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
      />
    </svg>
  );
};
//
export const PlusIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
      />
    </svg>
  );
};
//
export const DriverIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z"
      />
    </svg>
  );
};
//
export const MoneyIcon = ({ color = '#000000e0', width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM12.0049 20.0029C16.4232 20.0029 20.0049 16.4212 20.0049 12.0029C20.0049 7.58465 16.4232 4.00293 12.0049 4.00293C7.5866 4.00293 4.00488 7.58465 4.00488 12.0029C4.00488 16.4212 7.5866 20.0029 12.0049 20.0029ZM9.00488 13.0029H8.00488V11.0029H9.00488V10.0029C9.00488 8.06993 10.5719 6.50293 12.5049 6.50293C13.9741 6.50293 15.2319 7.40823 15.7509 8.69143L13.7644 9.18804C13.4971 8.77572 13.0329 8.50293 12.5049 8.50293C11.6765 8.50293 11.0049 9.1745 11.0049 10.0029V11.0029H14.0049V13.0029H11.0049V15.0029H16.0049V17.0029H8.00488V15.0029H9.00488V13.0029Z"
      ></path>
    </svg>
  );
};
//
export const VehicleIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"
      />
    </svg>
  );
};
export const WalletIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      width={width}
      height={height}
      className="svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M18.0049 7H21.0049C21.5572 7 22.0049 7.44772 22.0049 8V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V4C2.00488 3.44772 2.4526 3 3.00488 3H18.0049V7ZM4.00488 9V19H20.0049V9H4.00488ZM4.00488 5V7H16.0049V5H4.00488ZM15.0049 13H18.0049V15H15.0049V13Z"
      ></path>
    </svg>
  );
};
export const FileTransfer = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM12 11V8L16 12L12 16V13H8V11H12Z"
      ></path>
    </svg>
  );
};
export const CarIcon = ({ color = '#000', width = 24, height = 24, props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M19 20H5v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V11l2.48-5.788A2 2 0 0 1 6.32 4H17.68a2 2 0 0 1 1.838 1.212L22 11v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1zm1-7H4v5h16v-5zM4.176 11h15.648l-2.143-5H6.32l-2.143 5zM6.5 17a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm11 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
      />
    </svg>
  );
};
//
export const PagesIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M5 8v12h14V8H5zm0-2h14V4H5v2zm15 16H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zM7 10h4v4H7v-4zm0 6h10v2H7v-2zm6-5h4v2h-4v-2z"
      />
    </svg>
  );
};
//
export const CountryIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"
      />
    </svg>
  );
};
//
export const CityIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
      />
    </svg>
  );
};
//
export const AreaIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M4 6.143v12.824l5.065-2.17 6 3L20 17.68V4.857l1.303-.558a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V7l2-.857zm12.243 5.1L12 15.485l-4.243-4.242a6 6 0 1 1 8.486 0zM12 12.657l2.828-2.829a4 4 0 1 0-5.656 0L12 12.657z"
      />
    </svg>
  );
};
//
export const TimeLineIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
      />
    </svg>
  );
};
//
//
export const CloseIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
      />
    </svg>
  );
};
//
//
export const StoreIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className="svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        className="fill-path"
        d="M21 11.6458V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11.6458C2.37764 10.9407 2 10.0144 2 9V3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V9C22 10.0144 21.6224 10.9407 21 11.6458ZM19 12.874C18.6804 12.9562 18.3453 13 18 13C16.8053 13 15.7329 12.4762 15 11.6458C14.2671 12.4762 13.1947 13 12 13C10.8053 13 9.73294 12.4762 9 11.6458C8.26706 12.4762 7.19469 13 6 13C5.6547 13 5.31962 12.9562 5 12.874V20H19V12.874ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9V4H4V9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z"
      ></path>
    </svg>
  );
};
//
export const BikeIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className="svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        className="fill-path"
        d="M16 1C16.5523 1 17 1.44772 17 2V3H22V9H19.9813L22.7271 16.5439C22.9033 16.9948 23 17.4856 23 17.999C23 20.2082 21.2091 21.999 19 21.999C17.1365 21.999 15.5707 20.7247 15.1263 19H10.874C10.4299 20.7252 8.86384 22 7 22C5.05551 22 3.43508 20.6125 3.07474 18.7736C2.43596 18.4396 2 17.7707 2 17V7C2 6.44772 2.44772 6 3 6H10C10.5523 6 11 6.44772 11 7V12C11 12.5523 11.4477 13 12 13H14C14.5523 13 15 12.5523 15 12V3H12V1H16ZM7 16C5.89543 16 5 16.8954 5 18C5 19.1046 5.89543 20 7 20C8.10457 20 9 19.1046 9 18C9 16.8954 8.10457 16 7 16ZM19 15.999C17.8954 15.999 17 16.8944 17 17.999C17 19.1036 17.8954 19.999 19 19.999C20.1046 19.999 21 19.1036 21 17.999C21 17.7587 20.9576 17.5282 20.8799 17.3148L20.8635 17.2714C20.5725 16.5266 19.8479 15.999 19 15.999ZM17.853 9H17V12C17 13.6569 15.6569 15 14 15H12C10.3431 15 9 13.6569 9 12H4V15.3542C4.73294 14.5238 5.80531 14 7 14C8.86384 14 10.4299 15.2748 10.874 17H15.1258C15.5695 15.2743 17.1358 13.999 19 13.999C19.2368 13.999 19.4688 14.0196 19.6943 14.0591L17.853 9ZM9 8H4V10H9V8ZM20 5H17V7H20V5Z"
      ></path>
    </svg>
  );
};
//
//
export const BagIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className="svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        className="fill-path"
        d="M6.50488 2H17.5049C17.8196 2 18.116 2.14819 18.3049 2.4L21.0049 6V21C21.0049 21.5523 20.5572 22 20.0049 22H4.00488C3.4526 22 3.00488 21.5523 3.00488 21V6L5.70488 2.4C5.89374 2.14819 6.19013 2 6.50488 2ZM19.0049 8H5.00488V20H19.0049V8ZM18.5049 6L17.0049 4H7.00488L5.50488 6H18.5049ZM9.00488 10V12C9.00488 13.6569 10.348 15 12.0049 15C13.6617 15 15.0049 13.6569 15.0049 12V10H17.0049V12C17.0049 14.7614 14.7663 17 12.0049 17C9.24346 17 7.00488 14.7614 7.00488 12V10H9.00488Z"
      ></path>
    </svg>
  );
};
//
//
export const WarningIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className="svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        className="fill-path"
        d="M4.00098 20V14C4.00098 9.58172 7.5827 6 12.001 6C16.4193 6 20.001 9.58172 20.001 14V20H21.001V22H3.00098V20H4.00098ZM6.00098 20H18.001V14C18.001 10.6863 15.3147 8 12.001 8C8.68727 8 6.00098 10.6863 6.00098 14V20ZM11.001 2H13.001V5H11.001V2ZM19.7792 4.80761L21.1934 6.22183L19.0721 8.34315L17.6578 6.92893L19.7792 4.80761ZM2.80859 6.22183L4.22281 4.80761L6.34413 6.92893L4.92991 8.34315L2.80859 6.22183ZM7.00098 14C7.00098 11.2386 9.23956 9 12.001 9V11C10.3441 11 9.00098 12.3431 9.00098 14H7.00098Z"
      ></path>
    </svg>
  );
};
//
//
export const CalendarIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
      />
    </svg>
  );
};
//
//
export const CheckMarkIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20,
  style
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
      style={style}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
      />
    </svg>
  );
};
//
//
export const CheckMarkCircleIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
      />
    </svg>
  );
};
//
//
export const CloseCircleIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
      />
    </svg>
  );
};
//
//
export const BarChartSolid = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M2 13h6v8H2v-8zM9 3h6v18H9V3zm7 5h6v13h-6V8z"
      />
    </svg>
  );
};
//
//
export const PieChartSolid = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M11 2.05V13h10.95c-.501 5.053-4.765 9-9.95 9-5.523 0-10-4.477-10-10 0-5.185 3.947-9.449 9-9.95zm2-1.507C18.553 1.02 22.979 5.447 23.457 11H13V.543z"
      />
    </svg>
  );
};
//
export const LineChartSolid = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0H24V24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M5 3v16h16v2H3V3h2zm14.94 2.94l2.12 2.12L16 14.122l-3-3-3.94 3.94-2.12-2.122L13 6.88l3 3 3.94-3.94z"
      />
    </svg>
  );
};
//
//
export const BubbleChartSolid = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0L24 0 24 24 0 24z" />
      <path
        fill={color}
        className="fill-path"
        d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm8.5-10C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2z"
      />
    </svg>
  );
};
//
//
export const ArrowUpIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M13 7.828V20h-2V7.828l-5.364 5.364-1.414-1.414L12 4l7.778 7.778-1.414 1.414L13 7.828z"
      />
    </svg>
  );
};
//
//
export const TrashIcon = ({ color = '#000000e0', width = 20, height = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
      />
    </svg>
  );
};
//
//
export const UpdateIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
      />
    </svg>
  );
};
//
export const UploadCloudIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 13v4h-2v-4H8l4-5 4 5h-3z"
      />
    </svg>
  );
};
//
//
export const SearchIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M11 2c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 16c3.867 0 7-3.133 7-7 0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7zm8.485.071l2.829 2.828-1.415 1.415-2.828-2.829 1.414-1.414z"
      />
    </svg>
  );
};
//
//
export const ReloadIcon = ({
  color = '#000000e0',
  width = 20,
  height = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className="svg-icon"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={color}
        className="fill-path"
        d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"
      />
    </svg>
  );
};
//

export const RadioButtonFilled = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle
        cx="12"
        cy="12"
        r="10.25"
        stroke={color || '#58B030'}
        strokeWidth="1.5"
      />
      <circle cx="12" cy="12" r="5.5" fill={color || '#58B030'} />
    </svg>
  );
};

export const RadioButtonEmpty = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <circle
        cx="11"
        cy="11"
        r="10.25"
        stroke={color || '#98A6B3'}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default RadioButtonEmpty;
