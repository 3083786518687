/* eslint-disable eqeqeq */
import { accountTypes } from '@mocks/index';
import appRouterLinks from '@routes/appRouterLinks';
import { ProfileIcon, SettingsIcon } from '@icons/index';

const getProfileMenuLinks = (i18n, t, user) => {
  const userLabelTransString = (key) => t(`userDashboard.${key}`);
  const merchentLabelTransString = (key) => t(`companyDashboard.${key}`);

  if (user?.accountType == accountTypes()?.user)
    return [
      {
        id: 1,
        name: userLabelTransString('settings'),
        link: appRouterLinks?.profileRoute,
        icon: <ProfileIcon />
      }
      // {
      //   id: 2,
      //   name: userLabelTransString('fav'),
      //   link: appRouterLinks?.myFavRoute,
      //   icon: 'test icon'
      // },
      // {
      //   id: 3,
      //   name: userLabelTransString('orders'),
      //   link: appRouterLinks?.userOrdersRoute,
      //   icon: 'test icon'
      // },
      // {
      //   id: 4,
      //   name: userLabelTransString('subPlans'),
      //   link: appRouterLinks?.userSubscriptionRoute,
      //   icon: 'test icon'
      // }
    ];
  else if (
    user?.accountType == accountTypes()?.company ||
    user?.accountType == accountTypes()?.employee
  ) {
    return [
      {
        id: 1,
        name: merchentLabelTransString('settings'),
        link: appRouterLinks?.profileRoute,
        icon: <SettingsIcon />
      }
      // {
      //   id: 2,
      //   name: merchentLabelTransString('orders'),
      //   link: appRouterLinks?.merchentDashboard,
      //   icon: 'test icon'
      // }
    ];
  }
  return [];
};

export default getProfileMenuLinks;
