/* eslint-disable eqeqeq */
import CustomTag from '@components/shared/custom-tag';
import {
  confirmModalTypesValues,
  withdrawalsStatusArr,
  withdrawalsStatusValues
} from '@mocks/index';
import { Link } from 'react-router-dom';
import appRouterLinks from '@routes/appRouterLinks';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setPreviewBrandImgModalOpened,
  setIsLoading,
  setConfirmModal,
  setDeleteModalOpened,
  token,
  t,
  dispatch,
  navigate
}) => {
  const renderChangeStatusSwitch = (record) => {
    const found = withdrawalsStatusArr(t).find(
      (obj) => obj?.value == record.status
    );
    if (found)
      return (
        <div className="action-btns-wrap">
          {record.status === withdrawalsStatusValues?.inReview ? (
            <>
              <button
                type="button"
                className={`accept-btn ${
                  record?.status === withdrawalsStatusValues?.accepted
                    ? 'disabled'
                    : ''
                }`}
                onClick={() => {
                  if (record?.status !== withdrawalsStatusValues?.accepted) {
                    setSelectedTableRow(record);
                    setConfirmModal({
                      opened: true,
                      type: confirmModalTypesValues.successModal
                    });
                  }
                }}
              >
                {t('accept')}
              </button>
              <button
                type="button"
                className={`reject-btn ${
                  record?.status === withdrawalsStatusValues?.rejected
                    ? 'disabled'
                    : ''
                }`}
                onClick={() => {
                  if (record?.status !== withdrawalsStatusValues?.rejected) {
                    setSelectedTableRow(record);
                    setConfirmModal({
                      opened: true,
                      type: confirmModalTypesValues.errorModal
                    });
                  }
                }}
              >
                {t('reject')}
              </button>
            </>
          ) : (
            '----'
          )}
        </div>
      );
    return '---';
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.amount ? `${record.amount} ${t('currency.sar')}` : '---'}
          </div>
        );
      }
    },
    {
      title: t('withdrawalStatus'),
      dataIndex: 'withdrawalStatus',
      width: 182,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.status ? (
              <CustomTag
                status={record?.status}
                arr={withdrawalsStatusArr(t)}
              />
            ) : (
              '---'
            )}
          </div>
        );
      }
    },
    {
      title: t('driver'),
      dataIndex: 'driver.name',
      width: 152,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.user?.name && record?.user?.id ? (
              <Link
                style={{
                  textDecoration: 'underline'
                }}
                to={appRouterLinks.driverDetails(record?.user?.id)}
              >
                {record.user.name}
              </Link>
            ) : (
              '----'
            )}
          </div>
        );
      }
    },
    {
      title: t('wallet'),
      dataIndex: 'wallet',
      width: 212,
      render: (_, record) => {
        return record?.user?.profits
          ? `${record.user.profits} ${t('currency.sar')}`
          : '---';
      }
    },
    {
      title: t('requiredToBeRepaid'),
      dataIndex: 'requiredToBeRepaid',
      width: 222,
      render: (_, record) => {
        return record?.user?.requiredToBeRepaid
          ? `${record.user.requiredToBeRepaid} ${t('currency.sar')}`
          : '---';
      }
    },
    {
      title: t('date'),
      dataIndex: 'date',
      width: 122,
      render: (_, record) => {
        return record?.date ? record.date : '---';
      }
    },
    {
      title: t('changeStatus'),
      dataIndex: 'changeStatus',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {renderChangeStatusSwitch(record)}
          </div>
        );
      }
    }
  ];
};

export default TableColumns;
