const authenticationRouterLinks = {
  login: '/login',
  activateAccountRoute: '/activate-account',
  forgetPasswordRoute: '/forget-password',
  forgetPasswordActivateCodeRoute: '/forget-password/activation-code',
  forgetPasswordNewPasswordRoute: '/forget-password/new-password',
  forgetPasswordChangedSuccessfullyRoute: '/forget-password/success',
  notificationsRoute: '/notifications',
  profileRoute: '/profile'
};

export default authenticationRouterLinks;
