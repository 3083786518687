import checkSuccessRes from '@helpers/checkSuccessRes';
import {
  getAllWithdrawalAction,
  acceptOrRejectWithdrawalAction
} from './sliceActionsThunkApis';

export function getAllWithdrawal({ builder }) {
  const { pending, fulfilled, rejected } = getAllWithdrawalAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isLoadingWithdrawal = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isLoadingWithdrawal = false;
      if (checkSuccessRes(action?.payload?.res)) {
        const data = action?.payload?.res?.data?.data;
        sliceState.fetchedAllWithdrawals = data?.data;
        if (data?.pagination) sliceState.withdrawalPagination = data.pagination;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isLoadingWithdrawal = false;
    });
}

export function acceptOrRejectWithdrawal({ builder }) {
  const { pending, fulfilled, rejected } = acceptOrRejectWithdrawalAction;
  builder
    .addCase(pending, (sliceState, action) => {
      sliceState.isSubmittingWithdrawal = true;
    })
    .addCase(fulfilled, (sliceState, action) => {
      sliceState.isSubmittingWithdrawal = false;
      if (checkSuccessRes(action?.payload?.res)) {
        sliceState.confirmModal = {
          opened: false,
          type: ''
        };
        sliceState.fetchWithdrawalCount += 1;
      }
    })
    .addCase(rejected, (sliceState, action) => {
      sliceState.isSubmittingWithdrawal = false;
    });
}
