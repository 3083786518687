/* eslint-disable react-hooks/exhaustive-deps */
import AntdTable from '@components/shared/antd-table';
import ConfirmModal from '@components/shared/confirm-modal';
import ImageModal from '@components/shared/image-modal';
import { confirmModalTypesValues } from '@mocks/index';
import {
  setConfirmModalAction,
  setDeleteCountryModalOpenedAction,
  setDeleteMultiCountryModalOpenedAction,
  setPreviewCountryLogoModalOpened,
  setSelectedCountryAction,
  setSelectedRowsIdsAction
} from '@redux/countries-slice/countriesSlice';
import {
  allCountriesAction,
  changeCountryStatusAction,
  deleteCountryAction
} from '@redux/countries-slice/countriesSliceThunkApis';
import appRouterLinks from '@routes/appRouterLinks';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingModal from '@components/shared/loading-modal';
import './styles.scss';
import TableColumns from './TableColumns';

const CountriesTable = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const isLoadingCountries = useSelector(
    (store) => store.countries.isLoadingCountries
  );
  const countriesPagination = useSelector(
    (store) => store.countries.countriesPagination
  );
  const isSubmittingCountryStatus = useSelector(
    (store) => store.countries.isSubmittingCountryStatus
  );
  const isSubmittingCountry = useSelector(
    (store) => store.countries.isSubmittingCountry
  );
  const deleteCountryModalOpened = useSelector(
    (store) => store.countries.deleteCountryModalOpened
  );
  const fetchCountriesCount = useSelector(
    (store) => store.countries.fetchCountriesCount
  );
  const fetchedCountries = useSelector(
    (store) => store.countries.fetchedCountries
  );
  const selectedCountry = useSelector(
    (store) => store.countries.selectedCountry
  );
  const confirmModalType = useSelector(
    (store) => store.countries.confirmModal.type
  );
  const confirmModalOpened = useSelector(
    (store) => store.countries.confirmModal.opened
  );
  const previewCountryLogoModalOpened = useSelector(
    (store) => store.countries.previewCountryLogoModalOpened
  );
  const deleteMultiCountryModalOpened = useSelector(
    (store) => store.countries.deleteMultiCountryModalOpened
  );
  const selectedRowsIds = useSelector(
    (store) => store.countries.selectedRowsIds
  );
  const user = useSelector((store) => store.user.user);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        allCountriesAction({
          filterObj: {
            ...values
            // active: activeTypes.active,
          }
        })
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCountriesCount, search, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (fetchedCountries?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(fetchedCountries);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCountriesCount, fetchedCountries, fetchedCountries?.length]);

  let isMounted = true;
  const handleChangeStatus = () => {
    if (isMounted && selectedCountry?.id) {
      const formData = new FormData();
      formData.append('country_id', selectedCountry.id);
      dispatch(
        changeCountryStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDelete = () => {
    if (isMounted && selectedCountry?.id) {
      const formData = new FormData();
      formData.append('country_id', selectedCountry.id);
      dispatch(
        deleteCountryAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  // * Handle delete multiple entries
  const handleDeleteMulti = () => {
    if (isMounted && selectedRowsIds) {
      const formData = new FormData();
      formData.append('country_id', selectedRowsIds);
      dispatch(
        deleteCountryAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <>
      <AntdTable
        bordered
        // scroll={900}
        scroll={800}
        showHeader={true}
        className={`countries-table ${i18n.dir()}`}
        tableDataSource={tableDataSource}
        tableColumns={TableColumns}
        allFetchedTableData={fetchedCountries}
        setDeleteModalOpened={(v) =>
          dispatch(setDeleteCountryModalOpenedAction(v))
        }
        // setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        setSelectedTableRow={(v) => dispatch(setSelectedCountryAction(v))}
        setConfirmModal={(v) => dispatch(setConfirmModalAction(v))}
        setPreviewCountryLogoModalOpened={(v) =>
          dispatch(setPreviewCountryLogoModalOpened(v))
        }
        //
        isLoadingTableData={isLoadingCountries}
        user={user}
        // handleRowClick={(record) => {
        //   navigate(appRouterLinks.countryDetails(record?.id));
        // }}
        dispatch={dispatch}
        navigate={navigate}
        hasPagination={true}
        tablePagination={countriesPagination}
        baseTablePageUrl={`${appRouterLinks.countries}?searchText=${
          values?.searchText || ''
        }&status=${values?.status || ''}`}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            dispatch(setSelectedRowsIdsAction(selectedRowKeys.join(',')));
          }
        }}
      />

      {/* confirm modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmModalOpened}
        modalType={confirmModalType}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedCountryAction(null));
          }
          dispatch(
            setConfirmModalAction({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeStatus}
      >
        {confirmModalType === confirmModalTypesValues?.successModal && (
          <h3>
            {`${t('sureActivate')}`}{' '}
            <span className="bold-font">{selectedCountry?.name || ''} !?</span>{' '}
          </h3>
        )}
        {confirmModalType === confirmModalTypesValues?.errorModal && (
          <h3>
            {`${t('sureDeactivate')}`}{' '}
            <span className="bold-font">{selectedCountry?.name || ''} !?</span>{' '}
          </h3>
        )}
      </ConfirmModal>
      {/* end confirm modal */}

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteCountryModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedCountryAction(null));
          }
          dispatch(setDeleteCountryModalOpenedAction(v));
        }}
        handleAccept={handleDelete}
      >
        <h3>
          {`${t('sureDelete')}`}{' '}
          <span className="bold-font">{selectedCountry?.name || ''} !?</span>{' '}
        </h3>
      </ConfirmModal>
      {/* end delete modal */}

      {/* delete multi modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteMultiCountryModalOpened}
        modalType={confirmModalTypesValues.errorModal}
        setModalOpened={(v) => {
          dispatch(setDeleteMultiCountryModalOpenedAction(v));
        }}
        handleAccept={handleDeleteMulti}
      >
        <h3>{`${t('sureDeleteSelected')}`}</h3>
      </ConfirmModal>
      {/* end delete multi modal */}

      {/* Preview image modals */}
      <ImageModal
        title={t('countryLogo')}
        open={previewCountryLogoModalOpened}
        onCancel={() => {
          dispatch(setSelectedCountryAction(null));
          dispatch(setPreviewCountryLogoModalOpened(false));
        }}
        src={selectedCountry?.logo}
      />

      {/* Preview image modals */}

      {(isSubmittingCountry || isSubmittingCountryStatus) && <LoadingModal />}
    </>
  );
};

export default CountriesTable;
