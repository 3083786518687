import AntdTextField from '@components/shared/antd-form-components/AntdTextField';
import { useTranslation } from 'react-i18next';

const SharedOrderTextField = ({
  name,
  transName,
  control,
  setValue,
  errors,
  sharedFormLabel,
  prefix = '',
  errorMsg = '',
  validateStatus = '',
  sm,
  ...props
}) => {
  const { i18n } = useTranslation();
  return (
    <div
      className={`custom-text-field-wrapper ${
        prefix ? 'with-prefix' : ''
      } ${i18n.dir()} ${sm ? 'sm' : ''}`}
    >
      <AntdTextField
        className={`custom-text-field`}
        name={name}
        type="text"
        errorMsg={!errorMsg ? errors[name]?.message : errorMsg}
        validateStatus={
          !validateStatus ? (errors[name] ? 'error' : '') : validateStatus
        }
        control={control}
        setValue={setValue}
        {...props}
      />
      <p className="custom-label">{sharedFormLabel(transName)}</p>
      {prefix ? <span className="prefix-span">{prefix}</span> : null}
    </div>
  );
};

export default SharedOrderTextField;
