/* eslint-disable react-hooks/exhaustive-deps */
// import AppDrawer from "@components/AppDrawer";
import AppRoutes from '@routes/AppRoutes';
import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import axiosDefaults from './axiosDefaults';
import AuthLayout from './components/app-layout/auth-layout';
import GeneralLayout from './components/app-layout/general-layout';
import { userInfoAction } from '@redux/user-slice/userSliceThunkApis';
import './i18n';
import {
  removeCurrentUserAction,
  setNotAuthedModalOpened
} from './redux/user-slice/userSlice';

function App() {
  const user = useSelector((store) => store.user.user);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  axiosDefaults(
    user,
    () => dispatch(removeCurrentUserAction()),
    (v) => dispatch(setNotAuthedModalOpened(v)),
    i18n.language
  );

  // const valid = useValidateImageURL('https://picsum.photos/200/300');
  // console.log('🚀 ~ file: App.js:33 ~ App ~ const:', valid);

  useEffect(() => {
    console.log('user : ', user);
    if (user?.token) {
      console.log(user);
      dispatch(userInfoAction());
    }
    // else {
    //   dispatch(removeCurrentUserAction());
    // }
  }, [user, i18n.language]);

  const renderApp = () => {
    if (user) {
      return (
        <div className="App">
          <AuthLayout>
            <AppRoutes />
          </AuthLayout>
        </div>
      );
    }
    return (
      <div className="App">
        <GeneralLayout>
          <AppRoutes />
        </GeneralLayout>
      </div>
    );
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  return (
    <ConfigProvider
      direction={i18n.dir()}
      theme={{
        token: {
          colorPrimary: '#D0AE6C',
          fontFamily: "'IBMPlexSans-Regular', 'IBMPlexSansArabic-Regular'"
        }
      }}
    >
      <ToastContainer closeButton={false} />
      {renderApp()}
    </ConfigProvider>
  );
}

export default App;
