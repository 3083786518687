/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from '@components/shared/page-header';
import { CarIcon, PlusIcon } from '@icons/index';
import appRouterLinks from '@routes/appRouterLinks';
import { Button } from 'antd';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import CitiesTable from './cities-table';
import TableFilter from './cities-table/table-filter';
import {
  setSelectedRowsIdsAction,
  setDeleteMultiCityModalOpenedAction
} from '@redux/cities-slice/citiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteAllBtn from '@components/delete-all-btn';

const CitiesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedRowsIds = useSelector((store) => store.cities.selectedRowsIds);

  return (
    <div className="cities-page">
      <PageHeader
        title={t('cities')}
        icon={<CarIcon />}
        breadcrumbItems={[
          {
            title: t('cities')
          }
        ]}
      />

      <div className="table-add-btn-filter-wrapper">
        <Button
          onClick={() => {
            navigate(appRouterLinks.newCity);
          }}
          className="new-filter-btn"
          type="primary"
        >
          <PlusIcon />
          {t('addCity')}
        </Button>

        <div className="table-filter-delete-all-btn">
          <DeleteAllBtn
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIds={(v) => dispatch(setSelectedRowsIdsAction(v))}
            onClickDelete={() =>
              dispatch(setDeleteMultiCityModalOpenedAction(true))
            }
          />
          <TableFilter />
        </div>
      </div>
      <CitiesTable />
    </div>
  );
};

export default CitiesPage;
