import { useContext } from 'react';
import { useSelector } from 'react-redux';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import ForgetPasswordChangedSuccessfullyPage from '../../pages/forget-password-page/ForgetPasswordChangedSuccessfullyPage';
import ForgetPasswordEnterCodePage from '../../pages/forget-password-page/ForgetPasswordEnterCodePage';
import ForgetPasswordNewPasswordPage from '../../pages/forget-password-page/ForgetPasswordNewPasswordPage';
import ForgetPasswordPage from '../../pages/forget-password-page/ForgetPasswordPage';
import sharedRouterLinks from '../shared-routes/sharedRouterLinks';
import LoginPage from '@pages/login-page/LoginPage';
import appRouterLinks from '../appRouterLinks';
import { Route, Navigate } from 'react-router-dom';
import authenticationRouterLinks from './authenticationRouterLinks';

const AuthenticationRoutes = () => {
  const loggedIn = useSelector((store) => store.user.loggedIn);
  const user = useSelector((store) => store.user.user);
  const { user: forgetPasswordUser } = useContext(ForgetPasswordContext);

  ////////////////////////////////////////////
  /////////// Forget password ///////////////
  const renderForgetPasswordPageRoute = () => {
    if (loggedIn) {
      <Navigate to={authenticationRouterLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <ForgetPasswordPage />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then navigate to changePassword enter activation code route
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return (
            <Navigate
              to={authenticationRouterLinks?.forgetPasswordActivateCodeRoute}
            />
          );
        // else if the forgetPasswordUser entered a success code then navigate to changePassword enter new password route
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return (
            <Navigate
              to={authenticationRouterLinks?.forgetPasswordNewPasswordRoute}
            />
          );
        //else  if the forgetPasswordUser changed password then navigate to the changed password successfully route
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return (
            <Navigate
              to={
                authenticationRouterLinks?.forgetPasswordChangedSuccessfullyRoute
              }
            />
          );
        // else
        return <Navigate to={authenticationRouterLinks?.login} />;
      }
    }
  };
  /////////////////
  /////////////////
  const renderForgetPasswordEnterCodeRoute = () => {
    if (loggedIn) {
      <Navigate to={sharedRouterLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <Navigate to={authenticationRouterLinks?.forgetPasswordRoute} />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then return enter activation code page
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return <ForgetPasswordEnterCodePage />;
        // else if the forgetPasswordUser entered a success code then navigate to changePassword enter new password route
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return (
            <Navigate
              to={authenticationRouterLinks?.forgetPasswordNewPasswordRoute}
            />
          );
        //else  if the forgetPasswordUser changed password then navigate to the changed password successfully route
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return (
            <Navigate
              to={
                authenticationRouterLinks?.forgetPasswordChangedSuccessfullyRoute
              }
            />
          );
        // else
        return <Navigate to={authenticationRouterLinks?.login} />;
      }
    }
  };

  ////////////////
  ////////////////
  const renderForgetPasswordEnterNewPassRoute = () => {
    if (loggedIn) {
      <Navigate to={authenticationRouterLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <Navigate to={authenticationRouterLinks?.forgetPasswordRoute} />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then navigate to changePassword enter activation code route
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return (
            <Navigate
              to={authenticationRouterLinks?.forgetPasswordActivateCodeRoute}
            />
          );
        // else if the forgetPasswordUser entered a success code then return changePassword new password page
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return <ForgetPasswordNewPasswordPage />;
        //else  if the forgetPasswordUser changed password then navigate to the changed password successfully route
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return (
            <Navigate
              to={
                authenticationRouterLinks?.forgetPasswordChangedSuccessfullyRoute
              }
            />
          );
        // else
        return <Navigate to={authenticationRouterLinks?.login} />;
      }
    }
  };
  ////////////////
  ////////////////
  const renderForgetPasswordSuccessfullRoute = () => {
    if (loggedIn) {
      <Navigate to={authenticationRouterLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <Navigate to={authenticationRouterLinks?.forgetPasswordRoute} />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then navigate to changePassword enter activation code route
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return (
            <Navigate
              to={authenticationRouterLinks?.forgetPasswordActivateCodeRoute}
            />
          );
        // else if the forgetPasswordUser entered a success code then navigate to changePassword enter new password route
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return (
            <Navigate
              to={authenticationRouterLinks?.forgetPasswordNewPasswordRoute}
            />
          );
        //else  if the forgetPasswordUser changed password then return changed password successfully page
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return <ForgetPasswordChangedSuccessfullyPage />;
        // else
        return <Navigate to={authenticationRouterLinks?.login} />;
      }
    }
  };
  ////////////////////////////////////////////
  /////////// End Forget password ///////////////

  const renderLoginRoute = () => {
    if (!loggedIn) {
      return <LoginPage />;
    } else if (loggedIn && user && !user?.activated) {
      return <Navigate to={authenticationRouterLinks?.activateAccountRoute} />;
    } else {
      return <Navigate to={appRouterLinks?.adminHome} />;
      // if (user?.accountType == accountTypes()?.user)
      // return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };

  return [
    <Route
      exact
      path={authenticationRouterLinks?.login}
      element={renderLoginRoute()}
      key="login_key"
    />,

    <Route
      exact
      path={authenticationRouterLinks?.forgetPasswordRoute}
      element={renderForgetPasswordPageRoute()}
      key="forget_pass_key"
    />,

    <Route
      exact
      path={authenticationRouterLinks?.forgetPasswordActivateCodeRoute}
      element={renderForgetPasswordEnterCodeRoute()}
      key="enter_pass_code_key"
    />,

    <Route
      exact
      path={authenticationRouterLinks?.forgetPasswordNewPasswordRoute}
      element={renderForgetPasswordEnterNewPassRoute()}
      key="enter_new_pass_key"
    />,

    <Route
      exact
      path={authenticationRouterLinks?.forgetPasswordChangedSuccessfullyRoute}
      element={renderForgetPasswordSuccessfullRoute()}
      key="success_pass_key"
    />
  ];
};

export default AuthenticationRoutes;
