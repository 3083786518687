import { initializeApp } from 'firebase/app';
// import { getMessaging, isSupported } from 'firebase/messaging';
// import { getAuth } from 'firebase/auth'
// import 'firebase/database';
// import 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBqgPgP73LnE8QOr1C2PLJdO5d9Mhec4S4',
  authDomain: 'driver-app-1c82d.firebaseapp.com',
  databaseURL: 'https://driver-app-1c82d-default-rtdb.firebaseio.com',
  projectId: 'driver-app-1c82d',
  storageBucket: 'driver-app-1c82d.appspot.com',
  messagingSenderId: '1087521931993',
  appId: '1:1087521931993:web:d8d805ae8b2f94996874c8',
  measurementId: 'G-S7CSZFRSQ6'
};

const firebaseApp = initializeApp(firebaseConfig);

// let messaging = null;
// if (isSupported()) {
//   messaging = getMessaging(firebaseApp);
// }

// const auth = getAuth(app);

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

// export const getCurrentToken = async (setIsTokenFound) => {
//   let currentToken = '';
//   if (messaging) {
//     try {
//       currentToken = await messaging.getToken({ vapidKey: publicKey });
//       if (currentToken) {
//         setIsTokenFound(true);
//         console.log('current token : ', currentToken);
//       } else {
//         setIsTokenFound(false);
//       }
//     } catch (error) {
//       console.log('An error occurred while retrieving token. ', error);
//     }
//   }

//   return currentToken;
// };

// foreground FCM
// export const onMessageListener = () => {
//   if (messaging) {
//     return new Promise((resolve) => {
//       messaging.onMessage((payload) => {
//         resolve(payload);
//       });
//     });
//   }
// };

export const db = getDatabase(firebaseApp);

export default firebaseApp;
