import { createSlice } from '@reduxjs/toolkit';
import {
  getDrivers,
  getSingleDriver,
  changeDriverStatus,
  changeSingleDriverStatus,
  deleteDriver,
  deleteSingleDriver
} from './sliceExtraReducers';

const initialState = {
  // all drivers
  isLoadingDrivers: false,
  fetchDriversCount: 0,
  fetchedDrivers: [],
  selectedDriver: null,
  selectedRowsIds: '',
  isSubmittingDriver: false,
  driversPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  /////////////////////////
  // single driver
  isLoadingSingleDriver: false,
  fetchSingleDriverCount: 0,
  fetchedSingleDriver: null,
  isSubmittingSingleDriver: false,
  ///////////////////////
  //
  previewDriverImgModalOpened: false,
  previewCarFrontImgModalOpened: false,
  previewCarBackImgModalOpened: false,
  //
  deleteDriverModalOpened: false,
  deleteMultiDriverModalOpened: false,
  confirmModal: {
    opened: '',
    type: ''
  }
};

const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    setSelectedDriverAction: (sliceState, action) => {
      sliceState.selectedDriver = action.payload;
    },
    setDeleteDriverModalOpenedAction: (sliceState, action) => {
      sliceState.deleteDriverModalOpened = action.payload;
    },
    setConfirmModalAction: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setPreviewDriverImgModalOpened: (sliceState, action) => {
      sliceState.previewDriverImgModalOpened = action.payload;
    },
    setPreviewCarFrontImgModalOpened: (sliceState, action) => {
      sliceState.previewCarFrontImgModalOpened = action.payload;
    },
    setPreviewCarBackImgModalOpened: (sliceState, action) => {
      sliceState.previewCarBackImgModalOpened = action.payload;
    },
    setSelectedRowsIdsAction: (sliceState, action) => {
      sliceState.selectedRowsIds = action.payload;
    },
    setDeleteMultiDriverModalOpenedAction: (sliceState, action) => {
      sliceState.deleteMultiDriverModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    getDrivers({ builder });
    getSingleDriver({ builder });
    changeDriverStatus({ builder });
    changeSingleDriverStatus({ builder });
    deleteDriver({ builder });
    deleteSingleDriver({ builder });
  }
});

const driversReducer = driversSlice.reducer;

export const {
  setSelectedDriverAction,
  setDeleteDriverModalOpenedAction,
  setConfirmModalAction,
  setPreviewDriverImgModalOpened,
  setPreviewCarFrontImgModalOpened,
  setPreviewCarBackImgModalOpened,
  setSelectedRowsIdsAction,
  setDeleteMultiDriverModalOpenedAction
} = driversSlice.actions;

export default driversReducer;
