/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import appRouterLinks from '@routes/appRouterLinks';
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import { ReloadIcon, SearchIcon } from '@components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { activeTypesArr } from '../../../../mocks';

const TableFilter = ({ isSubmittingForm }) => {
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const navigate = useNavigate();

  const defaultValues = {
    searchText: ''
  };

  const { control, handleSubmit, setValue, watch } = useForm({
    // resolver: yupResolver(

    //   )
    // ),
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues
    }
  });

  // handle default values
  useEffect(() => {
    setValue('searchText', values?.searchText || defaultValues?.searchText);
  }, [search]);

  const onSubmit = (data) => {
    navigate(
      `${appRouterLinks.nationalities}?searchText=${
        data.searchText || ''
      }&page=1`
    );
  };

  const [form] = Form.useForm();
  return (
    <div className="shared-table-filter-form-wrapper">
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit(onSubmit)}
        className={`shared-filter-form nationality-filter-form ${
          watch('searchText') ? 'view-actions' : ''
        }`}
      >
        <div className="form-body">
          <Controller
            name="searchText"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  className="search-text-input"
                  placeholder={t('searchText')}
                  {...field}
                />
              );
            }}
          />
        </div>
        <AnimatePresence>
          {watch('searchText') && (
            <motion.div
              initial={{
                opacity: 0,
                translateX: i18n.dir() === 'ltr' ? 192 : -192
              }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: {
                  duration: 0.5,
                  type: 'spring'
                }
              }}
              className={`form-actions ${i18n.dir()}`}
            >
              <Button
                className="reset-btn"
                htmlType="button"
                type="link"
                shape="circle"
                icon={
                  isSubmittingForm ? (
                    <LoadingOutlined />
                  ) : (
                    <ReloadIcon width={18} height={18} />
                  )
                }
                loading={isSubmittingForm}
                onClick={() => {
                  if (values?.searchText) {
                    navigate(appRouterLinks.nationalities);
                  } else {
                    setValue('searchText', '');
                  }
                }}
              />
              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                shape="circle"
                icon={
                  isSubmittingForm ? (
                    <LoadingOutlined />
                  ) : (
                    <SearchIcon width={18} height={18} />
                  )
                }
                loading={isSubmittingForm}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Form>
    </div>
  );
};

export default TableFilter;
