import CustomTag from '@components/shared/custom-tag';
import appRouterLinks from '@routes/appRouterLinks';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { orderStatusArr } from '@mocks/index';

const TableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setFetchCount,
  setSelectedTableRow,
  setIsLoading,
  setConfirmModal,
  token,
  t
}) => {
  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <Tooltip placement="top" title={t('details')}>
            <Link
              to={`${appRouterLinks?.orderDetails(record?.id)}`}
              className="action-btn view"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                // width="24"
                height="24"
                fill="#196c10"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>
            </Link>
          </Tooltip>
        </div>
      </div>
    );
  };

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 92,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('orderTime'),
      dataIndex: 'orderTime',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.orderTime || record?.createdTime || '---'}
          </div>
        );
      }
    },
    {
      title: t('orderStatus'),
      dataIndex: 'order_status',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.status ? (
              <CustomTag arr={orderStatusArr(t)} status={record?.status} />
            ) : (
              '---'
            )}
          </div>
        );
      }
    },
    {
      title: t('customer'),
      dataIndex: 'clientName',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.clientName || record?.clientDate?.name || '---'}
            </span>
          </div>
        );
      }
      // sorter: (a, b) => {
      //   let nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   let nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // }
    },
    {
      title: t('store'),
      dataIndex: 'restaurant_index',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>
              {record?.storeData?.name || record?.storeDate?.phone || '---'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('netPrice'),
      dataIndex: 'net_price',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>
              {record.productPrice || 0} {t('currency.sar')}
            </span>
          </div>
        );
      }
    },
    {
      title: t('productTaxPrice'),
      dataIndex: 'product_tax_price',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>
              {record?.taxProductPrice || 0} {t('currency.sar')}
            </span>
          </div>
        );
      }
    },
    {
      title: t('deliveryPrice'),
      dataIndex: 'delivery_price',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>
              {record?.deliveryPrice || 0} {t('currency.sar')}
            </span>
          </div>
        );
      }
    },
    {
      title: t('deliveryTaxPrice'),
      dataIndex: 'delivery_tax_price',
      width: 132,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>
              {record?.deliveryTaxPrice || 0} {t('currency.sar')}
            </span>
          </div>
        );
      }
    },
    {
      title: t('totalPrice'),
      dataIndex: 'total_price',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>
              {record?.totalPrice || 0} {t('currency.sar')}
            </span>
          </div>
        );
      }
    },

    // {
    //   title: t('deliveryStatus'),
    //   dataIndex: 'delivery_status',
    //   width: 132,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record?.status ? (
    //           <CustomTag arr={orderStatusArr(t)} status={2} />
    //         ) : (
    //           '---'
    //         )}
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('driver'),
      dataIndex: 'driver',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span>{record?.driverName || record?.driver?.name || '---'}</span>
          </div>
        );
      }
    },

    {
      title: t('details'),
      dataIndex: 'details',
      with: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
