/* eslint-disable react-hooks/exhaustive-deps */
import CustomImage from '@components/shared/custom-image';
import { Descriptions } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import CustomMap from '@components/custom-map';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import driverMarkerImg from '../../../../assets/imgs/icons/delivery-man.png';

const DriverInfo = ({ obj, firebaseObj, driverLiveLocation }) => {
  const { i18n } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    lat: '',
    lng: ''
  });

  useEffect(() => {
    if (driverLiveLocation?.lat && driverLiveLocation?.long) {
      setSelectedLocation({
        lat: parseFloat(driverLiveLocation.lat),
        lng: parseFloat(driverLiveLocation?.long)
      });
    }
  }, [driverLiveLocation?.lat, driverLiveLocation?.long]);

  if (!obj) {
    return (
      <div className="order-driver-info">
        <Descriptions column={1} title={t('driver')} bordered>
          <h2>{t('noDriver')}</h2>
        </Descriptions>
      </div>
    );
  } else if (obj) {
    return (
      <div
        className={`order-driver-info ${
          driverLiveLocation?.lat && driverLiveLocation?.long
            ? 'live-location'
            : ''
        }`}
      >
        <Descriptions
          size="small"
          column={driverLiveLocation?.lat && driverLiveLocation?.long ? 1 : 2}
          title={t('driverInfo')}
          bordered
        >
          <Descriptions.Item
            span={driverLiveLocation?.lat && driverLiveLocation?.long ? 1 : 2}
            label={t('name')}
          >
            <div className="img-name-wrapper">
              {obj?.image && (
                <CustomImage src={obj.image} className="driver-img" />
              )}
              <span className="name-span">
                {firebaseObj?.driverName || obj?.name || '---'}
              </span>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={t('idNumber')}>
            {obj?.idNumber || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('managerName')}>
            {obj?.managerName || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('phone')}>
            {obj?.phone || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('email')}>
            {obj?.email || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('birthDate')}>
            {obj?.birthDate || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('nationality')}>
            {obj?.nationality?.name || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('country')}>
            {obj?.country?.name || '---'}
          </Descriptions.Item>
          <Descriptions.Item label={t('city')}>
            {obj?.city?.name || '---'}
          </Descriptions.Item>
        </Descriptions>

        <AnimatePresence>
          {driverLiveLocation?.lat && driverLiveLocation?.long && (
            <motion.div
              initial={{
                opacity: 0,
                translateX: i18n.dir() === 'rtl' ? -152 : 152
              }}
              animate={{
                opacity: 1,
                translateX: 0
              }}
              className="map-wrapper"
            >
              <CustomMap
                width="100%"
                height="384px"
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                isDraggable={false}
                markerIcon={driverMarkerImg}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
  return null;
};

export default DriverInfo;
